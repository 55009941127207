import { createSelector } from 'reselect'

import { selectActiveTravelDestinationId } from '../travel/selectors'
import { getFormattedLabel } from './utils'

const selectAllGifts = (state) => state.vsf.gifts.options

export const selectGifts = createSelector(
  [selectAllGifts, selectActiveTravelDestinationId],
  (options, destinationId) => {
    try {
      if (Object.keys(options).length > 0 && destinationId) {
        return Object.values(options[destinationId]).reduce((aggr, curr) => {
          return {
            ...aggr,
            [curr.gift_type]: aggr[curr.gift_type]
              ? [...aggr[curr.gift_type], curr]
              : [curr],
          }
        }, {})
      }
    } catch (error) {
      console.error(error) // eslint-disable-line
    }
  }
)

export const selectIncludedGifts = createSelector(
  [selectGifts],
  (giftsMap) => {
    if (giftsMap) {
      return Object.values(giftsMap)
        .flatten()
        .reduce((aggr, curr) => {
          if (curr.included) {
            if (curr.input !== 'select' || curr.value) {
              const label = getFormattedLabel(curr, curr.value)

              return [...aggr, label]
            }
          }

          return aggr
        }, [])
    }
  }
)
