import React from 'react'
import PropTypes from 'prop-types'

// Toggle button, with toggle wrapper
const ToggleMe = ({ button, className, content, toggleBool, ...rest }) => { 
  const displayMode = toggleBool ? 'none' : 'inline-block'
  const active = toggleBool ? '' : 'open'

  return (
    <div className={`toggle-me ${active} ${className || ''}`} { ...rest }>
      { button }
      <div style={{display: displayMode}}>
        { content }
      </div>
    </div>
  )
}


ToggleMe.propTypes = {
  button:     PropTypes.any.isRequired,
  toggleBool: PropTypes.bool,
  className:  PropTypes.string,
  content:    PropTypes.any.isRequired
}

export default ToggleMe
