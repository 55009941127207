import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon'

const StampsIcon = ({ color, viewBox, ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <g fill={color}>
        <path stroke={color} d="M95,117h6a5,5,0,0,1,5-5v-5a5,5,0,0,1,0-10V92a5,5,0,0,1,0-10V77a5,5,0,0,1,0-10V61a5,5,0,0,1,0-10V46a5,5,0,0,1,0-10V31a5,5,0,0,1,0-10V16a5,5,0,0,1-5-5H95a5,5,0,0,1-10,0H79a5,5,0,0,1-10,0H63a5,5,0,0,1-10,0H47a5,5,0,0,1-10,0H31a5,5,0,0,1-5,5v5a5,5,0,0,1,0,10v5a5,5,0,0,1,0,10v5a5,5,0,0,1,0,10v6a5,5,0,0,1,0,10v5a5,5,0,0,1,0,10v5a5,5,0,0,1,0,10v5a5,5,0,0,1,5,5h6a5,5,0,0,1,10,0h6a5,5,0,0,1,10,0h6a5,5,0,0,1,10,0h6a5,5,0,0,1,10,0Zm-62-7V18H99v92Z"></path>
        <circle cx="37" cy="52" r="28" transform="translate(-17.08 18.12) rotate(-22.5)"></circle>
        <circle cx="37" cy="52" r="18" transform="translate(-17.08 18.12) rotate(-22.5)"></circle>
        <path d="M16.51,71.43A23.19,23.19,0,0,1,6.33,79.86q-.68.27-1.35.48"></path>
        <path d="M10.59,62.09a23.42,23.42,0,0,1-6.16,5.37"></path>
        <path d="M24.23,77.2c-2.47,5-6.56,11.05-13.31,13.75a18.48,18.48,0,0,1-3.28,1"></path>
        <path d="M9.54,45.7A31.41,31.41,0,0,1,4,53.16"></path>
        <path d="M65.23,51.25A23.19,23.19,0,0,0,78.39,50c6.67-2.87,9.17-8.36,13-13,5.32-6.36,6.8-9.84,26.41-12"></path>
        <path d="M62.81,40.45a22.17,22.17,0,0,0,11-1.53c6.67-2.87,9.17-8.36,13-13,5.32-6.36,6.8-9.84,26.41-12"></path>
        <path d="M63.85,60.79C69.16,62.6,76.3,64,83,61.1s9.17-8.36,13-13c5.32-6.36,6.8-9.84,26.41-12"></path>
        <path d="M52,28.13C57,29.6,63.29,30.38,69.2,27.84c6.67-2.87,9.17-8.36,13-13C87.55,8.5,89,5,108.64,2.84"></path>
    </g>
  </SvgIcon>
)

StampsIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

StampsIcon.defaultProps = {
  color: "rgba(0,0,0,0.7)",
  viewBox: "0 0 128 128",
}
/*
stroke={color} stroke-linejoin="round" stroke-width="4px" stroke-linecap="square"
    <defs>
        <style>.cls-1,.cls-2,.cls-3{stroke="#000";stroke-linejoin:round;stroke-width:4px;}
        .cls-1,.cls-2{stroke-linecap:square;}
        .cls-2,.cls-3{fill:none;}
        .cls-3{stroke-linecap:round;}
        </style>
    </defs>
*/

export default StampsIcon
