import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { TextField, SelectField } from 'redux-form-material-ui'
import MenuItem from 'material-ui/MenuItem'
import { validateRequired, validate255MaxChar } from '../../../../helpers/validators'
import { normalizePrice } from '../../../../helpers/normalizers'

import './index.scss'

const UpgradForm = ({ styles }) => {
  return (
    <div className="UpgradeForm">
      <Field
        className="input-field"
        name="upgrade_type"
        component={SelectField}
        floatingLabelText="fee type"
        floatingLabelFixed
        floatingLabelStyle={styles.floatingLabelStyle}
        hintText="upgrade fee type"
        validate={[validateRequired]}
      >
        <MenuItem value="Activation Fee" primaryText="Activation Fee"/>
        <MenuItem value="Additional Night(s)" primaryText="Additional Night(s)"/>
        <MenuItem value="Misc. Fee" primaryText="Misc. Fee"/>
        <MenuItem value="No Show Fee" primaryText="No Show Fee"/>
        <MenuItem value="Not Qualified Fee" primaryText="Not Qualified Fee"/>
        <MenuItem value="Premium Property" primaryText="Premium Property"/>
        <MenuItem value="Property Allotment" primaryText="Property Allotment"/>
        <MenuItem value="Rescheduling/Cancellation Fee" primaryText="Rescheduling/Cancellation Fee"/>
        <MenuItem value="Shipping & Handling" primaryText="Shipping & Handling"/>
        <MenuItem value="Travel Time Extension" primaryText="Travel Time Extension"/>
      </Field>

      <Field
        className="input-field"
        name="fee"
        component={TextField}
        type="number"
        style={styles.small.style}
        floatingLabelText="fee amount"
        hintText="50"
        floatingLabelFixed
        floatingLabelStyle={styles.floatingLabelStyle}
        validate={[validateRequired]}
        normalize={normalizePrice}
      />

      <Field
        className="input-field"
        name="upgrade_specifics"
        component={TextField}
        multiLine
        style={styles.multiLine.style}
        hintText="maximum of 255 characters"
        floatingLabelText="upgrade specifics"
        floatingLabelFixed
        floatingLabelStyle={styles.floatingLabelStyle}
        validate={[validateRequired, validate255MaxChar]}
      />
    </div>
  )
}

UpgradForm.propTypes = {
  styles: PropTypes.object.isRequired,
}

export default UpgradForm