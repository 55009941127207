import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm, formValueSelector } from 'redux-form'
import Cards from 'react-credit-cards'

import { validateRequired, validateCardNumber } from 'helpers/validators'
import { normalizeCardExpiration, normalizeCardNumber, normalizeLetter, normalizeCardCode } from 'helpers/normalizers'

// Icons
import CreditCardIcon from 'material-ui-icons/CreditCard'
import PersonIcon from 'material-ui-icons/Person'
import DateIcon from 'material-ui-icons/DateRange'
import CodeIcon from  'material-ui-icons/Code'

// Fields
import CreditCardField from 'views/vsf/views/CreditCard/CreditCardField'

import FormSectionHeader from 'views/ui/FormSectionHeader'

// Selectors
import { selectBillingInitValues, selectFocused } from '../../store/vsf/billing/selectors'

export const FORM_NAME = 'wizard.billing'

class CardBilling extends Component {
  renderCreditCardName() {
    return (
      <CreditCardField
        label="Name"
        name="credit_card_name"
        id="credit_card_name"
        hintText="First and Last"
        icon={<PersonIcon className="required-input-icon input-icon svg-icon" />}
        required
        validators={[validateRequired]}
        normalizer={normalizeLetter}
      />
    )
  }

  renderCreditCardNumber() {
    return (
      <CreditCardField
        label="Number"
        name="credit_card_number"
        id="credit_card_number"
        hintText="xxxx-xxxx-xxxx-xxxx"
        icon={<CreditCardIcon className="required-input-icon input-icon svg-icon" />}
        required
        validators={[validateRequired, validateCardNumber]}
        normalizer={normalizeCardNumber}
      />
    )
  }

  renderCreditCardExpDate() {
    return (
      <CreditCardField
        clearLeft
        smallField
        label="Expires"
        name="expiration_date"
        id="credit_card_expiration_date"
        hintText="MM/YY"
        icon={<DateIcon className="required-input-icon input-icon svg-icon" />}
        required
        validators={[validateRequired]}
        normalizer={normalizeCardExpiration}
      />
    )
  }

  renderCreditCardCVV() {
    return (
      <CreditCardField
        smallField
        label="CVC"
        name="cvv"
        id="credit_card_cvv"
        hintText="123"
        icon={<CodeIcon className="required-input-icon input-icon svg-icon" />}
        required
        validators={[validateRequired]}
        normalizer={normalizeCardCode}
      />
    )
  }

  render () {
    const { credit_card_name, credit_card_number, expiration_date, cvv, focused } = this.props

    return (
      <div className="content-padding billing-wrapper">

        <FormSectionHeader className="margin-zero top">
          <CreditCardIcon className="required-input-icon input-icon svg-icon" />
          Credit Card Information
        </FormSectionHeader>
  
        <div className="billing-info">
          <Cards
            number={credit_card_number}
            name={credit_card_name}
            expiry={expiration_date}
            cvc={cvv}
            focused={focused}
          />
  
          <div>
            {this.renderCreditCardName()}
            {this.renderCreditCardNumber()}
            {this.renderCreditCardExpDate()}
            {this.renderCreditCardCVV()}
          </div>

        </div>
      </div>
    )
  }
}

CardBilling.propTypes = {
  focused: PropTypes.string,
  credit_card_name: PropTypes.string,
  credit_card_number: PropTypes.string,
  expiration_date: PropTypes.string,
  cvv: PropTypes.string,
}

CardBilling.defaultProps = {
  focused: '',
  credit_card_name: '',
  credit_card_number: '',
  expiration_date: '',
  cvv: '',
}

const enhance = compose(
  connect((state) => {
    return {
      focused: selectFocused(FORM_NAME)(state),
      ...formValueSelector(FORM_NAME)(
        state,
        'credit_card_name',
        'credit_card_number',
        'expiration_date',
        'cvv',
      ),
      initialValues: selectBillingInitValues(state),
    }
  }),
  reduxForm({ form: FORM_NAME }),
)

export default enhance(CardBilling)
