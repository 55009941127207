import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import TextField from 'views/ui/TextField'

const NoteField = () => {
  return (
    <div>
      <div className="input full clearfix note-wrapper">
        <Field
          className="note-field"
          id="note_content"
          name="note_content"
          component={TextField}
          rows={16}
          placeholder="Enter Notes Here - Gifts entered in this field will not be accepted."
        />
      </div>
    </div>
  )
}

NoteField.propTypes = {}

export default NoteField
