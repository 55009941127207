import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { api } from 'controllers/Rest'
import { Table } from 'reactable'
import Moment from 'moment'

import Auth from 'models/Auth'
import { parseJSON } from 'controllers/concerns/railsRest'
import C from 'config/environment'
import { createAlert } from 'controllers/AlertController'

class BanFiles extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    loading: PropTypes.func,
    banFiles: PropTypes.object,
  }

  state = {
    pagination: 0,
  }

  componentWillMount = () => {
    this.reload()
  }

  reload = () => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(api.banFiles.reset()),
      dispatch(api.banFiles.sync()),
    ])
      .then(() => {
        done()
      })
      .catch((failure) => {
        done()
        alert('Failure in loading Ban Files', failure)
      })
    )
  }

  _toggleSpinner(message) {
    this.setState({
      loading: true,
      loadingMessage: message
    })
  }

  _disableSpinner() {
    this.setState({
      loading: false,
      loadingMessage: ""
    })
  }



  addToDNC = (id) => {
    const { dispatch } = this.props
    let data = { id: id }
    this._toggleSpinner("Marking File to be Banned")

    return fetch(C.API.BASE + `/ban_importer/${id}/ban`, {
      method: 'PATCH',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(parseJSON)
    .then((ban) => {
      this.reload()
      this._disableSpinner()
      dispatch(createAlert({
        dismissable: true,
        type: 'success',
        message: ban.message,
        dismissAfter: 3000,
      }))
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to schedule the file for banning'
      let errors = [error]
      let stackTrace = [error.stack]
      this.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      this._disableSpinner()
      return error
    })
  }

  _openInNewWindow = (e, link) => {
    e.preventDefault()
    let userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf(' electron/') > -1) {
      window.require('electron').shell.openExternal(link)
    } else {
      let width = window.innerWidth / 2
      let height = window.innerHeight
      window.open(link, 'Google', `left=${width},width=${width},height=${height}`)
    }
  }

  _downloadFileLink = (name, id) => {
    return <a onClick={(e) => this._openInNewWindow(e, `https://drive.google.com/uc?id=${id}&export=download`)}>{name}</a>
  }

  renderReceipts = () => {
    const { banFiles } = this.props
    let paged = 0

    if (banFiles.loading)
      return (<div className="big text">Loading... please wait</div>)

    if (banFiles.data.length === 0)
      return (<div className="big text">No files present to scrub</div>)

    if (banFiles.data.length > 15)
      paged = 15

    let receipts = banFiles.data.map(banFile => {
      let scrubbedStatus
      if (banFile.scrubbed) {
        scrubbedStatus = banFile.in_progress ? 'In Progress' : 'Yes'
      } else {
        scrubbedStatus = 'No'
      }
      let file = {
        'Title': this._downloadFileLink(banFile.title, banFile.id),
        'Imported': scrubbedStatus,
        'Summary': banFile.summary,
        'Created At': Moment(banFile.created_at).format("M/D/YY hh:mm:ss A")
      }

      if (!banFile.scrubbed) {
        let action = <button className="green button" disabled={this.state.loading? 'disabled' : ''} onClick={() => this.addToDNC(banFile.id)}>Add To Ban List</button>
        file['Actions'] = (
          <div className="nowrap">
            {action}
          </div>
        )
      }

      return file
    })

    return (
      <Table
        className="table"
        data={receipts}
        itemsPerPage={paged}
        width="100%"
      />
    )
  }

  render = () => (
    <div>
      {!this.props.banFiles.isLoading && this.renderReceipts()}
    </div>
  )
}

const getBanFiles = (state: GetBanFiles) => {
  return {
    banFiles: state.banFiles
  }
}

export default connect(getBanFiles)(BanFiles)
