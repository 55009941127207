import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'

import './index.scss'

const ICON_SIZE = 30
const DEFAULT_COLOR = "#333"
const ERROR_COLOR = "#D14A47"

const styles = {
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE
  }
}

const OptionLabel = ({
  name,
  label,
  icon,
  errors,
  children
}) => {
  const error = errors && errors[name]
  const color = error ? ERROR_COLOR : DEFAULT_COLOR
  return (
    <div className="OptionLabel-container">
      {cloneElement(icon, { style: styles.icon, color })}
      <h3 style={{
        color
      }}>{label}</h3>
      {children}
    </div>
  )
}

OptionLabel.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element,
  errors: PropTypes.object,
  children: PropTypes.element,
}

export default OptionLabel