import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import AttachMoney from 'material-ui-icons/AttachMoney'
import { TextField } from 'redux-form-material-ui'
import FieldLabel from 'views/ui/FieldLabel'
import { validatePrice } from 'helpers/validators'
import { normalizeNumber } from 'helpers/normalizers'

const normalizePrice = value => normalizeNumber && value.slice(0, 4)

const PackagePriceField = () => (
  <div className="input radioField input-style">

    <FieldLabel
      icon={<AttachMoney className="required-input-icon input-icon svg-icon" />}
      label="Package Price"
      required={true}
      />

    <Field
      id="package_price"
      name="package_price"
      component={TextField}
      hintText="150"
      normalize={normalizePrice}
      validate={validatePrice}
    />
  </div>
)

PackagePriceField.propTypes = {}

export default PackagePriceField
