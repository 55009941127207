import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CodeIcon from 'material-ui-icons/Code'
import { TextField } from 'redux-form-material-ui'
import FieldLabel from 'views/ui/FieldLabel'
import { validateRequired, validateVerification } from 'helpers/validators'
import { normalizeNumber } from 'helpers/normalizers'
import { textErrorStyle } from 'views/ui/styles/styles'

const VerficationCodeField = () => (
  <div className="input radioField input-style">

    <FieldLabel
      icon={<CodeIcon className="required-input-icon input-icon svg-icon" />}
      label="Verification Code"
      required={true}
    />

    <Field
      id="verification_code"
      name="verification_code"
      component={TextField}
      hintText="Verification Code"
      errorStyle={textErrorStyle}
      normalize={normalizeNumber}
      validate={[validateRequired, validateVerification]}
    />

  </div>
)

VerficationCodeField.propTypes = {}

export default VerficationCodeField