import React from 'react'
import PropTypes from 'prop-types'
import Dialog from 'material-ui/Dialog'
import { Tabs, Tab } from 'material-ui/Tabs'
import Button from 'views/ui/Button'

const styles = {
  tabs: {
    minHeight: '600px',
  },
  content: {
    padding: 20,
  },
}

const gift_3_2_ocean_cruise = () => (
  <div style={styles.content}>
    <iframe
      src="https://www.google.com/maps/d/embed?mid=1qerblEcaVNb9My9z8ILZcFuPPXE"
      width="100%"
      height="200"
    />
    <h3>
      <a
        href="https://www.google.com/maps/d/u/0/viewer?mid=1qerblEcaVNb9My9z8ILZcFuPPXE&ll=34.62459210115054%2C-95.28817035000003&z=4"
        target="_blank"
        rel="noopener noreferrer"
      >
        3/2 Vacation Passport Destinations
      </a>
    </h3>
    <p>
      <em>Room tax is not included.</em>
    </p>
    <div className="one third column">
      <dl>
        <dt>Arizona</dt>
        <dd>Sedona</dd>
        <dt>California</dt>
        <dd>Lake Tahoe, San Diego</dd>
        <dt>Colorado</dt>
        <dd>Denver</dd>
        <dt>Florida</dt>
        <dd>
          Orlando, Kissimmee,
          <br /> New Smyrna Beach,
          <br /> Daytona Beach, Fort Lauderdale
        </dd>
        <dt>Maine</dt>
        <dd>Kennebunk</dd>
        <dt>Minnesota</dt>
        <dd>Brainerd (Breezy Point)</dd>
      </dl>
    </div>

    <div className="one third column">
      <dl>
        <dt>Mississippi</dt>
        <dd>Biloxi</dd>
        <dt>Missouri</dt>
        <dd>Branson</dd>
        <dt>Nevada</dt>
        <dd>Las Vegas</dd>
        <dt>South Carolina</dt>
        <dd>
          Hilton Head, Myrtle Beach,
          <br /> Murrells Inlet, Charleston
        </dd>
        <dt>Tennessee</dt>
        <dd>Pigeon Forge, Gatlinburg</dd>
        <dt>Texas</dt>
        <dd>San Antonio</dd>
      </dl>
    </div>

    <div className="one third column">
      <dl>
        <dt>Utah</dt>
        <dd>Park City</dd>
        <dt>Virginia</dt>
        <dd>Virginia Beach, Williamsburg</dd>
        <dt>Wisconsin</dt>
        <dd>Wisconsin Dells</dd>
        <dt>Bahamas</dt>
        <dd>Grand Bahama Island</dd>
        <dt>Canada</dt>
        <dd>Collingwood</dd>
        <dt>Mexico</dt>
        <dd>Cancun, Puerto Vallarta, Cabo San Lucas</dd>
      </dl>
    </div>
  </div>
)

const gift_4_3_cruise = () => (
  <Tab label="4/3 Cruise" value={2}>
    <h3>4/3 Cruise Destinations</h3>
    <p>
      <em>Port fees and taxes are not included.</em>
    </p>
    <dl>
      <dt>Eastern Caribbean Cruise</dt>
      <dd>Sailing from Port Canaveral, Florida to Nassau, Bahamas.</dd>

      <dt>Eastern Caribbean Cruise</dt>
      <dd>Sailing from the Port of Miami, Florida to Nassau, Bahamas.</dd>

      <dt>Eastern Baja Cruise</dt>
      <dd>
        Sailing from the Port of Los Angeles, California to Ensenada, Mexico.
      </dd>
    </dl>
  </Tab>
)

const gift_8_7_vacation_lifetime = () => (
  <div style={styles.content}>
    <iframe
      src="https://www.google.com/maps/d/embed?mid=1bfL32AkQ4YcCT6G32gqrLM0Z6X8"
      width="100%"
      height="200"
    />
    <h3>
      <a
        href="https://www.google.com/maps/d/u/0/viewer?mid=1bfL32AkQ4YcCT6G32gqrLM0Z6X8&ll=-3.81666561775622e-14%2C-102.97191403124992&z=1"
        target="_blank"
        rel="noopener noreferrer"
      >
        8/7 Vacation of a LifeTime
      </a>
    </h3>
    
    <div className="one third column">
      <dl>
        <dt>Arizona</dt>
        <dd>
          Lake Havasu City,
          <br /> Phoenix Area
        </dd>
        <dt>Arkansas</dt>
        <dd>Arkansas</dd>
        <dt>California</dt>
        <dd>
          Bakersfield,
          <br /> Lake Tahoe Area,
          <br /> Palm Springs Area,
          <br /> San Bernardino Area
        </dd>
        <dt>Colorado</dt>
        <dd>
          Colorado - South,
          <br /> Colorado - Western Region,
          <br /> Steamboat Springs,
          <br /> Vail and Breckenridge Area,
          <br /> Winter Park
        </dd>
        <dt>Delaware</dt>
        <dd>Delaware</dd>
        <dt>Florida</dt>
        <dd>
          Daytona Beach Area,
          <br /> Fort Lauderdale Area,
          <br /> Ft. Walton/Destin,
          <br /> Lake Okeechobee Area,
          <br /> Naples - Fort Myers Area,
          <br /> Orlando Area
        </dd>
        <dt>Georgia</dt>
        <dd>Georgia</dd>
        <dt>Hawaii</dt>
        <dd>Hawaii</dd>
        <dt>Idaho</dt>
        <dd>Idaho</dd>
        <dt>Illinois</dt>
        <dd>Illinois</dd>
        <dt>Indiana</dt>
        <dd>Indiana</dd>
        <dt>Iowa</dt>
        <dd>Iowa</dd>
        <dt>Kentucky</dt>
        <dd>Kentucky</dd>
        <dt>Louisiana</dt>
        <dd>Louisiana</dd>
        <dt>Maine</dt>
        <dd>
          Maine - Coastal,
          <br /> Maine - Inland
        </dd>
        <dt>Maryland</dt>
        <dd>Maryland</dd>
        <dt>Massachusetts</dt>
        <dd>
          Massachusetts - Coastal,
          <br /> Massachusetts - Inland
        </dd>
        <dt>Michigan</dt>
        <dd>Michigan</dd>
        <dt>Minnesota</dt>
        <dd>Minnesota</dd>
        <dt>Mississippi</dt>
        <dd>Mississippi</dd>
        <dt>Missouri</dt>
        <dd>
          Branson Area,
          <br /> Lake of the Ozark,
          <br /> St Louis Area
        </dd>
        <dt>Montana</dt>
        <dd>Montana</dd>
        <dt>Nevada</dt>
        <dd>
          Las Vegas Area,
          <br /> Reno Area
        </dd>
        <dt>New Hampshire</dt>
        <dd>
          New Hampshire - Coastal,
          <br /> New Hampshire - Inland
        </dd>
        <dt>New Jersey</dt>
        <dd>New Jersey</dd>
        <dt>New Mexico</dt>
        <dd>New Mexico</dd>
        <dt>North Carolina </dt>
        <dd>
          North Carolina - Coastal,
          <br /> North Carolina - Inland
        </dd>
        <dt>Oklahoma</dt>
        <dd>Oklahoma</dd>
        <dt>Pennsylvania</dt>
        <dd>
          Pennsylvania - Western,
          <br /> Poconos
        </dd>
        <dt>South Carolina</dt>
        <dd>
          Charleston Area,
          <br /> Hilton Head Islands,
          <br /> Myrtle Beach,
          <br /> South Carolina - Inland
        </dd>


      </dl>
    </div>

    <div className="one third column">
      <dl>
      <dt>Tennessee</dt>
      <dd>
        Gatlinburg / Pigeon Forge,
        <br /> Knoxville Area,
        <br /> Nashville Area
      </dd>
      <dt>Texas</dt>
      <dd>
        Dallas Area,
        <br /> Houston Area,
        <br /> Lake Conroe Area,
        <br /> South Padre Island
      </dd>
      <dt>Utah</dt>
      <dd>
        Brian Head Ski Area,
        <br /> Park City Area,
        <br /> Salt Lake City Area,
        <br /> Utah - North
      </dd>
      <dt>Vermont</dt>
      <dd>Vermont</dd>
      <dt>Virgin Islands</dt>
      <dd>St Croix</dd>
      <dt>Virginia</dt>
      <dd>
        McGaheysville Area,
        <br /> Shenandoah Valley,
        <br /> Virginia Beach Area,
        <br /> Williamsburg
      </dd>
      <dt>Washington</dt>
      <dd>Washington</dd>
      <dt>Wisconsin</dt>
      <dd>
        Green Bay Area,
        <br /> Milwaukee Area,
        <br /> Oshkosh Area,
        <br /> The Dells Area,
        <br /> Wisconsin - North
      </dd>
      <dt>Canada</dt>
      <dd>
        Eastern Canada,
        <br /> Western Canada
      </dd>
      <dt>Mexico</dt>
      <dd>
        Acapulco ,<br /> Baja and Cabo San Lucas ,<br /> Cancun and Cozumel ,
        <br /> Guadalajara ,<br /> Huatulco ,<br /> Ixtapa & Zihatanejo ,
        <br /> Manzanillo ,<br /> Mazatlan ,<br /> Mexico - Inland ,<br />{' '}
        Puerto Vallarta
      </dd>
      <dt>Caribbean</dt>
      <dd>
        Antigua and Barbuda ,<br /> Aruba ,<br /> Bahamas ,<br /> Barbados ,
        <br /> Curacao ,<br /> Dominican Republic ,<br /> Guadeloupe ,<br />{' '}
        Jamaica ,<br /> Saint Lucia ,<br /> St Maarten ,<br /> St Vinent and
        the Grenadines,<br /> Tortola
      </dd>
      <dt>Africa</dt>
      <dd>
        Egypt ,<br /> Ivory Coast ,<br /> Kenya ,<br /> Morocco ,<br />{' '}
        Senegal ,<br /> Tunisia
      </dd>
      <dt>Asia</dt>
      <dd>
        Cambodia ,<br /> China ,<br /> Hong Kong ,<br /> India ,<br />{' '}
        Indonesia ,<br /> Japan ,<br /> Malaysia ,<br /> Philippines ,<br />{' '}
        Singapore ,<br /> South Korea ,<br /> Taiwan ,<br /> Thailand ,<br />{' '}
        Vietnam
      </dd>
      <dt>Australia/Oceania</dt>
      <dd>
        Australia ,<br /> New Zealand ,<br /> Fiji Islands ,<br /> Tahiti
      </dd>
      <dt>Brazil</dt>
      <dd>
        Bahia ,<br /> Brazil Interior ,<br /> Florianapolis ,<br /> Fortaleza
        ,<br /> Goias ,<br /> Rio De Janeiro ,<br /> Salvador ,<br /> Sao
        Paulo
      </dd>
      <dt>Central/South America</dt>
      <dd>
        Argentina ,<br /> Bolivia ,<br /> Chile ,<br /> Colombia ,<br /> Costa
        Rica ,<br /> Ecuador ,<br /> El Salvador ,<br /> Gauatemala ,<br />{' '}
        Panama ,<br /> Peru ,<br /> Uruguay ,<br /> Venezuela
      </dd>
      </dl>
    </div>

    <div className="one third column">
      <dl>
      <dt>Andorra</dt>
      <dt>Austria</dt>
      <dt>Bulgaria</dt>
      <dt>Cyprus</dt>
      <dt>Denmark</dt>
      <dt>Finland</dt>
      <dt>France</dt>
      <dd>
        Central France ,<br /> French Alps ,<br /> Northern France ,<br />{' '}
        South of France ,<br /> West Coast France
      </dd>
      <dt>Germany</dt>
      <dt>Greece</dt>
      <dd>
        Cofu ,<br /> Crete ,<br /> Other Islands in Greece ,<br /> Skiathos
      </dd>
      <dt>Hungary</dt>
      <dt>Italy</dt>
      <dd>
        Central Italy ,<br /> Italy ,<br /> Italy - Coastal ,<br /> Italy
        Lakes and Mountains ,<br /> Rome Area ,<br /> Sardinia ,<br /> Venice
        Area
      </dd>
      <dt>Malta</dt>
      <dt>Portugal</dt>
      <dd>
        Madeira ,<br /> Portugal
      </dd>
      <dt>Scandinavia</dt>
      <dd>Sweden</dd>
      <dt>Serbia</dt>
      <dt>Spain</dt>
      <dd>
        Costa Del Sol ,<br /> East Coast Spain
      </dd>
      <dt>Spain - Balearic Islands</dt>
      <dd>
        Ibiza ,<br /> Mallorca ,<br /> Menorca
      </dd>
      <dt>Spain - Canary Islands</dt>
      <dd>
        Fuerteventura ,<br /> Gran Canaria ,<br /> Lanzarote ,<br /> Tenerife
        North ,<br /> Tenerife South
      </dd>
      <dt>Switzerland</dt>
      <dt>Turkey</dt>
      <dt>United Kingdom</dt>
      <dd>
        England - North Lakes Region ,<br /> England - South and London ,
        <br /> Sotland ,<br /> Wales
      </dd>
      <dt>Middle East</dt>
      <dd>
        Lebanon ,<br /> United Arab Emirates
      </dd>
      </dl>
    </div>
  </div>
)

class ModalGiftInfo extends React.Component {
  state = {
    slideIndex: 0,
  }

  handleTab = (value) => {
    this.setState({ slideIndex: value })
  }

  render() {
    const { hideDialog, open, id } = this.props

    return (
      <Dialog
        actions={
          <Button label="close" className="red" onTouchTap={hideDialog} />
        }
        modal={false}
        open={open}
        onRequestClose={hideDialog}
        autoScrollBodyContent={true}
        contentStyle={{ maxWidth: '850px', width: '95%' }}
      >
        <Tabs
          onChange={this.handleTab}
          value={this.state.slideIndex}
          style={styles.tabs}
        >
          <Tab label="3/2 Passport" value={0}>
            {gift_3_2_ocean_cruise()}
          </Tab>

          <Tab label="3/2 Cruise" value={1}>
            <iframe
              src="https://www.google.com/maps/d/embed?mid=1HjJ7RrQ8rM8W8ILAMOXQOuObSTc"
              width="100%"
              height="200"
            />
            <h3>
              <a
                href="https://www.google.com/maps/d/u/0/viewer?ll=25.90429877064035%2C-79.21901416640623&z=7&mid=1HjJ7RrQ8rM8W8ILAMOXQOuObSTc"
                target="_blank"
                rel="noopener noreferrer"
              >
                3/2 Ocean Cruise Destinations
              </a>
            </h3>
            <p>
              <em>Port fees and taxes are not included.</em>
            </p>
            <dl>
              <dt>Eastern Caribbean Cruise</dt>
              <dd>Sailing from Palm Beach, Florida to Nassau, Bahamas.</dd>
            </dl>
          </Tab>

          <Tab label="8/7 Vacation of a lifetime" value={2}>
            {gift_8_7_vacation_lifetime()}
          </Tab>

          {id === 'AMG' || id === 'SGM' ? gift_4_3_cruise() : null}
        </Tabs>
      </Dialog>
    )
  }
}

ModalGiftInfo.propTypes = {
  open: PropTypes.bool,
  hideDialog: PropTypes.func,
  id: PropTypes.string,
}

export default ModalGiftInfo
