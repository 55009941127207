import React from 'react'
import PropTypes from 'prop-types'
// Icons
import StyleIcon from 'material-ui-icons/Style'

import ConfirmationField from './ConfirmationField'
import { getAccommodationData } from '../../../../store/vsf/utils'

const ConfirmationGiftsFields = ({ form_data }) => {
  const { gifts } = getAccommodationData(form_data.accommodation.values)
  const renderGifts = gifts && gifts.length > 0

  return (
    renderGifts ? <div className="card-group">
      <div className="card-header-container">
        <StyleIcon color="#7a7a7a"/>
        <h3 className={"card-header"}>Gifts</h3>
      </div>

      <div className="confirmation-fields">
        {gifts.map((gift) => {
          return (
            <ConfirmationField key={gift} label={gift} /> 
          )
        })}
      </div>
    </div> : null
  )
}

ConfirmationGiftsFields.propTypes = {
  form_data: PropTypes.object
}

export default ConfirmationGiftsFields