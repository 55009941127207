const defaultFormat = /(\d{1,4})/g

const cards = [
  {
    type: 'amex',
    pattern: /^3[47]/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    length: [15],
    cvcLength: [4],
    luhn: true
  }, {
    type: 'dankort',
    pattern: /^5019/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'dinersclub',
    pattern: /^(36|38|30[0-5])/,
    format: /(\d{1,4})(\d{1,6})?(\d{1,4})?/,
    length: [14],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'discover',
    pattern: /^(6011|65|64[4-9]|622)/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'jcb',
    pattern: /^35/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'laser',
    pattern: /^(6706|6771|6709)/,
    format: defaultFormat,
    length: [16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'maestro',
    pattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
    format: defaultFormat,
    length: [12, 13, 14, 15, 16, 17, 18, 19],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'mastercard',
    pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'unionpay',
    pattern: /^62/,
    format: defaultFormat,
    length: [16, 17, 18, 19],
    cvcLength: [3],
    luhn: false
  }, {
    type: 'visaelectron',
    pattern: /^4(026|17500|405|508|844|91[37])/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'elo',
    pattern: /^(4011|438935|45(1416|76|7393)|50(4175|6699|67|90[4-7])|63(6297|6368))/,
    format: defaultFormat,
    length: [16],
    cvcLength: [3],
    luhn: true
  }, {
    type: 'visa',
    pattern: /^4/,
    format: defaultFormat,
    length: [13, 16, 19],
    cvcLength: [3],
    luhn: true
  }
]

export const cardFromNumber = (num) => {
  var card, i, len

  num = (num + '').replace(/\D/g, '')

  for (i = 0, len = cards.length; i < len; i++) {
    card = cards[i]

    if (card.pattern.test(num))
      return card
  }
}

export const validLuhn = (num) => {
  var digit, digits, i, len, odd, sum
  odd = true
  sum = 0
  digits = (num + '').split('').reverse()

  for (i = 0, len = digits.length; i < len; i++) {
    digit = digits[i]
    digit = parseInt(digit, 10)

    odd = !odd

    if (odd)
      digit *= 2

    if (digit > 9)
      digit -= 9

    sum += digit
  }

  return sum % 10 === 0
}

export const validCardNumber = (num) => {
  num = (num + '').replace(/\s+|-/g, '')

  if (!/^\d+$/.test(num))
    return false

  let card = cardFromNumber(num)

  if (!card)
    return false

  return card.length.includes(num.length) && (card.luhn === false || validLuhn(num))
}

export const formatCardNumber = (raw) => {
  var card, groups, upperLength
  let num = (raw + '').replace(/[^\d]/g, '')
  card = cardFromNumber(num)

  if (!card)
    return num

  upperLength = card.length[card.length.length - 1]
  num = num.replace(/\D/g, '')
  num = num.slice(0, upperLength)
  groups = num.match(card.format)

  if (!groups)
    return num

  if (card.format.global)
    return groups.join(' ')


  groups.shift()
  return groups.join(' ')
}
