import {
  SET_DESTINATION_OPTIONS,
  SET_LOS_OPTIONS,
  SET_TRAVEL_TIME_OPTIONS,
  SET_SKIP_CREDIT_CARD
} from './actions'

const INITIAL_STATE = {
  skip_credit_card: false,
  destination: {
    options: []
  },
  length_of_stay: {
    // [0]: days, [1]: nights
    options: []
  },
  travel_time_months: {
    options: []
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DESTINATION_OPTIONS:
      return {
        ...state,
        destination: {
          ...state.destination,
          options: action.payload.options
        }
      }

    case SET_LOS_OPTIONS:
      return {
        ...state,
        length_of_stay: {
          ...state.length_of_stay,
          options: action.payload.options
        }
      }

    case SET_TRAVEL_TIME_OPTIONS:
      return {
        ...state,
        travel_time_months: {
          ...state.travel_time_months,
          options: action.payload.options
        }
      }

    case SET_SKIP_CREDIT_CARD:
      return {
        ...state,
        skip_credit_card: action.payload
      }

    default:
      return state
  }
}
