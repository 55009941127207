import { UPDATE, RESET } from './actions.js'

export const INITIAL_STATE = {
  open: false,
  title: '',
  body: '',
  errors: [],
  stackTrace: [],
  validationErrors: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        ...action.payload.data
      }

    case RESET:
      return {
        ...INITIAL_STATE
      }
  
    default:
      return state
  }
}