import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { TextField } from 'redux-form-material-ui'

import { validateRequired } from '../../../../helpers/validators'

import PlaceSearch from '../../../ui/PlaceSearch'

import './index.scss'

const AddressForm = ({
  styles,
  searchingForAddress,
  onChange,
}) => {
  return (
    <div className="AddressForm">
      <Field
        className="input-field"
        name="billing_street"
        component={TextField}
        floatingLabelText="street"
        hintText="123 Main St."
        floatingLabelFixed
        floatingLabelStyle={styles.floatingLabelStyle}
        validate={[validateRequired]}
      />

      <Field
        className="input-field"
        name="billing_city"
        component={TextField}
        floatingLabelText="city"
        hintText="Albany"
        floatingLabelFixed
        floatingLabelStyle={styles.floatingLabelStyle}
        validate={[validateRequired]}
      />

      <Field
        className="input-field"
        name="billing_state"
        component={TextField}
        floatingLabelText="state"
        style={styles.small.style}
        hintText="NY"
        floatingLabelFixed
        floatingLabelStyle={styles.floatingLabelStyle}
        validate={[validateRequired]}
      />

      <Field
        className="input-field"
        name="billing_zip_code"
        component={TextField}
        floatingLabelText="zip code"
        hintText="12084"
        style={styles.small.style}
        floatingLabelFixed
        floatingLabelStyle={styles.floatingLabelStyle}
        validate={[validateRequired]}
      />
      {searchingForAddress && <div style={{ width: '100%'}}>
        <PlaceSearch
          styles={styles}
          types={['geocode']}
          componentRestrictions={{country: 'us'}}
          floatingLabelText="address"
          updateBillingData={(field, value) => {
            onChange(field.split('.')[1], value)
          }}
        />
      </div>}
    </div>
  )
}

AddressForm.propTypes = {
  styles: PropTypes.object,
  searchingForAddress: PropTypes.bool,
  onToggleSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AddressForm