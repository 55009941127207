import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import LocationCityIcon from 'material-ui-icons/LocationCity'
import { TextField } from 'redux-form-material-ui'
import { textErrorStyle } from 'views/ui/styles/styles'
import { validateRequired } from 'helpers/validators'
import { normalizeUpper } from 'helpers/normalizers'
import FieldLabel from 'views/ui/FieldLabel'

const BillingCityField = () => (
  <div className="input radioField input-style">

    <FieldLabel
      icon={<LocationCityIcon className="required-input-icon input-icon svg-icon" />}
      label="Billing City"
      required={true}
    />

    <Field
      id="billing_city"
      name="billing.billing_city"
      component={TextField}
      hintText="Billing City"
      errorStyle={ textErrorStyle }
      normalize={normalizeUpper}
      validate={validateRequired}
    />
  </div>
)


BillingCityField.propTypes = {}

export default BillingCityField