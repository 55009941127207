// ACTIONS
export const UPDATE = 'dialog/UPDATE'
export const RESET = 'dialog/RESET'

// ACTION CREATORS
export const updateDialogAction = (data) => ({
  type: UPDATE,
  payload: {
    data
  }
})

export const resetDialogAction = () => ({
  type: RESET,
  payload: null
})

// BOUND ACTION CREATORS
export const updateDialog = (data) => (dispatch) => {
  return dispatch(updateDialogAction(data))
}

export const resetDialog = () => (dispatch) => {
  return dispatch(resetDialogAction())
}