import React from 'react'
import PropTypes from 'prop-types'

const TextField = ({input, className, ...rest }) => (
  <textarea {...input} className={`textarea ${className} `} {...rest}>
  </textarea>
)

TextField.propTypes = {
  className:  PropTypes.string,
  input:      PropTypes.any
}

export default TextField
