import 'whatwg-fetch'
import reduxApi, { transformers } from 'redux-api'
import adapterFetch from 'redux-api/lib/adapters/fetch'
import C from 'config/environment'

const apiUrl = C.API.BASE
const baseOptions = (url, params, getState) => {
  const { 'access-token': accessToken, expiry, uid, client } = getState().auth

  return {
    headers: {
      'access-token': accessToken,
      expiry,
      uid,
      client,
      'token-type': 'Bearer',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
  }
}

export const rest = reduxApi({
  payments: {
    url: `${apiUrl}/payments`,
    transformer: transformers.array,
    options: baseOptions,
  },
  refunds: {
    url: `${apiUrl}/refunds`,
    transformer: transformers.array,
    options: baseOptions,
  },
  tasks: {
    url: `${apiUrl}/tasks`,
    transformer: transformers.array,
    options: baseOptions,
  },
  task: {
    url: `${apiUrl}/tasks/:id`,
    transformer: transformers.array,
    crud: true,
    options: baseOptions,
  },
  leads: {
    transformer: transformers.array,
    url: `${apiUrl}/leads`,
    options: baseOptions,
  },
  stats: {
    url: `${apiUrl}/stats`,
    transformer: transformers.array,
    options: baseOptions,
  },
  admin: {
    url: `${apiUrl}/admin`,
    transformer: transformers.array,
    options: baseOptions,
  },
  bans: {
    url: `${apiUrl}/dncphones`,
    transformer: transformers.array,
    crud: true,
    options: baseOptions,
  },
  cleanNumbers: {
    url: `${apiUrl}/clean_phone_numbers`,
    transformer: transformers.array,
    crud: true,
    options: baseOptions,
  },
  scrubReceipts: {
    url: `${apiUrl}/scrub_receipts`,
    transformer: transformers.array,
    crud: true,
    options: baseOptions,
  },
  scrubFiles: {
    url: `${apiUrl}/scrubber`,
    transformer: transformers.array,
    crud: true,
    options: baseOptions,
  },
  banFiles: {
    url: `${apiUrl}/ban_importer`,
    transformer: transformers.array,
    crud: true,
    options: baseOptions,
  }

}).use('fetch', adapterFetch(fetch))

export const api = rest.actions

export const fetchOne = (state) => state

export const fetchAll = (state) => state
