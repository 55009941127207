import { generateRequireSignInWrapper } from 'redux-token-auth'

import Dashboard from 'views/pages/Dashboard'
import Wizard from 'views/pages/Wizard'
import Leads from 'views/dashboard/Leads'
import Mail from 'views/dashboard/Mail'
import Print from 'views/dashboard/Print'
import Stamps from 'views/dashboard/Stamps'
import Billing from '../views/billing/containers/Upgrade'
import CaptureVoid from 'views/dashboard/Capture-Void'
import Refund from 'views/dashboard/Refund'
import Admin from 'views/dashboard/Admin'
import Scrubber from 'views/dashboard/scrub/Scrubber'
import Kitchen from 'views/pages/Kitchen'
import Login from 'views/pages/Login'
import Support from 'views/dashboard/Support'

// HOC
import Loadable from 'views/containers/Loadable'

const Authable = generateRequireSignInWrapper({
  redirectPathIfNotSignedIn: '/',
})

const routes = [
  {
    component: Loadable(Login),
    path: '/',
    exact: true
  },
  {
    component: Dashboard,
    path: '/dashboard',
    routes: [
      {
        path: '/dashboard/sales',
        component: Authable(Loadable(Wizard)),
        title: 'VSF',
      },
      {
        path: '/dashboard/billing',
        component: Authable(Loadable(Billing)),
        title: 'Billing',
      },
      {
        path: '/dashboard/admin',
        component: Authable(Loadable(Admin)),
        title: 'Admin'
      },
      {
        path: '/dashboard/leads',
        component: Authable(Loadable(Leads)),
        title: 'Leads'
      },
      {
        path: '/dashboard/mail',
        component: Authable(Loadable(Mail)),
        title: 'Mail'
      },
      {
        path: '/dashboard/print',
        component: Authable(Loadable(Print)),
        title: 'Print'
      },
      {
        path: '/dashboard/stamps',
        component: Authable(Loadable(Stamps)),
        title: 'Stamps'
      },
      {
        path: '/dashboard/capture-&-void',
        component: Authable(Loadable(CaptureVoid)),
        title: 'Capture & Void'
      },
      {
        path: '/dashboard/refund',
        component: Authable(Loadable(Refund)),
        title: 'Refund'
      },

      {
        path: '/dashboard/scrubber',
        component: Authable(Loadable(Scrubber)),
        title: 'Scrubber'
      },
      {
        path: '/dashboard/kitchen',
        component: Authable(Loadable(Kitchen)),
        title: 'Kitchen'
      },
      {
        path: '/dashboard/support',
        component: Authable(Loadable(Support)),
        title: 'Support'
      }
    ]
  }
]

export default routes
