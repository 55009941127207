import React from 'react'
import PropTypes from 'prop-types'
import ReactFileReader from 'react-file-reader'
import { RaisedButton, DropDownMenu, MenuItem} from 'material-ui'
import CircularProgress from 'material-ui/CircularProgress'
import Papa from 'papaparse'
import uuid from 'uuid/v4'
import { reduxForm } from 'redux-form'
import Bans from 'views/dashboard/scrub/Bans'
import CleanNumbers from 'views/dashboard/scrub/CleanNumbers'
import Receipts from 'views/dashboard/scrub/Receipts'
import ScrubFiles from 'views/dashboard/scrub/ScrubFiles'
import BanFiles from 'views/dashboard/scrub/BanFiles'
import Tabable from 'views/ui/Tabable'
import { ScrubReceiptType } from 'models/ScrubReceiptModel'
import { addScrubReceipt } from 'controllers/ScrubReceiptController'
import Auth from 'models/Auth'

const SCHEMA = [
  { key: 'FirstName', label: 'First Name' },
  { key: 'LastName', label: 'Last Name' },
  { key: 'Email', label: 'Email' },
  { key: 'Phone', label: 'Phone' },
  { key: 'alt_phone', label: 'Alt Phone' },
  { key: 'Address', label: 'Street' },
  { key: 'City', label: 'City' },
  { key: 'State', label: 'State' },
  { key: 'Zip', label: 'Zip' },
]

class Scrubber extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    loading: PropTypes.func,
    renderTabs: PropTypes.func,
    tab: PropTypes.number,
  }


  state = {
    newBansCount : 0,
    csvString : "",
    csvSample: [],
    csvArray: [],
    headerValue: "",
    path: "",
    openCleanDialog: false,
    headersAdded: false,
    headers: [],
    binaryExists: false,
    inProgressFlag: false,
    progressCount: 0,
    summary: "",
    originalCSVFilename: "",
  }

  constructor(props) {
    super(props)

    window.document.title = "Lead Scrubber"
  }

  closeCleanDialog = () => {
    this.setState({ openCleanDialog: false })
  }

  setOpenCleanDialog = () => {
    this.setState({ openCleanDialog: true })
  }

  processCSV = (csvString) => {
    if (window.process) {
      const fs = window.require('electron').remote.require('fs')
      const desktopPath = window.require('electron').remote.app.getPath('desktop')
      const dirtyFileName = this.state.originalCSVFilename

      fs.mkdir(`${desktopPath}/ScrubberFiles`, (err) => {
        if (err.code && err.code == 'EEXIST') {
            fs.mkdir(`${desktopPath}/ScrubberFiles/${dirtyFileName}`, () => {
              fs.writeFile(`${desktopPath}/ScrubberFiles/${dirtyFileName}/dirty.csv`, csvString, (err) => {
                if (err)
                  throw err
                })
            })
        }
        else {
          fs.writeFile(`${desktopPath}/ScrubberFiles/dirty.csv`, csvString, (err) => {
            if (err)
              throw err
            })
        }
      })
    }
  }

  addHeaderToArray = () => {
    let headerArray = []
    const csvArray = this.state.csvArray
    const header = this.state.headers

    if ( header.length == csvArray[0].length ) {
      headerArray.push(header)
      headerArray = headerArray.concat(csvArray)
      return headerArray
    }
  }

  handleHeaderSubmit = () => {
    const hasPhone = this.state.headers.includes('Phone')
    const headersBeenAdded = this.state.headersAdded

    if ( hasPhone && !headersBeenAdded ) {
      const headerArray = this.addHeaderToArray()
      const stringCSVWithHeader = Papa.unparse(headerArray)
      this.processCSV(stringCSVWithHeader)
      this.setState({
        csvArray: headerArray,
        csvString: stringCSVWithHeader,
        headersAdded: true
      })
    } else {
      alert('Please add `Phone` header in order to scrub')
    }
  }

  cleanCSVWithBinary = () => {
    const fs = window.require('electron').remote.require('fs')
    const desktopPath = window.require('electron').remote.app.getPath('desktop')
    const { spawn } = window.require('child_process')
    const workingDirectory = `${desktopPath}/ScrubberFiles/`
    const binaryPath = `${workingDirectory}/clean_prospects`
    const folderName = this.state.originalCSVFilename
    const currentState = this.state

    const cleanCSVstarts = () => {
      this.setState({
        inProgressFlag: true,
      })
    }

    const incrementProgress = () => {
      this.setState({
        progressCount: this.state.progressCount + 1,
      })
    }

    const cleanSummary = (summaryString) => {
      this.setState({ summary: summaryString })
    }

    const saveReceipt = (scrubReceipt: ScrubReceiptType) => {
        const { loading, dispatch } = this.props

        loading((done) => Promise.all([
          addScrubReceipt(scrubReceipt, dispatch)
        ])
          .then(() => {
            done()
        })
        .catch((failure) => {
          done()
          alert(failure.message)
        })
      )
    }

    fs.stat(binaryPath, function(err) {
      if (err == null) {
        if (currentState.headersAdded) {
          cleanCSVstarts()

          const child = spawn(binaryPath, [folderName], {cwd: workingDirectory})
            child.stdout.on('data', () => {
                incrementProgress()
              })

            child.stderr.on('data', (data) => {
              let stringData = data.toString()
              let stringIncludesFlag = stringData.includes('Flagged-')
              let stringIncludesTotal = stringData.includes('Total')

              if (stringIncludesFlag && stringIncludesTotal) {
                incrementProgress()
                const summaryLength = stringData.length
                const endOfTimestampWithFlagged = 87
                const summary = stringData.substring(endOfTimestampWithFlagged, summaryLength)
                const receipt = { scrubReceipt: `${summary}`,
                                  user: Auth.current().id,
                                  originalFileName: `${folderName}`
                                }
                saveReceipt(receipt)
                cleanSummary(summary)
              }
              else if (stringIncludesTotal) {
                const summaryLength = stringData.length
                const endOfTimestampInString = 20
                const summary = stringData.substring(endOfTimestampInString, summaryLength)
                const receipt = { scrubReceipt: `${summary}`,
                                  user: Auth.current().id,
                                  originalFileName: `${folderName}`
                                }
                saveReceipt(receipt)
                cleanSummary(summary)
              } else {
                incrementProgress()
              }
            })
        } else {
          alert( 'Please add headers' )
        }
    } else if ( err.code == 'ENOENT' ) {
        alert( 'Please download the binary into your folder and try again.' )
    } else {
        alert( 'Some other error: ', err.code )
    }})
  }

  setHeaderIndex = (name, index) => {
    let nextState = this.state
    nextState.headers[index] = name
    this.setState(nextState)
  }

  renderCSVHead = (columns) => {
    return columns.map((c, i) => (
      <th key={uuid()}>
        <DropDownMenu value={this.state.headers[i]} onChange={(e, k, v) => this.setHeaderIndex(v, i)}>
          <MenuItem value={null} primaryText={c} />
          {SCHEMA.map(s => (
            <MenuItem
              key={uuid()}
              value={s.key}
              primaryText={s.label}
            />
          ))}
        </DropDownMenu>
      </th>
    ))
  }

  renderCSVBody = (data) => data.map(row => (
    <tr key={uuid()}>
      {row.map(column => (<td key={uuid()}>{column}</td>))}
    </tr>
  ))

  resetHeaders = () => {
    let arrayWithoutHeader = this.state.csvArray
    arrayWithoutHeader.shift()

    this.setState({
      headersAdded: false,
      csvArray: arrayWithoutHeader,
    })
  }

  renderCSVPreview = () => {
    const sample = this.state.csvSample

    return (
      <div>
        <form onSubmit={this.handleHeaderSubmit}>
          { !this.state.headersAdded &&
            <RaisedButton
            primary
            type="submit"
            label="Add Headers"
          /> }
          { this.state.headersAdded &&
            <div>
              <RaisedButton
                label="Clean CSV"
                onClick={ () => { this.cleanCSVWithBinary() } }
                primary={true}
                />
              <RaisedButton
                label="Reset Headers"
                onClick={ () => { this.resetHeaders() } }
                style={{ marginLeft: '5%'}}
                secondary={true}
                />
            </div>
          }
        </form>

        <table className="compact striped">
          <thead>
            <tr>{this.renderCSVHead(sample[0])}</tr>
          </thead>
          <tbody>
            {this.renderCSVBody(sample)}
          </tbody>
        </table>
      </div>
    )
  }

  createOutCSVForImport = () => {
    const fs = window.require('electron').remote.require('fs')
    const desktopPath = window.require('electron').remote.app.getPath('desktop')
    const folderName = this.state.originalCSVFilename
    const filePathOfCleanedCSV = `${desktopPath}/ScrubberFiles/${folderName}/cleaned.csv`
    const filePathOfOutCSV = `${desktopPath}/ScrubberFiles/${folderName}/out.csv`
    let tenNumbersRegEx = new RegExp("^[0-9]{10}$")
    let outerArray = []

    fs.readFile(filePathOfCleanedCSV, 'utf8', (err, cleanedCSVString) => {
      if (err)
        throw err
      let outArray = Papa.parse(cleanedCSVString)
      for (let number of outArray.data) {
        if (tenNumbersRegEx.test(number[1])){
          outerArray.push([number[1]])
        }
      }
      let outerArrayAsString = Papa.unparse(outerArray)
      fs.writeFile(filePathOfOutCSV, outerArrayAsString, () => {
        if (err)
          throw err
        })
    })
  }

  cleanInProgress = () => {
    if (!this.state.inProgressFlag) {
      return (
        null
      )
    } else {
      const currentState = this.state
      const cleanIsOver = () => {
        this.setState({
          inProgressFlag: false,
          progressCount: 0,
          headersAdded: false,
          headers: [],
          csvSample: [],
          csvArray: [],
          summary: "",
          originalCSVFilename: "",
          newBansCount: 0,
        })
      }

      return (
          <div>
            <CircularProgress
            mode="determinate"
            max={currentState.newBansCount}
            value={this.state.progressCount}
            size={120}
            thickness={6}
            style={{ 'marginLeft': '44.5%', 'marginTop': '10%' }} />
            <div className="big text">
              Cleaning in Progress
              <br/>
              <br/>
              {this.state.progressCount}/{currentState.newBansCount} Processed Records
              <br/>
              {this.state.summary}
            </div>
            { this.state.summary != "" &&
              <div>
                <RaisedButton
                  label="Scrub Again"
                  onClick={ () => cleanIsOver() }
                />
                <RaisedButton
                  label="Import Into Banlist"
                  style={{ 'marginLeft': '15%' }}
                  primary={ true }
                  onClick={ () => this.createOutCSVForImport() & cleanIsOver() }
                />
              </div>
            }
          </div>
      )
    }
  }

  handleFiles = (files) => {
    const reader = new FileReader()
    reader.onload = () => {
      // Use reader.result
      const parsed = Papa.parse(reader.result)
      const rows = parsed.data
      const csvSample = rows.slice(0,10)
      const csvFileName = files[0].name
      this.setState({
        csvString: reader.result,
        newBansCount: rows.length,
        csvSample: csvSample,
        csvArray: rows,
        headers: Array(csvSample[0].length),
        originalCSVFilename: csvFileName.substring(0,csvFileName.length-4)
      })
      this.processCSV(reader.result)
    }
      reader.readAsText(files[0])
  }

  renderList = () => (
    <Bans {...this.props} />
  )

  renderCleanList = () => (
    <CleanNumbers {...this.props} />
  )


  renderScrubFilesTable = () => (
    <ScrubFiles {...this.props} />
  )

  renderBanFilesTable = () => (
    <BanFiles {...this.props} />
  )

  renderReceiptTable = () => (
    <Receipts {...this.props} />
  )


  renderScrubber = () => {
    if (this.state.inProgressFlag) {
      return (
        <div>
          {this.cleanInProgress()}
        </div>
      )
    }

    return (
      <div>
        <ReactFileReader handleFiles={this.handleFiles} fileTypes={'.csv'}>
          <RaisedButton label="Upload a CSV from Disk" primary={true} style={{ margin: '2rem auto' }} />
        </ReactFileReader>
        {this.state.newBansCount > 0 ? this.renderCSVPreview() : <div className="big text"></div>}
      </div>
    )
  }

  render = () => {
    const { tab, renderTabs } = this.props

    return (
      <div className="content bans">
        { window.process && renderTabs() }
        { tab == 0 && this.renderList() }
        { tab == 1 && this.renderCleanList() }
        { tab == 2 && window.process && this.renderReceiptTable() }
        { tab == 3 && window.process && this.renderScrubFilesTable() }
        { tab == 4 && window.process && this.renderBanFilesTable() }
      </div>
    )
  }
}

const TabableScrubber = Tabable(Scrubber, ['DNC List', 'Clean List', 'Receipts', 'Scrub Files', 'Ban Files'])

export default reduxForm({ form: 'scrubber' })(TabableScrubber)
