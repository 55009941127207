import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'
import RaisedButton from 'material-ui/RaisedButton'
import { Table } from 'reactable'
import C from 'config/environment'
import { TaskType } from 'models/TaskModel'
import Queue from 'queue'
import icons from 'views/ui/icons'
import { createAlert } from 'controllers/AlertController'
import { CSV_FIELD_MAPPINGS } from '../print/CsvFieldMappings'
import writeToCSV from 'modules/CsvWriter'


// Redux API Stuff
import { api } from 'controllers/Rest'
import { getTasks } from 'controllers/TasksController'
import { ActionCable } from 'react-actioncable-provider'

const q = Queue()

var electron = null
var win      = null

const NOT_STARTED      = 0
const SYNCING          = 1
const FINISHED_SYNCING = 2

class Print extends React.Component {

  static propTypes = {
    dispatch: PropTypes.func,
    loading:  PropTypes.func,
    tasks:    PropTypes.object,
    children: PropTypes.element,
    route:    PropTypes.object,
  }

  state = {
    last_sync_at: null,
    fetch_message: "",
    startPrinting: false,
    startExportingToCSV: false,
    syncingState: NOT_STARTED,
    updateTasks: true,
    filter: "purchase_confirmation",
    filterCountry: 'All',
    printCount: 0,
    printButtonLabel: ""
  }

  constructor(props) {
    super(props)

    window.document.title = props.route.title
  }

  onConnected() {
    this.fetchTasks()
  }

  onReceived(message) {
    if (this.state.syncingState != FINISHED_SYNCING) {
      if (message.hasOwnProperty('syncing_state')) {
        this.setState({
          syncingState: message.syncing_state
        })
      }
    }

    if (message.hasOwnProperty('tasks') && this.state.updateTasks === true) {
      this.handleReceivingTasks(message)
    }

    if (message.hasOwnProperty('fetch_message')) {
      this.handleReceivingFetchMessage(message)
    }
  }

  handleReceivingFetchMessage = (message) => {
    this.setState({ fetch_message: message.fetch_message })
  }

  handleReceivingTasks = (tasks) => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(() => this.setTasks(tasks))
    ])
      .then(() => done())
      .catch(() => {
        // HANDLE ERRORS
      })
    )
  }

  setTasks = (possibleTasks) => {
    const { tasks } = this.props

    if (this.state.startExportingToCSV) {
      var csvTasks = possibleTasks.tasks.reduce((result, task) => {
        var newTask = {}

        for (var key in task) {
          if (key in CSV_FIELD_MAPPINGS) {
            newTask[CSV_FIELD_MAPPINGS[key]] = task[key]

            if (task[key] == "NULL" || task[key] == "null") {
              newTask[CSV_FIELD_MAPPINGS[key]] = ""
            }
          }
        }

        result.push(newTask)
        return result
      }, [])

      var headers = Object.values(CSV_FIELD_MAPPINGS)

      writeToCSV('print', 'print', headers, csvTasks)
      this.setState({ startExportingToCSV: false } )

      return
    }
    api.tasks.reset()
    tasks.data = possibleTasks.tasks


    this.setState({ printButtonLabel: '' }, () => {
      if (this.state.startPrinting == true) {
        if (possibleTasks.tasks.length > 0) {
          this.setState({ updateTasks: false }, () => {
            q.jobs.map(() => { q.pop() })

            tasks.data.map(task => {
              q.push((cb) => {
                this.printHandler(task, cb)
              })
            })

            this.startPrinting()
          })
        } else {
          this.setState({ startPrinting: false, updateTasks: true, fetch_message: '' })
        }
      }
    })
  }

  fetchForCSVGenerate = () => {
    const { loading } = this.props

    loading((done) => {
      this.setState({startExportingToCSV: true}, () => {
        this.refs.printChannel.perform('fetch_for_csv_generate', {
          status: 'Not Started',
          sync: true,
          filter: this.state.filter,
          filter_country: this.state.filterCountry
        })
      })
      done()
    })
  }

  fetchForPrintTasks = () => {
    const { loading, dispatch } = this.props

    this.setState({ syncingState: SYNCING }, () => {
      loading((done) => Promise.all([
        dispatch(() => api.tasks.reset(),
          this.refs.printChannel.perform('fetch_for_print',
          { status: 'Not Started', sync: true, filter: this.state.filter, filter_country: this.state.filterCountry }))
      ])
        .then(() => {
          done()
        })
        .catch(() => {
          this.setState({
            syncingState: FINISHED_SYNCING
          }, () => {
            done()
          })
        })
      )
    })
  }

  fetchTasks = () => {
    const { loading, dispatch } = this.props

    this.setState({ syncingState: SYNCING, printButtonLabel: '' }, () => {
      loading((done) => Promise.all([
        dispatch(() => api.tasks.reset(),
          this.refs.printChannel.perform('fetch',
          {
            status: 'Not Started',
            sync: true,
            filter: this.state.filter,
            filter_country: this.state.filterCountry
          }))
      ])
        .then(() => {
          done()
        })
        .catch(() => {
          this.setState({
            syncingState: FINISHED_SYNCING
          })

          done()
        })
      )
    })
  }

  renderActions = () => (
    <div className="actions">
      <RaisedButton
        label="Generate CSV"
        onClick={this.fetchForCSVGenerate}
        disabled={this.showPrintAllButton()}
        primary={true}
        icon={<icons.stamps color="white" viewBox="0 0 140 140" />}
      />

      <RaisedButton
        label={this.printAllButtonLabel()}
        disabled={this.showPrintAllButton()}
        primary={true}
        onClick={this.setupPrinting}
        icon={<icons.print />}
      />
    </div>
  )

  printAllButtonLabel = () => {
    if (this.props.tasks.data.length == 0) {
      return "There is nothing to Print"
    } else if (this.state.printButtonLabel != "") {
      return this.state.printButtonLabel
    } else {
      return `${this.props.tasks.data.length} ${this.filterToHuman()}`
    }
  }

  filterToHuman = () => {
    if (this.state.filter == "purchase_confirmation") {
      return "Purchase Confirmation"
    } else if (this.state.filter == "booking_confirmation") {
      return "Booking Confirmation"
    } else if (this.state.filter == "tour_confirmation") {
      return "Tour Confirmation"
    } else {
      return "Certified Purchase Confirmation"
    }
  }

  startPrinting = () => {
    this.setState({
      printCount: this.state.printCount + 1,
      printButtonLabel: `Printing 1/${this.props.tasks.data.length}`
    }, () => {
      q.start((error) => {
        if (error) throw error
      })
    })
  }

  setupPrinting = () => {
    const { dispatch } = this.props

    electron = window.require('electron').remote
    win      = new electron.BrowserWindow({ width: 800, height: 600, show: false, webPreferences: { nodeIntegration: true } })

    q.timeout     = 60000
    q.concurrency = 5
    q.autostart   = false

    q.on('timeout', (next, job) => {
      dispatch(createAlert({
        dismissable: true,
        type: 'error',
        message: `Job Failed: ${job}`,
      }))
    })

    q.on('success', () => {
      this.setState({
        printCount: this.state.printCount + 1,
        printButtonLabel: `Printing ${this.state.printCount + 1}/${this.props.tasks.data.length}`
      })
    })

    q.on('end', () => {
      this.setState({
        startPrinting: false,
        updateTasks: true,
        printButtonLabel: "Finished Printing",
        printCount: 0
      }, () => {
        this.props.tasks.data = []
        this.fetchForPrintTasks()
      })
    })

    this.setState({ startPrinting: true }, () => {
      this.fetchForPrintTasks()
    })
  }

  viewHandler = (task: TaskType) => {
    window.open(this.printPreviewLink(
      task.print,
      { task_id: task.task_id, lead_id: task.related_to_id }),
      '_blank'
    )
  }

  printHandler = (task: TaskType, cb) => {
    if (electron != null) {
      let child = new electron.BrowserWindow({ width: 800, height: 600, show: false, parent: win, webPreferences: { nodeIntegration: true } })
      child.loadURL(this.printPreviewLink(task.print,
        { task_id: task.task_id, lead_id: task.related_to_id }))

      child.webContents.on('did-finish-load', () => {
        this.printHandlerHandleDidFinishLoad(win, child, task, cb)
      })
    }
  }

  showPrintAllButton = () => {
    return this.state.syncingState != FINISHED_SYNCING  ||
        this.state.startPrinting ||
        this.state.startExportingToCSV ||
        this.props.tasks.data.length == 0
  }

  showFilter = () => {
    return this.state.syncingState != FINISHED_SYNCING  ||
        this.state.startPrinting || this.state.startExportingToCSV
  }


  printHandlerHandleDidFinishLoad = (win, child, task, cb) => {
    const { dispatch } = this.props

    if (child.getTitle() == "Action Controller: Exception caught") {
      child.close()
      cb()
    } else {
      dispatch(createAlert({
        dismissable: true,
        dismissAfter: 2000,
        type: 'success',
        message: `Printing Task: ${task.task_id} for Lead: ${task.related_to_id}`,
      }))

      child.webContents.print({ silent: true, printBackground: false }, () => {
        child.close()
        this.markTaskComplete(task, cb)
      })
    }
  }

  markTaskComplete = (task: TaskType, cb) => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(() => {
        this.refs.printChannel.perform('updatePrintTask',
          { task: { task_id: task.task_id } })
      })
    ]).then(() => {
      cb()
      done()
    }))
  }

  printPreviewLink(template: string, params: TaskType) {
    const p = {
      part: 'text/html',
      mode: 'desktop',
      ...params
    }

    return `${C.PRINT.BASE}${template}?${queryString.stringify(p)}`
  }

  renderTasks = () => {
    const { tasks } = this.props

    let items = tasks.data.map(task => {
      return {
        'Task ID':    task.task_id,
        'Name':       task.related_to,
        'Status':     task.status,
        'Print Type': task.print,
        'Actions':    (
          <div>
            <RaisedButton
              label="View"
              secondary={true}
              onClick={() => this.viewHandler(task)}
              style={{ marginRight: '1rem' }}
            />

            <RaisedButton
              label="Close"
              secondary
              onClick={() => this.markTaskComplete(task, this.fetchTasks)}
              style={{ marginRight: '1rem' }}
            />
          </div>
        )

      }
    })

    return (
      <Table
        className="table"
        data={items}
        sortable={true}
        filterable={['Task ID', 'Name', 'Print Type']}
        width="100%"
      />
    )
  }

  handleFilterOnChange = (event, index, value) => {
    this.setState({ filter: value }, () => {
      this.fetchTasks()
    })
  }

  handleFilterAddressOnChange = (event, index, value) => {
    this.setState({ filterCountry: value }, () => {
      this.fetchTasks()
    })
  }

  renderToolbar = () => {
    return (
      <div className="toolbar">
        <div className="filter">
          <DropDownMenu
            value={this.state.filter}
            onChange={this.handleFilterOnChange}
            disabled={this.showFilter()}
          >
            <MenuItem value="purchase_confirmation" primaryText="Purchase Confirmation" />
            <MenuItem value="purchase_confirmation_certified" primaryText="Certified Purchase Confirmation" />
            <MenuItem value="booking_confirmation" primaryText="Booking Confirmation" />
            <MenuItem value="tour_confirmation" primaryText="Tour Confirmation" />
          </DropDownMenu>

          <DropDownMenu
            value={this.state.filterCountry}
            onChange={this.handleFilterAddressOnChange}
            disabled={this.showFilter()}
          >
            <MenuItem value="US" primaryText="US Mail" />
            <MenuItem value="Canada" primaryText="Canada Mail" />
            <MenuItem value="All" primaryText="All Mail" />
          </DropDownMenu>

          </div>

        <div className="count">{this.state.fetch_message}</div>

        {window.process && this.renderActions()}
      </div>
    )
  }

  render = ({ children } = this.props) => {
    return (
      <div className="print">
        <ActionCable
          ref='printChannel'
          channel={{channel: 'PrintChannel'}}
          onConnected={() => this.onConnected()}
          onReceived={(data) => this.onReceived(data)}
        />

        {children}

        {this.renderTasks()}

        {this.renderToolbar()}
      </div>
    )
  }
}

export default connect(getTasks)(Print)
