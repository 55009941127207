import React from 'react'
// import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import NoteField from 'views/vsf/views/NoteField'

export const FORM_NAME = 'wizard.notes'

const CardNote = () => {
  return (
    <NoteField />
  )
}

CardNote.propTypes = {}

export default reduxForm({ form: FORM_NAME })(CardNote)
