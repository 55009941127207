import { createSelector } from 'reselect'

import { selectId } from '../../auth/selectors'

const selectAllDestinationOptions = (state) => state.vsf.travel.destination.options
const selectAllTravelTimeOptions = (state) => state.vsf.travel.travel_time_months.options
const selectAllLengthOfStayOptions = (state) => state.vsf.travel.length_of_stay.options

export const selectActiveTravelDestination = (state) =>
  state.form.wizard &&
  state.form.wizard.accommodation &&
  state.form.wizard.accommodation.values &&
  state.form.wizard.accommodation.values.travel
    ? state.form.wizard.accommodation.values.travel.destination
    : ''

export const selectActiveTravelDestinationId = createSelector(
  [selectActiveTravelDestination, selectAllDestinationOptions],
  (activeLoc, options) => {
    if (activeLoc && options && options.length > 0) {
      const val = options.find(({ name }) => name === activeLoc)
      if (val) return val.id
    }
  }
)

export const selectDestinationOptions = createSelector(
  [selectAllDestinationOptions],
  (options) => {
    return options.map((opt) => opt.name)
  }
)

export const selectLengthOfStayOptions = createSelector(
  [selectAllLengthOfStayOptions],
  (options) => {
    return options
  }
)

export const selectTravelTimeOptions = createSelector(
  [selectAllTravelTimeOptions],
  (options) => {
    return options
  }
)

export const selectTravelInitValues = createSelector(
  [selectId], 
  (id) => {
    let destination = ''
    let length_of_stay = ''
    let travel_time_months = ''

    if (id === 'DEV') {
      destination = 'Las Vegas, NV'
      length_of_stay = '3 Day & 2 Night'
      travel_time_months = '18 Months'
    }

    return {
      destination,
      length_of_stay,
      travel_time_months,
    }
  }
)
