import { SET_PERMISSIONS } from './actions'

export const INITIAL_STATE = {
  canAccessSales: undefined,
  canAccessBilling: undefined,
  canAccessLeads: undefined,
  canAccessAdmin: undefined,
  canAccessMail: undefined,
  canAccessPrint: undefined,
  canAccessStamps: undefined,
  canAccessCaptureAndVoid: undefined,
  canAcessRefunds: undefined,
  canAccessScrubber: undefined,
  canAccessKitchen: undefined,
  canAccessSupport: undefined,
  canAccessStats: undefined,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PERMISSIONS:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
