import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PlaceIcon from 'material-ui-icons/Place'
import MenuItem from 'material-ui/MenuItem'
import { SelectField } from 'redux-form-material-ui'
import FieldLabel from 'views/ui/FieldLabel'

const renderDropdown = (destination_options) => {
  return destination_options
    .map(destination => {
      return (
        <MenuItem
          key={destination}
          value={destination}
          primaryText={destination}
        />
      )
    })
}

const DestinationFieldDropdown = ({ destination_options, required, resetGifts }) => {
  return (
    <div className="input radioField input-style">
      <FieldLabel
        icon={<PlaceIcon className="required-input-icon input-icon svg-icon" />}
        label="Travel Destination"
        required={true}
      />

      <Field
        id="destination"
        name="travel.destination"
        component={SelectField}
        hintText="Choose Destination"
        validate={required}
        onChange={resetGifts}
      >
        {renderDropdown(destination_options)}
      </Field>
    </div>
  )
}

DestinationFieldDropdown.propTypes = {
  destination_options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.func.isRequired,
  resetGifts: PropTypes.func.isRequired,
}

export default DestinationFieldDropdown
