import React from 'react'
import PropTypes from 'prop-types'

import TravelTimeRadioButtons from 'views/vsf/views/TravelTimeRadioButtons'

const required = value => value == null ? 'Required' : undefined

const TravelTimeContainer = ({ travel_time_months_options }) => {
  return (
    <div className="input-bubble-wrap">
      <TravelTimeRadioButtons
        travel_time_months_options={travel_time_months_options}
        required={required}
      />
    </div>
  )
} 

TravelTimeContainer.propTypes = {
  travel_time_months_options: PropTypes.arrayOf(
    PropTypes.number.isRequired
  ).isRequired,
}

export default TravelTimeContainer
