import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { TextField } from 'redux-form-material-ui'
import { textErrorStyle } from 'views/ui/styles/styles'
import FieldLabel from 'views/ui/FieldLabel'

const CreditCardCvcField = ({
  label,
  name,
  id,
  hintText,
  icon,
  required = false,
  clearLeft = false,
  smallField = false,
  validators = [],
  normalizer
}) => {
  return (
    <div className={`input relative radioField ${clearLeft ? "clearleft": ""}`}>

      <FieldLabel
        className="margin-zero"
        icon={icon}
        label={label}
        required={required}
        htmlFor={id}
      />

      <Field
        className={`${smallField ? "small-field" : ""}`}
        id={id}
        type={id}
        name={name}
        component={TextField}
        hintText={hintText}
        errorStyle={textErrorStyle}
        validate={validators}
        normalize={normalizer}
      />
    </div>
  )
}

CreditCardCvcField.propTypes = {
  label:       PropTypes.string.isRequired,
  // value for react-cred-card focused prop
  name:        PropTypes.string.isRequired,
  id:          PropTypes.string,
  hintText:    PropTypes.string,
  icon:        PropTypes.element.isRequired,
  required:    PropTypes.bool,
  clearLeft:   PropTypes.bool,
  smallField:  PropTypes.bool,
  validators:  PropTypes.arrayOf(PropTypes.func),
  normalizer:  PropTypes.func,
}

export default CreditCardCvcField
