import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import EmailIcon from 'material-ui-icons/Email'
import { TextField } from 'redux-form-material-ui'
import { textErrorStyle } from 'views/ui/styles/styles'
import { validateRequired, validateEmail } from 'helpers/validators'
import { normalizeUpper } from 'helpers/normalizers'
import FieldLabel from 'views/ui/FieldLabel'

const EmailField = () => (
  <div className="input radioField input-style width-third">

    <FieldLabel
      icon={<EmailIcon className="required-input-icon input-icon svg-icon" />}
      label="Email"
      required={true}
    />

    <Field
      id="email"
      name="email.email"
      component={TextField}
      fullWidth={true}
      hintText="Email address"
      errorStyle={ textErrorStyle }
      normalize={normalizeUpper}
      validate={[validateRequired, validateEmail]}
    />
  </div>
)

EmailField.propTypes = {}

export default EmailField