import React from 'react'
import PropTypes from 'prop-types'
import RaisedButton from 'material-ui/RaisedButton'

class MassActionToolBar extends React.Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    selectedCount: PropTypes.number.isRequired,
    massVoidRefundPayments: PropTypes.func.isRequired,
    unselectRefunds: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div className="toolbar on-top">
        <div className="mass-actions">
          <button className="green button" disabled={this.props.loading? 'disabled' : ''} onClick={() => this.props.massVoidRefundPayments()}>Void/Refund</button>
        </div>
        <div className="count">Count: {this.props.selectedCount}</div>
        <div className="wrap-right">
          <RaisedButton
            label="Unselect All"
            primary={false}
            disabled={this.props.loading}
            onClick={this.props.unselectRefunds}
            style={{ marginRight: '15px' }}
          />
        </div>
      </div>
    )
  }
}

export default MassActionToolBar
