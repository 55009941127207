import deepEqual from 'deep-equal'

export const initUserForm = (form, formData, {
  change,
}) => {
    formData.locations.forEach(({ id: locationId }) => {
      formData.gifts.forEach(({ id: giftId, location_id, input, values }) => {
        if (location_id) {
          // if gift has a specific location_id, only set it if it matches the location
          if (location_id === locationId) {
            change(form, `giftOptions.type.locationId:${locationId}.giftId:${giftId}`, 'default')
          }
        } else {
          change(form, `giftOptions.type.locationId:${locationId}.giftId:${giftId}`, 'default')
        }

        if (input === 'select' && values) {
          values.forEach((val) => {
            change(form, `giftOptions.values.locationId:${locationId}.giftId:${giftId}.option:${val}`, false)
          })
        }
      })
    })
}

export const getUserDataFromForm = (fieldsMapping, formData) => {
  return Object.entries(fieldsMapping).reduce((aggr, [formField, payloadField]) => {
    switch (formField) {
      case 'los':
        return {
          ...aggr,
          [payloadField]: formData[formField] && formData[formField]
            .map(({ value }) => value.split(',')
            .map((value) => parseInt(value, 10)))
        }

      case 'ttim':
        return {
          ...aggr,
          [payloadField]: formData[formField] && formData[formField].map(({ value }) => parseInt(value, 10))
        }

      case 'locations':
        return {
          ...aggr,
          [payloadField]: formData[formField] &&formData[formField].map(({ value }) => parseInt(value, 10))
        }

      case 'gifts':
        return {
          ...aggr,
          [payloadField]: formData.gifts && getGiftsFromFormdata(formData)
        }

      default:
        return {
          ...aggr,
          [payloadField]: formData[formField]
        }
    }
  }, {})
}

export const getActiveMergedPresets = (all, active) => {
  let _card = ''
  let _name =- ''
  let _locations = []
  let _ttim = []
  let _los = []
  let _gifts = []

  let _can_access_admin =  undefined
  let _can_access_billing = undefined
  let _can_access_capture_and_void = undefined
  let _can_access_kitchen = undefined
  let _can_access_leads = undefined
  let _can_access_mail = undefined
  let _can_access_print = undefined
  let _can_access_refunds = undefined
  let _can_access_sales = undefined
  let _can_access_scrubber = undefined
  let _can_access_stamps = undefined
  let _can_access_stats = undefined
  let _can_access_support = undefined
  let _skip_credit_card = undefined



  const presets = active ? all.filter((preset) => active.includes(preset.name)) : all

  presets.forEach(({
    credit_card = '',
    can_access_admin =  undefined,
    can_access_billing = undefined,
    can_access_capture_and_void = undefined,
    can_access_kitchen = undefined,
    can_access_leads = undefined,
    can_access_mail = undefined,
    can_access_print = undefined,
    can_access_refunds = undefined,
    can_access_sales = undefined,
    can_access_scrubber = undefined,
    can_access_stamps = undefined,
    can_access_stats = undefined,
    can_access_support = undefined,
    skip_credit_card = undefined,
    name = '',
    locations = [],
    travel_time = [],
    length_of_stay = [],
    gifts = [],
  }) => {
    _card = credit_card
    _name = name


    _can_access_admin = can_access_admin
    _can_access_billing = can_access_billing
    _can_access_capture_and_void = can_access_capture_and_void
    _can_access_kitchen = can_access_kitchen
    _can_access_leads = can_access_leads
    _can_access_mail = can_access_mail
    _can_access_print = can_access_print
    _can_access_refunds = can_access_refunds
    _can_access_sales = can_access_sales
    _can_access_scrubber = can_access_scrubber
    _can_access_stamps = can_access_stamps
    _can_access_stats = can_access_stats
    _can_access_support = can_access_support,
    _skip_credit_card = skip_credit_card

    locations.forEach((loc) => {
      _locations = _locations.filter((_loc) => _loc.id !== loc.id)
      _locations.push(loc)
    })

    travel_time.forEach((tt) => {
      _ttim = _ttim.filter((_tt) => _tt !== tt)
      _ttim.push(tt)
    })

    length_of_stay.forEach((losDaysNights) => {
      _los = _los.filter((_losDaysNights) => !deepEqual(_losDaysNights, losDaysNights))
      _los.push(losDaysNights)
    })

    gifts.forEach((gift) => {
      _gifts = _gifts.filter((_gift) =>
        _gift.id !== gift.id || _gift.id === gift.id && _gift.location_id !== gift.location_id
      )
      _gifts.push(gift)
    })
  })

  return {
    card: _card,
    locations: _locations,
    ttim: _ttim.sort((a, b) => a - b),
    los: _los,
    gifts: _gifts,
    name: _name,
    can_access_admin: _can_access_admin,
    can_access_billing: _can_access_billing,
    can_access_capture_and_void: _can_access_capture_and_void,
    can_access_kitchen: _can_access_kitchen,
    can_access_leads: _can_access_leads,
    can_access_mail: _can_access_mail,
    can_access_print: _can_access_print,
    can_access_refunds: _can_access_refunds,
    can_access_sales: _can_access_sales,
    can_access_scrubber: _can_access_scrubber,
    can_access_stamps: _can_access_stamps,
    can_access_stats: _can_access_stats,
    can_access_support: _can_access_support,
    skip_credit_card: _skip_credit_card,
  }
}

export const applyFormValues = (form, formValues, {
  change,
  arrayPush,
  arrayRemoveAll,
}, isEditing) => {
  const {
    card,
    gifts,
    name,
    can_access_admin,
    can_access_billing,
    can_access_capture_and_void,
    can_access_leads,
    can_access_mail,
    can_access_print,
    can_access_refunds,
    can_access_sales,
    can_access_scrubber,
    can_access_stamps,
    can_access_support,
    skip_credit_card,
    ...arrFormValues
  } = formValues

  change(form, 'card', card)
  if (isEditing) {
    change(form, 'name', name)
    change(form, 'uid', name)
    change(form, 'can_access_admin', can_access_admin)
    change(form, 'can_access_billing', can_access_billing)
    change(form, 'can_access_capture_and_void', can_access_capture_and_void)
    change(form, 'can_access_leads', can_access_leads)
    change(form, 'can_access_mail', can_access_mail)
    change(form, 'can_access_print', can_access_print)
    change(form, 'can_access_refunds', can_access_refunds)
    change(form, 'can_access_sales', can_access_sales)
    change(form, 'can_access_scrubber', can_access_scrubber)
    change(form, 'can_access_stamps', can_access_stamps)
    change(form, 'can_access_leads', can_access_leads)
    change(form, 'can_access_support', can_access_support)
    change(form, 'skip_credit_card', skip_credit_card)
  }

  Object.entries(arrFormValues).forEach(([key, values]) => {
    arrayRemoveAll(form, key)

    switch (key) {
      case 'ttim':
        values.forEach((months) => {
          arrayPush(form, 'ttim', {
            label: `${months} Months`,
            value: months.toString()
          })
        })
        break

      case 'los':
        values.forEach(([days, nights]) => {
          arrayPush(form, 'los', {
            label: `${days} Days & ${nights} Nights`,
            value: `${days}, ${nights}`
          })
        })
        break

      case 'locations':
        change(form, 'gifts', null)

        values.forEach(({ id, name }) => {
          arrayPush(form, 'locations', {
            label: name,
            value: id
          })

          gifts.forEach(({
            id: giftId,
            location_id,
            input,
            values: giftValues,
            gift_type
          }) => {
            if (location_id) {
              if (location_id === id) {
                change(form, `gifts.locationId:${id}.giftId:${giftId}`, true)
              }
            } else {
              change(form, `gifts.locationId:${id}.giftId:${giftId}`, true)
            }

            if (input === 'select' && giftValues) {
              giftValues.forEach((value) => {
                change(
                  form,
                  `giftOptions.values.locationId:${id}.giftId:${giftId}.option:${value}`,
                  true,
                )
              })
            }

            change(form, `giftOptions.type.locationId:${id}.giftId:${giftId}`, gift_type)
          })
        })
        break

      default:
        break
    }
  })
}

function getGiftsFromFormdata(formData) {
  let gifts = []

  Object.entries(formData.gifts).forEach(([locationId, _gifts]) => {
    if (_gifts) {
      Object.entries(_gifts).forEach(([giftId, included]) => {
        if (included) {
          const values = formData.giftOptions.values &&
            formData.giftOptions.values[locationId] &&
            formData.giftOptions.values[locationId][giftId]


          let gift = {
            gift_id: parseInt(giftId.split(':')[1], 10),
            gift_type: formData.giftOptions.type[locationId][giftId],
            location_id: parseInt(locationId.split(':')[1], 10)
          }

          if (values) {
            gift.values = Object.entries(values).map(([option, selected]) => {
              if (selected) return parseInt(option.split(':')[1], 10)
              else return null
            }).filter(Boolean)
          }

          gifts.push(gift)
        }
      })
    }
  })

  return gifts
}

export const getSampleFormData = () => {
  return {
    card: 'cc2',
    name: "test1234",
    password: "1234",
    giftOptions: {
      type: {
        'locationId:1': {
          'giftId:1': "default",
          'giftId:2': "closer",
          'giftId:3': "additional",
        },
        'locationId:2': {
          'giftId:4': "additional"
        }
      },
      values: {
        'locationId:2': {
          'giftId:4': {
            'option:500': true,
            'option:1000': true,
          }
        }
      },
    },
    gifts: {
      'locationId:1': {
        'giftId:1': true,
        'giftId:2': false,
        'giftId:3': true,
        'giftId:100': false,
      },
      'locationId:2': {
        'giftId:4': true,
      }
    },
    locations: [{
      label: "Las Vegas",
      value: 1,
    }, {
      label: "Florida",
      value: 2,
    }],
    los: [{
      label: "3 Days & 2 Nights",
      value: "3, 2"
    }, {
      label: "4 Days & 3 Nights",
      value: "4, 3"
    }],
    ttim: [{
      label: "18 Months",
      value: "18"
    }, {
      label: "24 Months",
      value: "24"
    }],
  }
}

export const getSampleUsers = () => {
  return [
    { name: "123tt", id: 29, email: "123tt@amg.com" },
    { name: "blv", id: 7, email: "blv@amg.com" },
    { name: "ylv", id: 9, email: "ylv@amg.com" },
    { name: "slv", id: 14, email: "slv@amg.com" },
    { name: "amg", id: 3, email: "amg@amg.com" },
    { name: "wfh", id: 22, email: "wfh@amg.com" },
    { name: "vsf$admin", id: 1, email: "vsf$admin@amg.com" },
    { name: "john", id: 23, email: "john@amg.com" },
    { name: "alv", id: 4, email: "alv@amg.com" },
    { name: "kor", id: 16, email: "kor@amg.com" },
    { name: "test12355", id: 28, email: "test12355@amg.com" },
    { name: "vlv", id: 8, email: "vlv@amg.com" },
    { name: "test", id: 24, email: "test@amg.com" },
    { name: "sgm", id: 19, email: "sgm@amg.com" },
    { name: "dlv", id: 5, email: "dlv@amg.com" },
    { name: "qor", id: 21, email: "qor@amg.com" },
    { name: "rlv", id: 13, email: "rlv@amg.com" },
    { name: "ior", id: 15, email: "ior@amg.com" },
    { name: "ims", id: 18, email: "ims@amg.com" },
    { name: "qlv", id: 10, email: "qlv@amg.com" },
    { name: "lor", id: 12, email: "lor@amg.com" },
    { name: "wor", id: 17, email: "wor@amg.com" },
    { name: "1234", id: 27, email: "1234@amg.com" },
    { name: "test66", id: 26, email: "test66@amg.com" },
    { name: "test12", id: 25, email: "test12@amg.com" },
    { name: "dev", id: 2, email: "dev@amg.com" },
    { name: "hlv", id: 6, email: "hlv@amg.com" },
    { name: "llv", id: 20, email: "llv@amg.com" },
    { name: "xlv", id: 11, email: "xlv@amg.com" }
  ]
}

export const getSampleState = (overrides) => {
  return {
    loading: { creating: false, preset: false },
    activeLocation: { id: 10, label: "Las Vegas, NV" },
    options: {
      card: ["cc1", "cc2"],
      gifts: [
        {
          id: 3,
          code: "3_2_ocean_cruise",
          label: "3/2 Ocean Cruise",
          image_url: null,
          format: "#{label} #{value}",
          input: "checkbox",
          value: "3/2 Ocean Cruise",
          included: false,
          location_id: null
        },
        {
          id: 4,
          code: "3_2_vacation_passport",
          label: "3/2 Vacation Passport",
          image_url: null,
          format: "#{label} #{value}",
          input: "checkbox",
          value: "3/2 Vacation Passport",
          included: false,
          location_id: null
        },
        {
          id: 8,
          code: "binions_slot_play",
          label: "$100 Promotional Slot Play at Binions",
          image_url: null,
          format: null,
          input: "checkbox",
          value: "$100 Binions Slot Play",
          included: false,
          location_id: null
        },
        {
          id: 9,
          code: "visa_gift_certificate_100",
          label: "$100 VISA Gift Card",
          image_url: null,
          format: null,
          input: "checkbox",
          value: "$100 Cash Voucher",
          included: false,
          location_id: null
        },
        {
          id: 11,
          code: "visa_gift_certificate_50",
          label: "$50 VISA Gift Card",
          image_url: null,
          format: null,
          input: "checkbox",
          value: "$50 Cash Voucher",
          included: false,
          location_id: null
        },
        {
          id: 12,
          code: "refundable_deposit",
          label: "Refundable Deposit",
          image_url: null,
          format: null,
          input: "checkbox",
          value: "Refundable Deposit",
          included: false,
          location_id: null
        },
        {
          id: 13,
          code: "refundable_deposit_200",
          label: "$200 Refundable Deposit",
          image_url: null,
          format: null,
          input: "checkbox",
          value: "$200 Refundable Deposit",
          included: false,
          location_id: null
        },
        {
          id: 1,
          code: "dining_dough",
          label: "$100 Dining Dough",
          image_url: "https://s3-us-west-2.amazonaws.com/a9e7yti-images/ddd.png",
          format: "#{label}",
          input: "checkbox",
          value: "$100 Dining Dough",
          included: false,
          location_id: 10
        },
        {
          id: 5,
          code: "pampas",
          label: "$50 Gift Certificate to Pampas Brazilian Steakhouse",
          image_url: null,
          format: null,
          input: "checkbox",
          value: "$50 Gift Certificate to Pampas",
          included: false,
          location_id: 10
        },
        {
          id: 7,
          code: "vegas_bite_card",
          label: "Las Vegas Bite Card",
          image_url: null,
          format: null,
          input: "checkbox",
          value: "Vegas Bite Card",
          included: false,
          location_id: 10
        },
        {
          id: 10,
          code: "2_carnival_world_buffet_tickets",
          label: "2 Carnival World Buffet Tickets",
          image_url:
            "https://s3-us-west-2.amazonaws.com/allenmg-email/gift-buffet.png",
          format: "#{label}",
          input: "checkbox",
          value: "2 Carnival World Buffet Tickets",
          included: false,
          location_id: 10
        },
        {
          id: 2,
          code: "travel_savings_dollars",
          label: "Travel Savings Dollars",
          image_url: "https://s3-us-west-2.amazonaws.com/a9e7yti-images/tsd.png",
          format: "$#{value} #{label}Ё",
          input: "select",
          included: false,
          location_id: null,
          values: [500, 1000]
        },
        {
          id: 6,
          code: "show_tickets",
          label: "Show Tickets",
          image_url: null,
          format: "#{value} #{label}",
          input: "select",
          included: false,
          location_id: 10,
          values: [2, 4, 6, 8]
        },
        {
          id: 14,
          code: "2_laugh_factory_tickets",
          label: "2 Laugh Factory Tickets",
          image_url:
            "https://s3-us-west-2.amazonaws.com/allenmg-email/gift-laugh-factory.png",
          format: "#{label}",
          input: "checkbox",
          value: "2 Laugh Factory Tickets",
          included: false,
          location_id: 10
        }
      ],
      los: [[3, 2], [4, 3], [5, 4], [6, 5]],
      locations: [
        { id: 1, name: "Branson, MO" },
        { id: 2, name: "Cabo" },
        { id: 3, name: "Charleston, SC" },
        { id: 4, name: "Dana Point, CA" },
        { id: 5, name: "Daytona, FL" },
        { id: 6, name: "Gatlinburg, TN" },
        { id: 7, name: "HB Special (See Notes)" },
        { id: 8, name: "Kill Devil Hills, NC" },
        { id: 9, name: "Lake Tahoe" },
        { id: 10, name: "Las Vegas, NV" },
        { id: 11, name: "Miami Beach, FL" },
        { id: 12, name: "Myrtle Beach, SC" },
        { id: 13, name: "New Smyrna Beach, FL" },
        { id: 14, name: "Orlando, FL" },
        { id: 15, name: "Palm Springs, CA" },
        { id: 16, name: "San Clemente, CA" },
        { id: 17, name: "Scottsdale, AZ" },
        { id: 18, name: "Sedona, AZ" },
        { id: 19, name: "Virginia Beach, FL" },
        { id: 20, name: "Williamsburg, VA" },
        { id: 21, name: "Peoria, AZ" }
      ],
      ttim: [12, 18, 24, 36],
      presets: [
        {
          name: "blv",
          email: "blv@amg.com",
          travel_time: [18, 24],
          length_of_stay: [[3, 2], [4, 3]],
          credit_card: "cc2",
          last_sign_in_at: null,
          sign_in_count: 0,
          current_sign_in_ip: null,
          last_sign_in_ip: null,
          locations: [
            {
              id: 10,
              name: "Las Vegas, NV",
              qualifications: {
                age: "25+",
                income: "50k+",
                presentation: "60-90"
              }
            }
          ],
          gifts: [
            {
              id: 1,
              label: "$100 Dining Dough",
              image: "https://s3-us-west-2.amazonaws.com/a9e7yti-images/ddd.png",
              format: "#{label}",
              gift_type: "additional",
              input: "checkbox",
              included: false,
              location_id: 10,
              value: "$100 Dining Dough"
            },
          ]
        },
        {
          name: "qor",
          email: "qor@amg.com",
          travel_time: [18, 24],
          length_of_stay: [[3, 2], [4, 3]],
          credit_card: "cc2",
          last_sign_in_at: null,
          sign_in_count: 0,
          current_sign_in_ip: null,
          last_sign_in_ip: null,
          locations: [
            {
              id: 10,
              name: "Las Vegas, NV",
              qualifications: {
                age: "25+",
                income: "50k+",
                presentation: "60-90"
              }
            },
          ],
          gifts: [
            {
              id: 4,
              label: "3/2 Vacation Passport",
              image: null,
              format: "#{label} #{value}",
              gift_type: "closer",
              input: "checkbox",
              included: false,
              location_id: 12,
              value: "3/2 Vacation Passport"
            },
          ]
        }
      ]
    },
    activePresets: ["blv", "qor"],
    ...overrides
  }
}
