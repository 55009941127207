import React from 'react'
import PropTypes from 'prop-types'
import DestinationFieldRadioButtons from 'views/vsf/views/DestinationFieldRadioButtons'
import DestinationFieldDropdown from 'views/vsf/views/DestinationFieldDropdown'

const required = value => value == null ? 'Required' : undefined

const renderDestinationField = (destination_options, resetGifts) => {
  if (destination_options.length > 4) {
    return (
      <DestinationFieldDropdown
        required={required}
        destination_options={destination_options}
        resetGifts={resetGifts}
      />
    )
  }
  return (
    <DestinationFieldRadioButtons
      destination_options={destination_options}
      required={required}
      resetGifts={resetGifts}
    />
  )
}

const DestinationFieldContainer = ({ destination_options, resetGifts }) => {
  return (
    <div className="input-bubble-wrap">
      {renderDestinationField(destination_options, resetGifts)}
    </div>
  )
}

DestinationFieldContainer.propTypes = {
  destination_options: PropTypes.arrayOf(PropTypes.string).isRequired,
  resetGifts: PropTypes.func.isRequired
}

export default DestinationFieldContainer
