import React from 'react'
import PropTypes from 'prop-types'

const ConfirmationField = ({ label, error, value }) => {
  return (
    <div className={`confirmation-field-group ${error ? 'error' : ''}`}>
      {label && <div className="confirmation-field-label">{label}</div>}
      <div className="confirmation-field-value">{value}</div>
      <div className="confirmation-field-error">{error}</div>
    </div>
  )
}

ConfirmationField.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ConfirmationField