import C from 'config/environment'
import { checkStatus, parseJSON } from './concerns/railsRest'
import Auth from 'models/Auth'
import { createAlert } from 'controllers/AlertController'

class AdminController {
  data = {}
  app

  constructor(app) {
    this.app = app

    this.data = { users: [] }
  }

  resetTokens(id) {
    let data = { id: id }

    return fetch(C.API.BASE + '/admin/reset_tokens', {
      method: 'POST',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(checkStatus)
    .then(parseJSON)
    .then((user) => {
      const { dispatch } = this.app.props

      this.app.setState((prevState) => {
        return {
          ...prevState,
          admin: {
            ...prevState.admin,
            data: prevState.admin.data.map((_user) =>
              _user.id === user.id
                ? user
                : _user
            )
          }
        }
      })


      dispatch(createAlert({
        type: 'success',
        message: 'Tokens have been reset',
        dismissAfter: 2000,
      }))
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to reset the users tokens'
      let errors = [error]
      let stackTrace = [error.stack]
      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      return error
    })
  }

  updatePassword(data) {
    return fetch(C.API.BASE + '/admin/update_password', {
      method: 'POST',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(checkStatus)
    .then(parseJSON)
    .then((user) => {
      const { dispatch } = this.app.props
      this.app.setState((prevState) => {
        return {
          ...prevState,
          admin: {
            ...prevState.admin,
            data: prevState.admin.data.map((_user) => 
              _user.id === user.id
                ? user
                : _user
            )
          }
        }
      })

      dispatch(createAlert({
        type: 'success',
        message: `Updated password for ${data.user}`,
        dismissAfter: 2000
      }))
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to update the users password'
      let errors = [error]
      let stackTrace = [error.stack]

      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      return error
    })
  }

  deleteUser(id) {
    return fetch(C.API.BASE + `/users/${id}`, {
      method: 'DELETE',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      }
    })
    .then(checkStatus)
    .then(parseJSON)
    .then((user) => {
      const { dispatch } = this.app.props
      this.app.setState((prevState) => {
        let clone = {...prevState}
        let idx = clone.admin.data.map(u => u.name).indexOf(user.name)
        clone.admin.data.splice(idx, 1)
        return clone
      })

      dispatch(createAlert({
        type: 'success',
        message: `Deleted User`,
        dismissAfter: 2000
      }))
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to Delete the User'
      let errors = [error]
      let stackTrace = [error.stack]

      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      return error
    })
  }

}

export default AdminController
