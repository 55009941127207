import React, { Fragment } from "react"
import PropTypes from "prop-types"
import TextField from "material-ui/TextField"
import Divider from "material-ui/Divider"
import CardIcon from "material-ui/svg-icons/action/credit-card"
import DollarSignIcon from "material-ui/svg-icons/editor/attach-money"
import LocationIcon from "material-ui/svg-icons/communication/location-on"
import FlatButton from "material-ui/FlatButton"
import SearchIcon from "material-ui/svg-icons/action/search"
import CloseIcon from "material-ui/svg-icons/navigation/close"

import Button from "../../../ui/Button"
import UpgradeForm from "../UpgradeForm/index"
import AddressForm from "../AddressForm/index"
import CardForm from "../../containers/CardForm"
import Dialog from "../../../containers/Dialog"

import "./index.scss"
const PRIMARY_COLOR = "#333"

const styles = {
  floatingLabelStyle: {
    color: PRIMARY_COLOR,
    fontWeight: "bold",
  },
  leadId: {
    style: {
      marginBottom: "-8px",
    },
  },
  invoiceCheck: {
    style: {
      marginLeft: 25,
    },
  },
  multiLine: {
    style: {
      minWidth: 400,
    },
  },
  small: {
    style: {
      maxWidth: 100,
    },
  },
}

const Upgrade = ({
  leadId,
  onInvoiceChange,
  onInvoiceSubmit,
  form,
  packageDetails,
  hasPackageData,
  searchingForAddress,
  onToggleSearch,
  onReset,
  handleSubmit,
  onSubmit,
  onDialogClose,
  change,
  hasErrors,
}) => {
  const { package_code } = packageDetails
  const searchButtonIsDisabled = !!(
    !leadId.value ||
    (leadId.value && leadId.error)
  )
  const submitButtonIsDisable = false
  const renderResetDialogButton = hasPackageData && hasErrors

  return (
    <Fragment>
      <Dialog
        actions={[
          <Button
            key="close"
            label="close"
            className="basic"
            onClick={onDialogClose}
          />,
          renderResetDialogButton ? (
            <Button
              key="reset"
              label={"Reset Form"}
              className="red"
              onClick={onReset}
            />
          ) : null,
        ].filter(Boolean)}
      />
      <h2 className="Upgrade-heading">Billing Details</h2>
      <div className="Upgrade-number-container">
        <TextField
          name="leadId"
          id="leadId"
          hintText="1231565000015598372"
          floatingLabelText="lead id"
          floatingLabelFixed={true}
          onKeyPress={e => {
            e.which === 13 && onInvoiceSubmit()
          }}
          onChange={onInvoiceChange}
          value={leadId.value}
          errorText={leadId.error}
          style={styles.leadId.style}
          floatingLabelStyle={styles.floatingLabelStyle}
        />
        <Button
          className={`green button ${searchButtonIsDisabled ? "disabled" : ""}`}
          disabled={searchButtonIsDisabled}
          onClick={onInvoiceSubmit}
        >
          Find
        </Button>
      </div>
      <form className="Upgrade" onSubmit={handleSubmit(onSubmit)}>
        {hasPackageData && (
          <Fragment>
            <Divider
              style={{
                marginTop: 30,
                backgroundColor: "#999",
              }}
            />
            <h3 className="Upgrade-package-heading">
              Package Code: {package_code}
            </h3>

            <div className="Upgrade-small-heading">
              <CardIcon color={PRIMARY_COLOR} />
              <h4>Credit Card Details</h4>
            </div>
            <CardForm styles={styles} form={form} />

            <div className="Upgrade-small-heading">
              <LocationIcon color={PRIMARY_COLOR} />
              <h4>Billing Address Details</h4>
              <FlatButton
                onClick={onToggleSearch}
                label="Search"
                icon={searchingForAddress ? <CloseIcon /> : <SearchIcon />}
                style={{
                  marginLeft: 10,
                }}
              />
            </div>
            <AddressForm
              styles={styles}
              searchingForAddress={searchingForAddress}
              onToggleSearch={onToggleSearch}
              onChange={change}
            />

            <div className="Upgrade-small-heading">
              <DollarSignIcon
                style={{
                  marginLeft: "-4px",
                }}
                color={PRIMARY_COLOR}
              />
              <h4
                style={{
                  marginLeft: 5,
                }}
              >
                Upgrade Details
              </h4>
            </div>
            <UpgradeForm styles={styles} />

            <div className="Upgrade-buttons">
              <Button className={`red button`} onClick={onReset} type="button">
                Reset
              </Button>

              <Button
                className={`green button ${
                  submitButtonIsDisable ? "disabled" : ""
                }`}
                disabled={submitButtonIsDisable}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Fragment>
        )}
      </form>
    </Fragment>
  )
}

Upgrade.propTypes = {
  form: PropTypes.string.isRequired,
  packageDetails: PropTypes.object,
  searchingForAddress: PropTypes.bool,
  hasErrors: PropTypes.bool,
  hasPackageData: PropTypes.bool,
  leadId: PropTypes.object.isRequired,
  onInvoiceSubmit: PropTypes.func.isRequired,
  onInvoiceChange: PropTypes.func.isRequired,
  onToggleSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
}

export default Upgrade
