import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'material-ui/Checkbox'

import './index.scss'

const GiftHelpers = ({
  selectAll,
  onSelectAllToggle
}) => {
  return (
    <div className="GiftHelper-container">
      <Checkbox
        label="Select All"
        checked={selectAll}
        onCheck={onSelectAllToggle}
      />
    </div>
  )
}

GiftHelpers.propTypes = {
  selectAll: PropTypes.bool.isRequired,
  onSelectAllToggle: PropTypes.func.isRequired
}

export default GiftHelpers