import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon'

const LeadsIcon = ({ color, viewBox, ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <path fill={color} d="M70.364,45.702c1.775-2.773,2.804-6.053,2.804-9.541c0-9.813-7.985-17.798-17.799-17.798  c-4.806,0-9.401,1.976-12.746,5.41c-8.87,1.001-15.79,8.542-15.79,17.676c0,3.503,1.031,6.763,2.787,9.522  c-7.236,4.939-11.664,13.23-11.664,22.047c0,2.521,0.352,5.014,1.043,7.414c0.206,0.715,0.861,1.207,1.605,1.207l48.05-0.002  c0.746,0,1.397-0.492,1.605-1.207c0.383-1.324,0.649-2.678,0.822-4.049h8.312c0.746,0,1.399-0.49,1.605-1.207  c0.691-2.396,1.043-4.891,1.043-7.408C82.043,58.939,77.609,50.641,70.364,45.702z M69.825,36.161  c0,6.146-3.926,11.497-9.447,13.554c1.303-2.473,2.048-5.281,2.048-8.266c0-8.768-6.375-16.056-14.731-17.516  c2.282-1.43,4.935-2.228,7.675-2.228C63.34,21.706,69.825,28.19,69.825,36.161z M44.631,26.993c7.971,0,14.455,6.484,14.455,14.456s-6.484,14.456-14.455,14.456c-7.973,0-14.456-6.484-14.456-14.456S36.658,26.993,44.631,26.993z M21.898,78.295c-0.398-1.721-0.6-3.492-0.6-5.277c0-7.783,3.947-15.094,10.379-19.4c3.249,3.457,7.846,5.629,12.953,5.629c5.005,0,9.526-2.084,12.764-5.422c0.141-0.018,0.281-0.033,0.422-0.053c6.295,4.326,10.146,11.557,10.146,19.246c0,1.783-0.199,3.553-0.598,5.275L21.898,78.295z M78.104,73.039l-6.802,0.002c0-0.01,0.003-0.016,0.003-0.023c0-7.859-3.525-15.291-9.413-20.312c2.449-0.975,4.632-2.475,6.408-4.361c6.445,4.306,10.401,11.626,10.401,19.419C78.701,69.549,78.5,71.318,78.104,73.039z">
    </path>
  </SvgIcon>
)

LeadsIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

LeadsIcon.defaultProps = {
  color: "rgba(0,0,0,0.7)",
  viewBox: "0 0 100 100",
}

export default LeadsIcon
