import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ children, label, className, disabled, icon, ...props }) => (
  <button className={`button ${className} ${icon ? 'icon' : ''} ${disabled ? 'disabled' : ''}`} {...props} disabled={disabled}>
    {icon}
    <span>{label}</span>
    {children}
  </button>
)

Button.propTypes = {
  icon: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool
}

export default Button
