import React from 'react'

/**
 * Create a higher-order component wrapping a child component, passing along all props as a proxy.
 *
 * @param {XML} Component The React component to wrap.
 * @param {Array} Options for the tab navigation
 * @returns {HOC} A wrapper component that exposes additional component props accessible via the
 *                child component.
 */
export default (Component, Tabs) => {
  return class Tabable extends React.Component {
    state = {
      tab: 0
    }

    constructor(props) {
      super(props)

      this.setTabbed = this.setTab.bind(this, this.state.tab)
    }

    /**
     * Set the current Tab state.
     *
     * @param {Boolean} tab Whether the component is currently loading.
     */
    setTab = (tab) => {
      this.setState({ tab: tab })
    }

    renderTabs = () => {
      const { tab } = this.state
      const tabs = Tabs.map((name, i) => (
        <button className={tab === i ? 'selected' : ''} key={name} onClick={() => this.setTab(i)}>{name}</button>
      ))

      return (
        <nav className="subnav">{tabs}</nav>
      )
    }

    /**
     * Render the wrapped component, passing along props from this HOC's internal state.
     *
     * @returns {XML} The wrapped component with additional props.
     */
    render() {
      return (
        <Component
          ref={(elem) => { this.component = elem }}
          setTab={this.setTab}
          tab={this.state.tab}
          renderTabs={this.renderTabs}
          {...this.props}
          {...this.state}
        >
        </Component>
      )
    }
  }
}
