import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon'

const BillingIcon = ({ color, viewBox, ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <g fill={color}>
      <path d="M28.03125,0.154411765 L2,0.154411765 C1,0.154411765 0.1875,0.957352941 0.1875,1.94558824 L0.1875,5.25 L29.875,5.25 L29.875,1.97647059 C29.84375,0.957352941 29.03125,0.154411765 28.03125,0.154411765 Z" id="Shape"></path>
      <path d="M0.15625,19.0544118 C0.15625,20.0426471 0.96875,20.8455882 1.96875,20.8455882 L28,20.8455882 C29,20.8455882 29.8125,20.0426471 29.8125,19.0544118 L29.8125,8.43088235 L0.125,8.43088235 L0.125,19.0544118 L0.15625,19.0544118 Z M20.375,14.2367647 C20.375,13.7735294 20.75,13.4029412 21.21875,13.4029412 L25.78125,13.4029412 C26.25,13.4029412 26.625,13.7735294 26.625,14.2367647 L26.625,16.8308824 C26.625,17.2941176 26.25,17.6647059 25.78125,17.6647059 L21.21875,17.6647059 C20.75,17.6647059 20.375,17.2941176 20.375,16.8308824 L20.375,14.2367647 Z" id="Shape"></path>
    </g>
  </SvgIcon>
)

BillingIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

BillingIcon.defaultProps = {
  color: "rgba(0,0,0,0.7)",
  viewBox: "0 0 30 20",
}

export default BillingIcon
