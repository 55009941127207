import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Moment from 'moment'
import { Table } from 'reactable'
import Checkbox from 'material-ui/Checkbox'

import { api } from 'controllers/Rest'
import { createAlert } from 'controllers/AlertController'
import Spinner from "../ui/Spinner"

import RefundsController from 'controllers/RefundsController'
import FilterToolBar from '../refund/FilterToolBar'
import MassActionToolBar from '../refund/MassActionToolBar'
import MassActionResults from '../capture_void/MassActionResults'

export const getRefunds = (state) => {
  return {
    refunds: state.refunds
  }
}

class Refund extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    refunds: PropTypes.object,
    loading: PropTypes.func
  }

  state = {
    refunds: [],
    filter: {
      gateway: null,
      processing_status: 'RF - Sent for Processing'
    },
    showMassActionResults: false,
    selectedRefunds: [],
    massActionResults: {},
    tableFilterValue: '',
    loading: false,
    loadingMessage: '',
  }

  componentWillMount() {
    this.setState({
      refunds: this.props.refunds
    })

    this.reload()
  }

  reload = () => {
    const { loading, dispatch } = this.props

    loading(done => dispatch(api.refunds.force(this.state.filter))
      .then(() => done())
      .then(() => {
        this.setState({
          refunds: this.props.refunds
        })
      })
      .catch(e => dispatch(createAlert({
        dismissable: true,
        type: 'error',
        message: `Error loading Payments: ${e.message}`,
      })))
     )
  }

  fetchRefunds = () => {
    const { loading, dispatch } = this.props
    loading((done) => Promise.all([
      dispatch(
        api.refunds(this.state.filter)
      )
    ]).then(() => {
      done()
      this.setState({
        refunds: this.props.refunds
      })
    }))
  }

  voidRefund(refundId) {
    let admin = new RefundsController(this)
    admin.voidRefund(refundId)
  }

  refund(refundId) {
    let admin = new RefundsController(this)
    admin.refund(refundId)
  }

  massVoidRefundPayments = () =>  {
    let admin = new RefundsController(this)
    admin.massVoidRefundPayments()
  }

  clearFilters = () => {
    this.setState({
      filter: {
        processing_status: 'RF - Sent for Processing',
        gateway: null,
      }
    }, () => {
      this.fetchRefunds()
    })
  }

  closeDialog = () => {
    this.setState({
      showMassActionResults: false
    })
  }

  filterState = (event, index, value) => {
    this._filterProperty('processing_status', value)
  }

  filterGateway = (event, index, value) => {
    this._filterProperty('gateway', value)
  }

  _filterProperty = (propertyName, propertyValue) => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(
        api.refunds(
          Object.assign(
            {},
            this.state.filter,
            { [propertyName]: propertyValue }
          )
        )
      )
    ]).then(() => {
      done()
      this.setState({
        refunds: this.props.refunds,
        filter: {
          ...this.state.filter,
          [propertyName]: propertyValue
        }
      })
    }))
  }

  selectAllRefunds = () => {
    let allRefunds= this.state.refunds.data
    if (this.state.selectedRefunds.length === 0) {
      this.setState({
        selectedRefunds: allRefunds.map((refund) => refund.id)
      })
    } else {
      this.unselectRefunds()
    }
  }

  selectRefund = refund => () => {
    const { selectedRefunds } = this.state
    if (this.state.selectedRefunds.indexOf(refund.id) >= 0) {
      this.setState({
        selectedRefunds: selectedRefunds.filter((val) => val !== refund.id)
      })
    } else {
      this.setState({
        selectedRefunds: [].concat(selectedRefunds, refund.id)
      })
    }
  }

  unselectRefunds = () => {
    this.setState({
      selectedRefunds: []
    })
  }

  shouldRenderActionButtons = (refund) => {
    return (
      (this.state.selectedRefunds.length == 0) &&
        (!refund.refund_transaction_code)
    )
  }

  _renderLinkForLead = (name, link) => {
    return <a onClick={(e) => this._openLeadInNewWindow(e, link)} href={link}>{name}</a>
  }

  _openLeadInNewWindow = (e, link) => {
    e.preventDefault()
    let userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf(' electron/') > -1) {
      window.require('electron').shell.openExternal(link)
    } else {
      let width = window.innerWidth / 2
      let height = window.innerHeight
      window.open(link, 'Google', `left=${width},width=${width},height=${height}`)
    }
  }

  _renderToolBar = () => {
    if (this.state.selectedRefunds.length > 0) {
      return (
        <MassActionToolBar
          loading={this.state.loading}
          selectedCount={this.state.selectedRefunds.length}
          unselectRefunds={this.unselectRefunds}
          massVoidRefundPayments={this.massVoidRefundPayments}
        />
      )
    } else {
      return (
        <FilterToolBar
          gatewayFilterValue={this.state.filter.gateway}
          filterGatewayOnChange={this.filterGateway}
          processingStatusFilterValue={this.state.filter.processing_status}
          filterProcessingStatusOnChange={this.filterState}
          refundCount={this.state.refunds.data.length}
          loading={this.state.loading}
          clearFilters={this.clearFilters}
          fetchRefunds={this.fetchRefunds}
        />
      )
    }
  }

  _renderRefunds = () => {
    const refunds = this.state.refunds
    let paged = 0

    if (refunds.loading)
      return <div className="big text">Loading... please wait</div>

    if (refunds.data.length < 1)
      return <div className="big text">No payments found.</div>

    if (refunds.data.length > 20)
      paged = 300

    let items = refunds.data.map(refund => {
      let payment = refund.associated_payment

      let name
      if (payment) {
        name = this._renderLinkForLead(payment.lead_name, payment.lead_link)
      } else {
        name = this._renderLinkForLead(refund.guest, refund.guest_link)
      }

      let refundItem = {
        'Selection': refund.id !== '99999' ? <Checkbox
          checked={this.state.selectedRefunds.includes(refund.id)}
          onCheck={this.selectRefund(refund)}
        /> : '',
        'Date Requested': refund.date_requested && Moment(refund.date_requested).format("M/D/YY"),
        'Auth Code': refund.authorization_code,
        'Upgrade Auth Code': refund.upgrade_auth_code,
        'Name': name,
      }

      if (this.state.filter.processing_status !== 'RF - Sent for Processing') {
        refundItem['Refund Transaction Code'] = refund.refund_transaction_code
        refundItem['Date Processed'] = refund.date_processed && Moment(refund.date_processed).format("M/D/YY")
      }

      if (this.shouldRenderActionButtons(refund)) {
        let action = ''
        if (
          !payment ||
          payment.state === "settledSuccessfully" ||
          (((new Date).getTime() - (new Date(payment.created_at)).getTime()) / (1000 * 3600 * 24) > 120)
        ) {
          action = <button className="green button" disabled={this.state.loading? 'disabled' : ''} onClick={() => this.refund(refund.id)}>Refund</button>
        } else {
          action = <button className="red button" disabled={this.state.loading? 'disabled' : ''} onClick={() => this.voidRefund(refund.id)}>Void</button>
        }
        refundItem['Actions'] = (
          <div className="nowrap">
            {action}
          </div>
        )
      }

      return refundItem
    })

    let columns = [
      {key: 'Selection', label: <Checkbox checked={this.state.selectedRefunds.length > 0} onCheck={() => this.selectAllRefunds()} />},
      'Date Requested',
      'Auth Code',
      'Upgrade Auth Code',
      'Name'
    ]
    if (this.state.filter.processing_status !== 'RF - Sent for Processing')
      columns = [...columns, 'Refund Transaction Code', 'Date Processed']
    if (this.state.selectedRefunds.length == 0)
      columns = [...columns, 'Actions']


    return (
      <Table
        className="table"
        columns={columns}
        data={items}
        filterable={[
          {
            column: 'Name',
            filterFunction: function(contents, filter) {
              return (contents.props.children.toLowerCase().indexOf(filter) > -1)
            }
          },
          'Transaction',
          'Auth Code',
          'Upgrade Auth Code'
        ]}
        onFilter={(val) => this.setState({...this.state, tableFilterValue: val})}
        filterBy={this.state.tableFilterValue}
        sortable={[
          'Auth Code',
          'Upgrade Auth Code',
          'Refund Transaction Code',
          {
            column: 'Name',
            sortFunction: function(a, b) {
              return a.props.children.toLowerCase() > b.props.children.toLowerCase()
            }
          },
          {
            column: 'Date Processed',
            sortFunction: function(a, b) {
              let dateA = (new Date(a)).getTime() || 0
              let dateB = (new Date(b)).getTime() || 0

              return dateA > dateB ? 1 : -1
            }
          },
          {
            column: 'Date Requested',
            sortFunction: function(a, b) {
              let dateA = (new Date(a)).getTime() || 0
              let dateB = (new Date(b)).getTime() || 0

              return dateA > dateB ? 1 : -1
            }
          }
        ]}
        itemsPerPage={paged}
        pageButtonLimit={10}
        width="100%"
      />
    )
  }

  renderSpinner = () => {
    if (this.state.loading) {
      return <Spinner text={this.state.loadingMessage } />
    }
  }

  render = ({ children } = this.props) => {
    return (
      <div className="refund">
        {children}
        <MassActionResults
          show={this.state.showMassActionResults}
          closeDialog={this.closeDialog}
          success={this.state.massActionResults.success}
          errors={this.state.massActionResults.errors}
          title={this.state.massActionResults.action}
        />
        {this._renderToolBar()}
        {this.renderSpinner()}
        {this._renderRefunds()}
      </div>
    )
  }
}

export default connect(getRefunds)(Refund)
