import {
  SET_USER_OPTIONS,
  UPDATE_ACTIVE_LOCATION,
  SET_USER_LOADING,
  SET_PRESETS,
  REMOVE_PRESET,
  PUSH_ACTIVE_PRESET,
  UPDATE_EDITING_PRESETS,
  REMOVE_ACTIVE_PRESET,
  SET_EDITING_USER,
  RESET_ACTIVE_PRESETS,
} from './actions'

export const INITIAL_STATE = {
  loading: {
    creating: false,
    preset: false,
    editing: false
  },
  activeLocation: {
    id: null,
    label: ''
  },
  options: {
    card: [],
    gifts: [],
    // length_of_stay
    los: [],
    locations: [],
    // travel_time_in_months
    ttim: [],
    presets: []
  },
  activePresets: [],
  editing: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload.options
        }
      }

    case SET_PRESETS:
      return {
        ...state,
        options: {
          ...state.options,
          presets: [
            ...state.options.presets.filter(({ name }) => {
              return name !== action.payload.preset.name
            }),
            action.payload.preset
          ]
        }
      }

    case REMOVE_PRESET:
      return {
        ...state,
        options: {
          ...state.options,
          presets: state.options.presets.filter(({ name }) => {
            return name !== action.payload.id
          })
        }
      }

    case PUSH_ACTIVE_PRESET:
      return {
        ...state,
        activePresets: state.editing
          ? [action.payload.id]
          : [
            ...state.activePresets,
            action.payload.id
          ]
      }

    case REMOVE_ACTIVE_PRESET:
      return {
        ...state,
        activePresets: state.editing
          ? []
          : state.activePresets.filter((preset) =>
              preset !== action.payload.id
        )
      }

    case RESET_ACTIVE_PRESETS:
      return {
        ...state,
        activePresets: INITIAL_STATE.activePresets
      }

    case UPDATE_EDITING_PRESETS:
      return {
        ...state,
        options: {
          ...state.options,
          presets: state.options.presets.map((preset) => {
            if (preset.name === state.editing) {
              return {
                ...preset,
                name: action.payload.id,
                email: `${action.payload.id}@amg.com`
              }
            } else {
              return preset
            }
          })
        },
        activePresets: [
          ...state.activePresets.filter((active) =>
            active !== state.editing
          ),
          action.payload.id
        ],
        editing: action.payload.id
      }

    case UPDATE_ACTIVE_LOCATION:
      return {
        ...state,
        activeLocation: {
          ...action.payload.activeLocation
        }
      }

    case SET_USER_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.type]: action.payload.loading
        }
      }

    case SET_EDITING_USER:
      return {
        ...state,
        editing: action.payload.uid,
      }

    default:
      return state
  }
}
