import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon'

const KitchenIcon = ({ color, viewBox, ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <g fill={color}>
      <path d="M55.602,0.398 L2.399,0.398 C1.0982,0.398 0.0006,1.30744297 0.0006,2.37803632 L0.0006,42.4179637 C0.0006,43.4918593 1.1022,44.398 2.399,44.398 L55.598,44.398 C56.8988,44.398 57.9964,43.488557 57.9964,42.4179637 L58.000306,2.38133859 C58.000306,1.22364807 56.898706,0.398082557 55.601906,0.398082557 L55.602,0.398 Z M35.301,14.5 C34.801,14.19922 34.69944,13.60156 35.00022,13.1016 C35.301,12.60164 35.89866,12.50004 36.39862,12.80082 L50.60162,22.00002 C50.9024,22.19924 51.10162,22.50002 51.10162,22.8008 C51.10162,23.10158 50.9024,23.50002 50.60162,23.60158 L36.40262,32.80078 C36.1995,33 36.00028,33 35.80106,33 C35.50028,33 35.1995,32.80078 35.00028,32.5 C34.6995,32 34.80106,31.3984 35.30106,31.1016 L48.19906,22.8008 L35.301,14.5 Z M22.1994,31.2 C22.6994,31.50078 22.80096,32.09844 22.50018,32.5984 C22.30096,32.89918 22.00018,33.0984 21.6994,33.0984 C21.50018,33.0984 21.30096,32.99684 21.1994,32.89918 L7.0004,23.69998 C6.69962,23.50076 6.5004,23.19998 6.5004,22.8992 C6.5004,22.59842 6.69962,22.19998 7.0004,22.09842 L21.1994,12.89922 C21.6994,12.59844 22.301,12.7 22.5978,13.2 C22.89858,13.7 22.79702,14.3016 22.29702,14.5984 L9.39902,22.8992 L22.1994,31.2 Z M24.1994,33.3016 C23.6994,33.10238 23.39862,32.50082 23.59784,32.0008 L30.69944,13.0008 C30.89866,12.5008 31.50022,12.20002 32.00024,12.39924 C32.50024,12.59846 32.80102,13.20002 32.6018,13.70004 L25.5002,32.59804 C25.30098,32.99648 25.0002,33.1996 24.60176,33.1996 C24.5002,33.30116 24.30098,33.30116 24.19942,33.30116 L24.1994,33.3016 Z" id="Shape"></path>
    </g>
  </SvgIcon>
)

KitchenIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

KitchenIcon.defaultProps = {
  color: "rgba(0,0,0,0.7)",
  viewBox: "0 0 58 45",
}

export default KitchenIcon
