import React from 'react'
import PropTypes from 'prop-types'
import LengthOfStayRadioButtons from 'views/vsf/views/LengthOfStayRadioButtons'

const required = value => value == null ? 'Required' : undefined

const LengthOfStayContainer = ({ length_of_stay_options }) => {
  return (
    <div className="input-bubble-wrap">
      <LengthOfStayRadioButtons
        length_of_stay_options={length_of_stay_options}
        required={required}
      />
    </div>
  )
}

LengthOfStayContainer.propTypes = {
  length_of_stay_options: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  ).isRequired,
}

export default LengthOfStayContainer
