import React from 'react'
import PropTypes from 'prop-types'
// Icons
import LocationIcon from 'material-ui/svg-icons/communication/location-on'

import ConfirmationField from './ConfirmationField' 

const ConfirmationGuestBillingAddressFields = ({ form_data, errorColor }) => {
  const { lead } = form_data
  const hasErrors = lead.syncErrors && lead.syncErrors.billing
  
  return (
    <div className="card-group">
      <div className="card-header-container">
        <LocationIcon color={hasErrors ? errorColor : "#7a7a7a"}/>
        <h3 className={`card-header ${hasErrors ? 'error' : ''}`}>Guest Billing Address</h3>
      </div>

      <div className="confirmation-fields">
        <ConfirmationField
          label="Billing Street Address"
          value={lead.values.billing && lead.values.billing.billing_street}
          error={lead.syncErrors && lead.syncErrors.billing && lead.syncErrors.billing.billing_street}
        /> 
        <ConfirmationField
          label="Billing City"
          value={lead.values.billing && lead.values.billing.billing_city}
          error={lead.syncErrors && lead.syncErrors.billing && lead.syncErrors.billing.billing_city}
        /> 
        <ConfirmationField
          label="Billing State"
          value={lead.values.billing && lead.values.billing.billing_state}
          error={lead.syncErrors && lead.syncErrors.billing && lead.syncErrors.billing.billing_state}
        /> 
        <ConfirmationField
          label="Billing ZIP Code"
          value={lead.values.billing && lead.values.billing.billing_zip_code}
          error={lead.syncErrors && lead.syncErrors.billing && lead.syncErrors.billing.billing_zip_code}
        /> 
      </div>
    </div>
  )
}

ConfirmationGuestBillingAddressFields.propTypes = {
  form_data: PropTypes.object.isRequired,
  errorColor: PropTypes.string.isRequired,
}

export default ConfirmationGuestBillingAddressFields
