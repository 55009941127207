import { getAccommodationData, getLeadData } from './utils'

export const selectLeadFormData = (state) => {
  return 'wizard' in state.form ? Object.keys(state.form.wizard).reduce((aggr, curr) => {
    switch (curr) {
      case 'accommodation':
        return {
          ...aggr,
          ...getAccommodationData(state.form.wizard[curr] ? state.form.wizard[curr].values : {})
        }
    
      case 'lead':
        return {
          ...aggr,
          ...getLeadData(state.form.wizard[curr] ? state.form.wizard[curr].values : {})
        }

      case 'notes':
        return {
          ...aggr,
          note: {
            title: "Point of Sale Notes",
            content: state.form.wizard[curr].values ? state.form.wizard[curr].values.note_content : ''
          }
        }

      case 'qualification':
        return {
          ...aggr,
          classification: state.form.wizard[curr] ? state.form.wizard[curr].values.classification : ''
        }
        
      // eslint-disable-next-line no-fallthrough
      default:
        return {
          ...aggr,
          ...state.form.wizard[curr].values
        }
    }
  }, {}) : {}
}
