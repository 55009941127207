import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field, reduxForm, formValueSelector } from 'redux-form'
// App
import { login } from '../../store/auth/actions'
import logo from 'assets/images/logo.png'

const FORM_NAME = 'login'

export class Login extends React.Component {

  static propTypes = {
    children: PropTypes.element,
    dispatch: PropTypes.func,
    loading: PropTypes.func,
    history: PropTypes.object,
    login: PropTypes.func,
    error: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string
  }

  static contextTypes = {
    signInUser: PropTypes.func,
  }

  handleSubmit = (e) => {
    const { login, history, loading, username, password } = this.props
    
    e.preventDefault()

    loading(async (done) => {
      await login(history, FORM_NAME, { username, password }, this.context.signInUser)
      done()
    })

  }

  render() {
    const { children, error } = this.props

    return (
      <div className="login">
      {children}

      <form className="form" onSubmit={this.handleSubmit}>
        <img className="logo" src={logo} alt="Allen Marketing Group" />
        {error ? <div className="error message">{error}</div> : null}

        <Field
          type="text"
          name="username"
          placeholder="Location ID"
          component="input"
          parse={(value) => value.toUpperCase()}
          autoFocus
        />

        <br/>
        <br/>

        <Field
          type="text"
          name="password"
          placeholder="Pin"
          component="input"
          parse={(value) => value.toUpperCase()}
        />

        <br/>
        <br/>

        <button className="red button" id="submit_button" onClick={this.handleSubmit}>Login</button>
      </form>
    </div>
    )
  }
}

const enhance = compose(
  reduxForm({ form: FORM_NAME }),
  connect((state) => {
    const { username, password } = formValueSelector(FORM_NAME)(state, 'username', 'password')
    return {
      username,
      password,
      error: state.auth.error
    }
  }, { login }),
)

export default enhance(Login)
