// @flow
import type { GetScrubReceipts, ScrubReceiptType } from 'models/ScrubReceiptModel'
import { api } from 'controllers/Rest'
import type { Dispatch } from 'redux'

export const getReceipts = (state: GetScrubReceipts) => {
  return {
    scrubReceipts: state.scrubReceipts
  }
}

export const addScrubReceipt = (values: ScrubReceiptType, dispatch: Dispatch<*>) => {
  const payload = {
    body: JSON.stringify({
      scrub_receipt: {
        scrub_summary: values.scrubReceipt,
        user: values.user,
        original_file_name: values.originalFileName,
      }
    })
  }

  return dispatch(api.scrubReceipts.post({}, payload))
}
