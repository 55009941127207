/* eslint-disable */

const radioButtonStyles = {
  hidden: {
    display: "none"
  },
  radio: {
    display: "inline-block",
    width: "auto",
    marginRight: "1rem", //1rem",
    float: "none",
  },
  radioLabel: {
    borderRadius: "5px",
    borderColor: "#ddd",
    borderWidth: 1,
    borderStyle: "solid",
    fontSize: 14,
    float: "none",
    width: "100%",
    padding: "0.25rem 0.75rem",
    marginBottom: "0.5rem",
    backgroundColor: "white",
  },
}

export default radioButtonStyles
