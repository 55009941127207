import { createSelector } from 'reselect'

import { selectId } from '../../auth/selectors'
import { selectActiveTravelDestinationId } from '../travel/selectors'

export const selectAllQualificationOptions = (state) => {
  return state.vsf.qualification.options
}

export const selectQualificationsFormData = (state) => {
  return state.form.wizard.qualification
}

export const selectQualificationInitValues = createSelector(
  [selectId],
  (id) => {
    return {
      classification: id === 'DEV' ? 'CoHabitating' : '',
    }
  }
)

export const selectQualificationOptions = createSelector(
  [selectActiveTravelDestinationId, selectAllQualificationOptions],
  (activeId, options) => {
    if (activeId && options && options.length > 0) {
      const val = options.find(({ id }) => id === activeId)
      if (val) return val.qualifications
    }
  }
)
