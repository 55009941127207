import { reset as resetForm } from 'redux-form'

import C from 'config/environment'
import { checkStatus, parseJSON } from '../../controllers/concerns/railsRest'

import { updateDialogAction } from '../dialog/actions'
import {
  setDestinationOptionsAction,
  setLosOptionsAction,
  setTravelTimeOptionsAction,
  setSkipCreditCardAction
} from './travel/actions'
import { setQualificationOptionsActions } from './qualification/actions'
import { setGiftOptionsActions } from './gifts/actions'
import { setPermissionsActions } from './permissions/actions'
import { setAuthValueAction } from '../auth/actions'
import { selectHeaderData } from '../auth/selectors'

// ACTIONS
export const SET_STEP = 'vsf/SET_STEP'
export const RESET = 'vsf/RESET'
export const TOGGLE_NOTES_VIEW = 'vsf/TOGGLE_NOTES_VIEW'
export const SET_LOADING = 'vsf/SET_LOADING'

// ACTION CREATORS
export const setStepAction = (step) => ({
  type: SET_STEP,
  payload: {
    step
  }
})

export const resetAction = () => ({
  type: RESET,
  payload: null
})

export const toggleNotesViewAction = () => ({
  type: TOGGLE_NOTES_VIEW,
  payload: null
})

export const setLoadingStateAction = (loading) => ({
  type: SET_LOADING,
  payload: {
    loading
  }
})

// BOUND ACTION CREATORS
export const setStep = (step) => (dispatch) => {
  dispatch(setStepAction(step.toString()))
}

export const reset = (forms) => (dispatch) => {
  forms.forEach((form) => {
    dispatch(resetForm(form))
  })

  dispatch(resetAction())
}

export const toggleNotesView = () => (dispatch, getState) => {
  const { notesExpanded } = getState().vsf

  dispatch(toggleNotesViewAction())

  if (!notesExpanded) {
    // hack to focus the field since this needs to run after redux updates state
    setTimeout(() => {
      document.querySelector('.note-field').focus()
    }, 10)
  }
}

export const getUserData = (uid) => async (dispatch, getState) => {
  const headers = selectHeaderData({
    'Content-Type': 'application/json'
  })(getState())

  try {
    dispatch(setLoadingStateAction(true))
    let data = await fetch(C.API.BASE + `/users/${uid}`, {
      method: 'GET',
      headers,
    })
    data = await checkStatus(data)
    data = await parseJSON(data)
    dispatch(setLoadingStateAction(false))
    const {
      locations,
      length_of_stay,
      travel_time,
      gifts,
      skip_credit_card,
    } = data.user

    const permissionsData = {
      canAccessSales: data.user.can_access_sales,
      canAccessMail: data.user.can_access_mail,
      canAccessBilling: data.user.can_access_billing,
      canAccessLeads: data.user.can_access_leads,
      canAccessAdmin: data.user.can_access_admin,
      canAccessPrint: data.user.can_access_print,
      canAccessStamps: data.user.can_access_stamps,
      canAccessCaptureAndVoid: data.user.can_access_capture_and_void,
      canAccessRefunds: data.user.can_access_refunds,
      canAccessScrubber: data.user.can_access_scrubber,
      canAccessKitchen: data.user.can_access_kitchen,
      canAccessSupport: data.user.can_access_support,
      canAccessStats: data.user.can_access_stats
    }

    const assignAclValue = (property) => {
      return property && [uid]
    }

    let aclPermissions = {
      login: "*",
      sales: assignAclValue(permissionsData.canAccessSales),
      mail: assignAclValue(permissionsData.canAccessMail),
      print: assignAclValue(permissionsData.canAccessPrint),
      admin: assignAclValue(permissionsData.canAccessAdmin),
      billing: assignAclValue(permissionsData.canAccessBilling),
      "capture-&-void": assignAclValue(permissionsData.canAccessCaptureAndVoid),
      refund: assignAclValue(permissionsData.canAccessRefunds),
      stats: assignAclValue(permissionsData.canAccessStats),
      scrubber: assignAclValue(permissionsData.canAccessScrubber),
      leads: assignAclValue(permissionsData.canAccessLeads),
      stamps: assignAclValue(permissionsData.canAccessStamps),
      support: assignAclValue(permissionsData.canAccessSupport),
      kitchen: assignAclValue(permissionsData.canAccessKitchen),
    }

    dispatch(setAuthValueAction('acl', aclPermissions))

    let qualificationData = []

    const locationData = locations.map((loc) => {
      const { id, qualifications, ...rest } = loc

      qualificationData.push({
        id,
        qualifications,
      })

      return {
        id,
        ...rest
      }
    })

    const giftData = gifts.reduce((aggr, curr) => {
      return {
        ...aggr,
        [curr.location_id]: {
          ...aggr[curr.location_id],
          [curr.id]: curr
        }
      }
    }, {})

    dispatch(setDestinationOptionsAction(locationData))
    dispatch(setLosOptionsAction(length_of_stay))
    dispatch(setTravelTimeOptionsAction(travel_time))
    dispatch(setQualificationOptionsActions(qualificationData))
    dispatch(setGiftOptionsActions(giftData))
    dispatch(setPermissionsActions(permissionsData))
    dispatch(setSkipCreditCardAction(skip_credit_card))

  } catch (error) {
    dispatch(setLoadingStateAction(false))
    console.log(error) // eslint-disable-line
  }
}

export const createLead = (form_data) => async (dispatch, getState) => {
  dispatch(setLoadingStateAction(true))

  const state = getState()

  try {
    let data = await fetch(C.API.BASE + '/leads', {
      method: 'POST',
      headers: {
        'access-token': state.auth['access-token'],
        'expiry': state.auth.expiry,
        'uid': state.auth.uid,
        'client': state.auth.client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(form_data)
    })

    data = await checkStatus(data)
    data = await parseJSON(data)

    const body = data.message
    const errors = data.errors || []
    const validationErrors = data.validationErrors || []
    const title = data.charge && data.charge.success
      ? 'Your lead saved and charged successfully'
      : 'There were some problems'

    dispatch(setLoadingStateAction(false))

    dispatch(updateDialogAction({
      open: true,
      title,
      body,
      errors,
      validationErrors
    }))

  } catch (error) {
    const title = 'Sorry to tell you this, but there may be a problem'
    const body = 'You may have forgotten to fill out a required field or entered an invalid value. You can safely check over the form and resubmit again (no charge or record has been saved). If the error persists, please contact AMG for support.'
    const errors = [error]
    const stackTrace = [error.stack]
    const validationErrors = error.validationErrors || []

    dispatch(setLoadingStateAction(false))

    dispatch(updateDialogAction({
      open: true,
      title,
      body,
      errors,
      validationErrors,
      stackTrace
    }))
  }
}
