import { createSelector } from 'reselect'

const selectUserOptions = (state) => state._admin.user.options
const selectActiveLocationId = (state) => state._admin.user.activeLocation.id
const selectUsers = (state) => state.admin.data

export const selectGiftById = (id) => createSelector(
  [selectUserOptions],
  (options) => options.gifts.find((gift) => gift.id === id)
)

export const selectFormattedUserOptions = createSelector(
  [selectUserOptions], (options) => {
    return Object.entries(options).reduce((aggr, [key, values]) => {
      switch (key) {
        case 'los':
          return {
            ...aggr,
            [key]: values.map(([days, nights]) => ({
              label: `${days} Day & ${nights} Night`,
              value: `${days}, ${nights}`
            }))
          }

        case 'ttim':
          return {
            ...aggr,
            [key]: values.map((months) => ({
              label: `${months} Months`,
              value: months.toString()
            }))
          }

        case 'card':
          return {
            ...aggr,
            [key]: values.map((card) => ({
              label: card.toUpperCase(),
              value: card
            }))
          }

        case 'locations':
          return {
            ...aggr,
            [key]: values.map(({ id, name }) => ({
              label: name,
              value: id
            }))
          }

        default:
          return {
            ...aggr,
            [key]: values
          }
      }
    }, {})
  }
)

export const selectFilteredUserOptions = createSelector(
  [selectFormattedUserOptions, selectActiveLocationId, selectUsers],
  (formattedOptions, locationId, users) => {
    const presets = users.map(({ name }) => {
      const label = name.toUpperCase()
      return {
        value: name,
        label
      }
    }).sort((a, b) => {
      if (a.value < b.value) return -1
      if (a.value > b.value) return 1
      return 0
    })

    if (locationId) {
      return {
        ...formattedOptions,
        presets,
        gifts: formattedOptions.gifts.filter((gift) => {
          if (!gift.location_id) return true
          else {
            return gift.location_id === locationId
          }
        })
      }
    } else {
      return {
        ...formattedOptions,
        presets,
        gifts: null
      }
    }
  }
)
