import icons from 'views/ui/icons'

export default [
  {
    text: 'Sales',
    route: 'sales',
    routeClean: 'sales',
    url: '/dashboard/sales',
    acl: 'sales',
    image: icons.sales
  },
  {
    text: 'Billing',
    route: 'billing',
    url: '/dashboard/billing',
    acl: 'billing',
    image: icons.billing
  },
  {
    text: 'Leads',
    route: 'leads',
    url: '/dashboard/leads',
    acl: 'leads',
    image: icons.leads
  },
  {
    text: 'Admin',
    route: 'admin',
    url: '/dashboard/admin',
    acl: 'admin',
    image: icons.admin
  },
  {
    text: 'Mail',
    route: 'mail',
    url: '/dashboard/mail',
    acl: 'mail',
    image: icons.mail
  },
  {
    text: 'Print',
    route: 'print',
    url: '/dashboard/print',
    acl: 'print',
    image: icons.print
  },
  {
    text: 'Stamps',
    route: 'stamps',
    url: '/dashboard/stamps',
    acl: 'stamps',
    image: icons.stamps
  },
  {
    text: 'Capture & Void',
    route: 'capture-&-void',
    url: '/dashboard/capture-&-void',
    acl: 'capture-&-void',
    image: icons.billing
  },
  {
    text: 'Refund',
    route: 'refund',
    url: '/dashboard/refund',
    acl: 'refund',
    image: icons.billing
  },
  {
    text: 'Scrubber',
    route: 'scrubber',
    url: '/dashboard/scrubber',
    acl: 'scrubber',
    image: icons.scrubber
  },
  {
    text: 'Kitchen',
    route: 'kitchen',
    url: '/dashboard/kitchen',
    acl: 'kitchen',
    image: icons.kitchen
  },
  {
    text: 'Support',
    route: 'support',
    url: '/dashboard/support',
    acl: 'support',
    image: icons.support
  },
]
