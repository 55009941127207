import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { api } from 'controllers/Rest'
import { getReceipts } from 'controllers/ScrubReceiptController'
import Moment from 'moment'
import { Table } from 'reactable'

class Receipts extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    loading: PropTypes.func,
    scrubReceipts: PropTypes.object,
  }

  state = {
    pagination: 0,
  }

  componentWillMount = () => {
    this.reload()
  }

  reload = () => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(api.scrubReceipts.reset()),
      dispatch(api.scrubReceipts.sync()),
    ])
      .then(() => {
        done()
      })
      .catch((failure) => {
        done()
        alert('Failure in loading Scrub Receipts', failure)
      })
    )
  }

  _openInNewWindow = (e, link) => {
    e.preventDefault()
    let userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf(' electron/') > -1) {
      window.require('electron').shell.openExternal(link)
    } else {
      let width = window.innerWidth / 2
      let height = window.innerHeight
      window.open(link, 'Google', `left=${width},width=${width},height=${height}`)
    }
  }

  _downloadFileLink = (name, id) => {
    return <a onClick={(e) => this._openInNewWindow(e, `https://drive.google.com/uc?id=${id}&export=download`)}>{name}</a>
  }

  renderReceipts = () => {
    const { scrubReceipts } = this.props
    let paged = 0

    if (scrubReceipts.loading)
      return (<div className="big text">Loading... please wait</div>)

    if (scrubReceipts.data.length === 0)
      return (<div className="big text">Scrub a file to see the receipt here!</div>)

    if (scrubReceipts.data.length > 15)
      paged = 15

    let receipts = scrubReceipts.data.map(receipt => {
      return {
        'User': receipt.user,
        'Receipt': receipt.scrub_summary,
        'Original File Name': receipt.original_file_name,
        'Scrub Date': Moment(receipt.created_at).format("M/D/YY hh:mm:ss A"),
        'Cleaned File': receipt.good_google_file_id && this._downloadFileLink('View', receipt.good_google_file_id),
        'Bad File': receipt.bad_google_file_id && this._downloadFileLink('View', receipt.bad_google_file_id)
      }
    })

    return (
      <Table
        className="table"
        data={receipts}
        itemsPerPage={paged}
        width="100%"
      />
    )
  }

  render = () => (
    <div>
      {!this.props.scrubReceipts.isLoading && this.renderReceipts()}
    </div>
  )
}

export default connect(getReceipts)(Receipts)
