import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import logo from 'assets/images/logo.png'
import navigation from 'config/navigation'
import { logout } from '../../store/auth/actions'
import { createAlert, clearAlerts } from 'controllers/AlertController'
import { selectAuthorizedRoutes } from '../../store/auth/selectors'

class Sidebar extends Component {

  static propTypes = {
    history: PropTypes.object,
    dispatch: PropTypes.func,
    logout: PropTypes.func,
    createAlert: PropTypes.func,
    clearAlerts: PropTypes.func,
    id: PropTypes.string.isRequired,
    authorizedRoutes: PropTypes.arrayOf(PropTypes.string).isRequired
  }

  static contextTypes = {
    signOutUser: PropTypes.func
  }

  handleLogout = async () => {
    const { history, createAlert, clearAlerts, logout } = this.props

    try {
      await logout(history, this.context.signOutUser)
      clearAlerts()
    } catch (error) {
      createAlert({
        dismissable: true,
        type: 'error',
        message: error.message,
      })
    }
  }

  renderMenu() {
    const { authorizedRoutes } = this.props

    return navigation.map(({ acl, image, url, text }) => {
      if (!authorizedRoutes.includes(acl))
        return

      const Icon = image

      return (
        <NavLink to={url} key={acl} className="menu item">
          <Icon color="white" role="presentation" />
          <span>{text}</span>
        </NavLink>
      )
    })
  }

  render() {
    const { id } = this.props

    return (
      <nav className="sidebar">
        <img className="logo" src={logo} alt="Allen Marketing Group" />

        {this.renderMenu()}

        <button onClick={this.handleLogout}>Logout of {id}</button>
      </nav>
    )
  }
}

const enhance = compose(
  connect((state) => {
    return {
      id: state.auth.id,
      authorizedRoutes: selectAuthorizedRoutes(state)
    }
  }, {
    createAlert,
    clearAlerts,
    logout,
  }),
  withRouter,
)

export default enhance(Sidebar)
