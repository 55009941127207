// ACTIONS
export const SET_GIFT_OPTIONS = 'vsf/gifts/SET_GIFT_OPTIONS'
export const TOGGLE_DIALOG = 'vsf/gifts/TOGGLE_DIALOG'
export const TOGGLE_GIFT = 'vsf/gifts/TOGGLE_GIFT'
export const SELECT_GIFT = 'vsf/gifts/SELECT_GIFT'
export const RESET_GIFTS = 'vsf/gifts/RESET_GIFTS'

import { createAlert } from 'controllers/AlertController'

// ACTION CREATORS
export const setGiftOptionsActions = (options) => ({
  type: SET_GIFT_OPTIONS,
  payload: {
    options,
  },
})

export const toggleDialogAction = () => ({
  type: TOGGLE_DIALOG,
  payload: null,
})

const validateMultiplePremiumGiftsBeingSelected = (
  locationId,
  id,
  giftState
) => {
  const giftsInLocation = giftState.options[locationId]
  if (giftsInLocation[id].gift_type === 'premium') {
    const existingGifts = Object.values(giftsInLocation).filter((gift) => {
      return gift.gift_type === 'premium' && gift.id != id && gift.included
    })

    if (existingGifts.length > 0) {
      return createAlert({
        dismissable: true,
        type: 'warning',
        message: 'At maximum only 1 premium gift can be selected',
        dismissAfter: 5000,
      })
    }
  }

  return null
}

export const toggleGiftAction = (locationId, id, getState) => {
  const multiplePremiumGiftsSelected = validateMultiplePremiumGiftsBeingSelected(
    locationId,
    id,
    getState().vsf.gifts
  )
  if (multiplePremiumGiftsSelected) {
    return multiplePremiumGiftsSelected
  }
  return {
    type: TOGGLE_GIFT,
    payload: {
      locationId,
      id,
    },
  }
}

export const selectGiftAction = (locationId, id, value, getState) => {
  const multiplePremiumGiftsSelected = validateMultiplePremiumGiftsBeingSelected(
    locationId,
    id,
    getState().vsf.gifts
  )
  if (multiplePremiumGiftsSelected) {
    return multiplePremiumGiftsSelected
  }

  return {
    type: SELECT_GIFT,
    payload: {
      locationId,
      id,
      value,
    },
  }
}

export const resetGiftsAction = () => ({
  type: RESET_GIFTS,
  payload: null,
})

// BOUND ACTOPM CREATORS
export const toggleDialog = () => (dispatch) => {
  dispatch(toggleDialogAction())
}

export const toggleGift = (locationId, id) => (dispatch, getState) => {
  dispatch(toggleGiftAction(locationId, id, getState))
}

export const selectGift = (locationId, id, value) => (dispatch, getState) => {
  dispatch(selectGiftAction(locationId, id, value, getState))
}

export const resetGifts = () => (dispatch) => {
  dispatch(resetGiftsAction())
}
