/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux'

import travel from './travel/reducer'
import qualification from './qualification/reducer'
import gifts from './gifts/reducer'
import permissions from './permissions/reducer'
import { SET_STEP, RESET, TOGGLE_NOTES_VIEW, SET_LOADING } from './actions'

export const INITIAL_STATE = {
  // currentTab
  step: '1',
  loading: false,
  // once there's no more validation errors this should be true
  // todo: should be abstracted in a selector
  finished: false,
  notesExpanded: false,
}

export const rootReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.payload.step
      }

    case RESET:
      // don't reset the notesExpanded state
      const { notesExpanded, ...newState } = INITIAL_STATE // eslint-disable-line no-unused-vars

      return {
        ...state,
        ...newState
      }

    case TOGGLE_NOTES_VIEW:
      return {
        ...state,
        notesExpanded: !state.notesExpanded
      }

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      }

    default:
      return state
  }
}

export default combineReducers({
  root: rootReducer,
  travel,
  gifts,
  qualification,
  permissions
})
