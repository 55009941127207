import React from 'react'
import PropTypes from 'prop-types'
import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'
import icons from 'views/ui/icons'
import RaisedButton from 'material-ui/RaisedButton'

class FilterToolBar extends React.Component {
  static propTypes = {
    gatewayFilterValue: PropTypes.string,
    filterGatewayOnChange: PropTypes.func.isRequired,
    processingStatusFilterValue: PropTypes.string,
    filterProcessingStatusOnChange: PropTypes.func.isRequired,
    refundCount: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    clearFilters: PropTypes.func.isRequired,
    fetchRefunds: PropTypes.func.isRequired,
  }

  hasDefaultFilterStates = () => {
    const { processingStatusFilterValue, gatewayFilterValue } = this.props
    return (processingStatusFilterValue === 'RF - Sent for Processing') &&
      (gatewayFilterValue === null)
  }

  render() {
    return (
      <div className="toolbar on-top">
        <div className="filter">
          <DropDownMenu
            value={this.props.gatewayFilterValue}
            onChange={this.props.filterGatewayOnChange}
            underlineStyle={{ border: 'none' }}
          >
            <MenuItem value={null} primaryText="All Gateways" />
            <MenuItem value={'cc1'} primaryText="CC1" />
            <MenuItem value={'cc2'} primaryText="CC2" />
            <MenuItem value={'cc3'} primaryText="CC3" />
          </DropDownMenu>

          <DropDownMenu
            value={this.props.processingStatusFilterValue}
            onChange={this.props.filterProcessingStatusOnChange}
            underlineStyle={{ border: 'none' }}
          >
            <MenuItem value={'Processed'} primaryText="Processed" />
            <MenuItem value={'VOIDED'} primaryText="Voided" />
            <MenuItem value={'RF - Sent for Processing'} primaryText="Sent for Processing" />
            <MenuItem value={null} primaryText="All Refunds" />
          </DropDownMenu>
        </div>

        <div className="count">Count: {this.props.refundCount}</div>
        <div className="wrap-right">
          {!this.hasDefaultFilterStates() &&
              <RaisedButton
                label="Clear Filters"
                primary={false}
                disabled={this.props.loading}
                onClick={this.props.clearFilters}
                style={{ marginRight: '15px' }}
              />
          }
          <RaisedButton
            label="Reload Payments"
            primary={true}
            disabled={this.props.loading}
            onClick={this.props.fetchRefunds}
            icon={<icons.reload color="white" viewBox="0 0 140 140" />}
          />
        </div>
      </div>
    )
  }
}

export default FilterToolBar
