import React from 'react'
import PropTypes from 'prop-types'
// Icons
import ClassIcon from 'material-ui-icons/Group'

import ConfirmationField from './ConfirmationField'

const ConfirmationClassificationFields = ({ form_data, errorColor }) => {
  const { qualification } = form_data
  const hasErrors = !qualification.values.classification
  
  return (
    <div className="card-group">
      <div className="card-header-container">
        <ClassIcon color={hasErrors ? errorColor : "#7a7a7a"}/>
        <h3 className={`card-header ${hasErrors ? 'error' : ''}`}>Classification</h3>
      </div>

      <div className="confirmation-fields">
        <ConfirmationField
          label="Classification"
          value={qualification.values.classification && qualification.values.classification}
          error={qualification.syncErrors && qualification.syncErrors.classification}
        /> 
      </div>
    </div>
  )
}

ConfirmationClassificationFields.propTypes = {
  form_data: PropTypes.object.isRequired,
  errorColor: PropTypes.string.isRequired,
}

export default ConfirmationClassificationFields