import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { api } from 'controllers/Rest'
import { Table } from 'reactable'
import Moment from 'moment'

import Auth from 'models/Auth'
import { parseJSON } from 'controllers/concerns/railsRest'
import C from 'config/environment'
import { createAlert } from 'controllers/AlertController'

class ScrubFiles extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    loading: PropTypes.func,
    scrubFiles: PropTypes.object,
  }

  state = {
    pagination: 0,
  }

  componentWillMount = () => {
    this.reload()
  }

  reload = () => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(api.scrubFiles.reset()),
      dispatch(api.scrubFiles.sync()),
    ])
      .then(() => {
        done()
      })
      .catch((failure) => {
        done()
        alert('Failure in loading Scrub Files', failure)
      })
    )
  }

  _toggleSpinner(message) {
    this.setState({
      loading: true,
      loadingMessage: message
    })
  }

  _disableSpinner() {
    this.setState({
      loading: false,
      loadingMessage: ""
    })
  }

  deleteReceipt = (id) => {
    const { dispatch } = this.props
    let data = { id: id }
    this._toggleSpinner("Marking File to be Scrubbed")

    return fetch(C.API.BASE + `/scrubber/${id}`, {
      method: 'DELETE',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(parseJSON)
    .then((scrub) => {
      this.reload()
      this._disableSpinner()
      dispatch(createAlert({
        dismissable: true,
        type: 'success',
        message: scrub.message,
        dismissAfter: 3000,
      }))
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to delete the scrubbed receipt'
      let errors = [error]
      let stackTrace = [error.stack]
      this.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      this._disableSpinner()
      return error
    })
  }

  scrub = (id) => {
    const { dispatch } = this.props
    let data = { id: id }
    this._toggleSpinner("Marking File to be Scrubbed")

    return fetch(C.API.BASE + `/scrubber/${id}/scrub`, {
      method: 'PATCH',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(parseJSON)
    .then((scrub) => {
      this.reload()
      this._disableSpinner()
      dispatch(createAlert({
        dismissable: true,
        type: 'success',
        message: scrub.message,
        dismissAfter: 3000,
      }))
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to schedule the file for scrubbing'
      let errors = [error]
      let stackTrace = [error.stack]
      this.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      this._disableSpinner()
      return error
    })
  }


  renderReceipts = () => {
    const { scrubFiles } = this.props
    let paged = 0

    if (scrubFiles.loading)
      return (<div className="big text">Loading... please wait</div>)

    if (scrubFiles.data.length === 0)
      return (<div className="big text">No files present to scrub</div>)

    if (scrubFiles.data.length > 15)
      paged = 15

    let receipts = scrubFiles.data.map(scrubFile => {
      let scrubbedStatus
      if (scrubFile.scrubbed) {
        scrubbedStatus = scrubFile.in_progress ? 'In Progress' : 'Yes'
      } else {
        scrubbedStatus = 'No'
      }
      let file = {
        'Title': scrubFile.title,
        'Scrubbed': scrubbedStatus,
        'Created At': Moment(scrubFile.created_at).format("M/D/YY hh:mm:ss A")
      }
      if (scrubFile.scrubbed) {
        if (!scrubFile.in_progress) {
          let action = <button className="red button" disabled={this.state.loading? 'disabled' : ''} onClick={() => this.deleteReceipt(scrubFile.id)}>Delete Receipt</button>
          file['Actions'] = (
            <div className="nowrap">
              {action}
            </div>
          )
        }
      } else {
        let action = <button className="green button" disabled={this.state.loading? 'disabled' : ''} onClick={() => this.scrub(scrubFile.id)}>Scrub</button>
        file['Actions'] = (
          <div className="nowrap">
            {action}
          </div>
        )
      }

      return file
    })

    return (
      <Table
        className="table"
        data={receipts}
        itemsPerPage={paged}
        width="100%"
      />
    )
  }

  render = () => (
    <div>
      {!this.props.scrubFiles.isLoading && this.renderReceipts()}
    </div>
  )
}

const getScrubFiles = (state: GetScrubFiles) => {
  return {
    scrubFiles: state.scrubFiles
  }
}

export default connect(getScrubFiles)(ScrubFiles)
