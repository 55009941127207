import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Checkbox } from 'redux-form-material-ui'

import OptionLabel from '../OptionLabel/index'
import GiftHelpers from '../../containers/GiftHelpers'
import GiftOptions from '../../containers/GiftOptions'

const Gifts = ({
  options,
  name,
  label,
  icon,
  activeLocation,
  form,
  formType,
}) => {
  const renderGifts = !!activeLocation.id

  return (
    <div className="option-group gifts full-width">
      <OptionLabel label={label} icon={icon} name={name} formType={formType}>
       {renderGifts ? <GiftHelpers form={form} /> : null}
      </OptionLabel>
      <div className="gifts-container">
        {renderGifts && options.map(({id, label}) => 
          <div key={id} className="option-field-container checkbox">
            <Field
              labelStyle={{
                fontWeight: 'bold'
              }}
              label={label}
              name={`${name}.locationId:${activeLocation.id}.giftId:${id}`}
              component={Checkbox}>
            </Field>
            <GiftOptions form={form} name={name} id={id} label={label}/>
          </div>
        )}
      </div>
    </div>
  )
}

Gifts.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  activeLocation: PropTypes.object.isRequired,
  formType: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
}

export default Gifts
