/* global google*/
import React from 'react'
import PropTypes from 'prop-types'
import { AutoComplete } from 'material-ui'
import { statesObject } from 'constants/states'
import { textErrorStyle } from 'views/ui/styles/styles'

class PlaceSearch extends React.Component {
  constructor(props) {
    super(props)
    this.autocompleteService = new google.maps.places.AutocompleteService()
    this.geocoder = new google.maps.Geocoder()
    this.state = {
      dataSource: [],
      data: [],
      searchText: ''
    }
  }

  updateDatasource(data) {
    if (!data || !data.length)
      return false

    if(this.state.data)
      this.previousData = { ...this.state.data }

    this.setState({
      dataSource: data.map(place => place.description),
      data
    })
  }

  getBounds() {
    if (!this.props.bounds || (!this.props.bounds.ne && !this.props.bounds.south))
      return undefined

    if (this.props.bounds.ne && this.props.bounds.sw)
      return new google.maps.LatLngBounds(this.props.bounds.sw, this.props.bounds.ne)

    return {
      ...this.props.bounds
    }
  }

  onUpdateInput(searchText) {
    if ( !searchText || !searchText.length || !this.autocompleteService)
      return false

    let request = {
      input: searchText,
      location: new google.maps.LatLng(this.props.location.lat, this.props.location.lng),
      componentRestrictions: this.props.componentRestrictions,
      radius: this.props.radius,
      types: this.props.types,
      bounds: this.getBounds()
    }

    this.autocompleteService.getPlacePredictions(request, data => this.updateDatasource(data))
  }

  onNewRequest = (searchText, index) => {
    const { updateBillingData } = this.props
    // The index in dataSource of the list item selected, or -1 if enter is pressed in the TextField
    if (index === -1)
      return false

    const data = this.previousData || this.state.data

    this.geocoder.geocode({ placeId: data[index].place_id }, (results) => {
      const place = results[0]
      const location = place.geometry.location

      place.address_components.forEach(type => {
        data[index][type.types[0]] = type.long_name
      })

      data[index].place = place
      data[index].location = {
        lat: location.lat(),
        lng: location.lng()
      }

      updateBillingData('billing.billing_street', `${data[index].street_number || ''} ${data[index].route || ''}`)
      updateBillingData('billing.billing_city', data[index].locality || '')
      updateBillingData('billing.billing_state', statesObject[data[index].administrative_area_level_1] || '')
      updateBillingData('billing.billing_zip_code', data[index].postal_code || '')
    })
  }

  onInputChange = (searchText) => {
    this.onUpdateInput(searchText)
    
    this.setState({
      ...this.state,
      searchText
    })
  }

  render() {
    const { searchText } = this.state
    const { floatingLabelText, styles } = this.props

    const hadFloatingLabel = !!floatingLabelText
    return (
      <AutoComplete
        style={{
          maxWidth: 500
        }}
        fullWidth={true}
        filter={this.props.filter}
        hintText="123 Street, City, ST 12345"
        searchText={searchText}
        openOnFocus={true}
        floatingLabelStyle={styles && hadFloatingLabel && styles.floatingLabelStyle}
        floatingLabelFixed={hadFloatingLabel}
        floatingLabelText={floatingLabelText}
        onUpdateInput={this.onInputChange}
        dataSource={this.state.dataSource}
        onNewRequest={this.onNewRequest}
        errorStyle={textErrorStyle}
      />
    )
  }
}

PlaceSearch.propTypes = {
  bounds: PropTypes.object,
  styles: PropTypes.object,
  componentRestrictions: PropTypes.object,
  filter: PropTypes.func,
  location: PropTypes.object,
  radius: PropTypes.number,
  types: PropTypes.arrayOf(PropTypes.string),
  updateBillingData: PropTypes.func.isRequired,
  floatingLabelText: PropTypes.string
}

PlaceSearch.defaultProps = {
  componentRestrictions: {},
  filter: AutoComplete.noFilter,
  location: {lat: 0, lng: 0},
  radius: 0,
  floatingLabelText: null
}

export default PlaceSearch
