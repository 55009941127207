import { generateAuthActions } from 'redux-token-auth'

import config from 'config/environment'
import { setAuthValueAction, removeAuthValueAction } from './actions'

export const storage = (store) => ({
  flushGetRequests: true,
  getItem: (key) => new Promise((resolve, reject) => {
    try {
      const value = localStorage.getItem(key)
      store.dispatch(setAuthValueAction(key, value))
      resolve(value)
    } catch (error) {
      reject(error)
    }
  }),
  setItem: (key, value) => new Promise((resolve, reject) => {
    try {
      store.dispatch(setAuthValueAction(key, value))
      localStorage.setItem(key, value)
      resolve(value)
    } catch (error) {
      reject(error)
    }
  }),
  removeItem: (key) => new Promise((resolve, reject) => {
    try {
      const value = localStorage.getItem(key)
      store.dispatch(removeAuthValueAction(key))
      localStorage.removeItem(key)
      resolve(value)
    } catch (error) {
      reject(error)
    } 
  }),
  clear: () => new Promise((resolve, reject) => {
    try {
      localStorage.clear()
      resolve(true)
    } catch (error) {
      reject(error)
    }
  }),
  getAllKeys: () => new Promise((resolve, reject) => {
    try {
      const allKeys = Object.keys(localStorage)
      resolve(allKeys)
    } catch (error) {
      reject(error)
    }
  }),
  multiGet: (keys) => new Promise((resolve, reject) => {
    try {
      const values = keys.map(key => [ key, localStorage.getItem(key) ])
      resolve(values)
    } catch (error) {
      reject(error)
    }
  }),
  multiSet: (keyValuePairs) => new Promise((resolve, reject) => {
    try {
      keyValuePairs.forEach(keyValuePair => localStorage.setItem(keyValuePair[0], keyValuePair[1]))
      const newKeyValuePairs = keyValuePairs.map(keyValuePair => [ keyValuePair[0], localStorage.getItem(keyValuePair[0]) ])
      resolve(newKeyValuePairs)
    } catch (error) {
      reject(error)
    }
  }),
})

export const userAttributes = {}

const generateAuthActionsWithStore = (store) => generateAuthActions({
  authUrl: config.AUTH.BASE,
  userAttributes,
  userRegistrationAttributes: {},
  storage: storage(store)
})

export default generateAuthActionsWithStore
