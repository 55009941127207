import React from 'react'
import PropTypes from 'prop-types'
import Dialog from 'material-ui/Dialog'
import Button from '../ui/Button'

class MassActionResults extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    success: PropTypes.array,
    errors: PropTypes.array,
    title: PropTypes.string,
  }

  render() {
    return (
      <Dialog
        title={`Mass ${this.props.title} results`}
        actions={[
          <Button key={'Close'} label="Close" className="basic" onClick={this.props.closeDialog} />,
        ]}
        modal={false}
        open={this.props.show}
        autoScrollBodyContent={true}
      >
        {this.props.success && (this.props.success.length > 0) &&
          <div className="success">
            <h3>Successfully {this.props.title === 'approve' ? 'approved' : 'voided'} the following payments</h3>
            <ul style={{padding: '2rem'}}>
              {this.props.success.map((payment) => {
                return <li key={payment.id} style={{padding: '5px 0'}}>{`Payment ID ${payment.id}`}</li>
                })
              }
            </ul>
          </div>
        }
        {this.props.errors && (this.props.errors.length > 0) &&
          <div className="errors">
            <h3>The following errors occurred:</h3>
            <ul style={{padding: '2rem'}}>
              {this.props.errors.map((e, idx) => {
                return <li key={idx} style={{padding: '5px 0'}}>{e.toString()}</li>
                })
              }
            </ul>
          </div>
        }
      </Dialog>
    )
  }
}

export default MassActionResults
