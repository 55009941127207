export const CSV_FIELD_MAPPINGS = {
  subject:          "SUBJECT",
  order_id:         "ORDERID",
  full_name:        "FULL NAME",
  country:          "COUNTRY",
  address_1:        "ADDRESS 1",
  city:             "CITY",
  state:            "STATE",
  zip:              "ZIP",
  creation_date:    "ORDER DATE",
  status:           "STATUS",
  print_message:    "PRINT MESSAGE",
  mail_piece:       "MAIL PIECE",
  mail_class:       "MAIL CLASS",
  tracking_service: "TRACKING SERVICE",
  weight:           "Weight"
}

