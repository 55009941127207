import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import Divider from 'material-ui/Divider'
import muiThemeable from 'material-ui/styles/muiThemeable'
// Icons
import CheckIcon from 'material-ui/svg-icons/action/check-circle'

import FormSectionHeader from 'views/ui/FormSectionHeader'
import {
  ConfirmationSaleFields,
  ConfirmationTravelFields,
  ConfirmationGiftsFields,
  ConfirmationQualificationFields,
  ConfirmationClassificationFields,
  ConfirmationGuestPersonalFields,
  ConfirmationGuestContactFields,
  ConfirmationGuestBillingAddressFields,
  ConfirmationCreditCardFields,
} from './views/Confirmation/index'

const CardConfirmation = ({ form_data, muiTheme, skipCreditCard }) => {
  const { error1Color } = muiTheme.palette

  return (
    <div>
      <FormSectionHeader className="margin-zero top">
        <CheckIcon className="required-input-icon input-icon svg-icon" />
        Confirm Information
      </FormSectionHeader>

      <div className="card-confirmation-content">
        <ConfirmationSaleFields form_data={form_data} errorColor={error1Color} />
        <Divider />
        <ConfirmationTravelFields form_data={form_data} errorColor={error1Color}/>
        <Divider />
        <ConfirmationGiftsFields form_data={form_data} errorColor={error1Color}/>
        <Divider />
        <ConfirmationQualificationFields form_data={form_data} errorColor={error1Color}/>
        <Divider />
        <ConfirmationClassificationFields form_data={form_data} errorColor={error1Color}/>
        <Divider />
        <ConfirmationGuestPersonalFields form_data={form_data} errorColor={error1Color}/>
        <Divider />
        <ConfirmationGuestContactFields form_data={form_data} errorColor={error1Color}/>
        <Divider />
        <ConfirmationGuestBillingAddressFields form_data={form_data} errorColor={error1Color}/>
        {!skipCreditCard && <Fragment>
          <Divider />
          <ConfirmationCreditCardFields form_data={form_data} errorColor={error1Color}/>
        </Fragment>}
      </div>
    </div>
  )
}

CardConfirmation.propTypes = {
  form_data: PropTypes.object.isRequired,
  muiTheme: PropTypes.object.isRequired,
  skipCreditCard: PropTypes.bool.isRequired
}

const enhance = compose(
  muiThemeable(),
  connect((state) => ({
    form_data: state.form.wizard,
    skipCreditCard: state.vsf.travel.skip_credit_card,
  }))
)

export default enhance(CardConfirmation)

