import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import { selectGiftById } from '../../../store/admin/user/selectors'

import GiftOptions from '../components/GiftOptions/index'

export default connect((state, { form, name, id }) => {
  const activeLocation = state._admin.user.activeLocation
  const formType = form.split('.')[1]

  return {
    selected: !!formValueSelector(form)(
      state,
      `${name}.locationId:${activeLocation.id}.giftId:${id}`
    ),
    valueOptions: selectGiftById(id)(state).values,
    activeLocationId: activeLocation.id,
    error:
      activeLocation &&
      state.form.admin[formType].syncErrors &&
      state.form.admin[formType].syncErrors.gifts &&
      state.form.admin[formType].syncErrors.gifts[
        `locationId:${activeLocation.id}`
      ] &&
      state.form.admin[formType].syncErrors.gifts[
        `locationId:${activeLocation.id}`
      ][`giftId:${id}`],
  }
})(GiftOptions)
