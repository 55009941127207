import { Component } from 'react'
import PropTypes from 'prop-types'

class AuthProvider extends Component {
  static childContextTypes = {
    signInUser: PropTypes.func,
    signOutUser: PropTypes.func
  }

  static propTypes = {
    signInUser: PropTypes.func.isRequired,
    signOutUser: PropTypes.func.isRequired,
    children: PropTypes.element
  }

  getChildContext() {
    return {
      signInUser: this.props.signInUser,
      signOutUser: this.props.signOutUser
    }
  }

  render() {
    return this.props.children
  }
}

export default AuthProvider