import React from 'react'
import PropTypes from 'prop-types'

import Dialog from 'material-ui/Dialog'
import Button from 'views/ui/Button'
import TextField from 'material-ui/TextField'

class ChangePasswordModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    resetDialog: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    handleUpdatePassword: PropTypes.func.isRequired,
    errorText: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
  }

  isInvalidPassword = () => {
    let { open, errorText, password } = this.props
    return  (open && (errorText || password.length === 0))
  }
  render() {
    return (
      <Dialog
        title="Please enter a new password"
        actions={[
          <Button key={'Cancel'} label="Cancel" className="basic" onClick={this.props.resetDialog} />,
          <Button
            key={"Reset Password"}
            label="Reset Password"
            className={this.isInvalidPassword() ? 'disabled' : 'red'}
            disabled={this.isInvalidPassword()}
            onClick={this.props.updatePassword} />
        ]}
        modal={false}
        open={this.props.open}
        autoScrollBodyContent={true}
      >
        <TextField
          name='password'
          fullWidth
          errorText={this.props.errorText}
          value={this.props.password}
          onChange={this.props.handleUpdatePassword} />
      </Dialog>
    )
  }
}

export default ChangePasswordModal
