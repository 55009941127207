import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import uuid from 'uuid/v4'
import Alert from 'views/ui/Alert'

const Alerts = ({ alerts }) => {
  return alerts.length > 0 && (
    <div className="alerts">
      {alerts.map(n => <Alert data={n} key={uuid()} />)}
    </div>
  )
}

Alerts.propTypes = {
  alerts: PropTypes.array,
}

Alerts.defaultProps = {
  alerts: []
}

const mapStateToProps = (state) => {
  return {
    alerts: state.alerts
  }
}

export default connect(mapStateToProps)(Alerts)
