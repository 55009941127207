import React from 'react'
import PropTypes from 'prop-types'
// Icons
import PersonIcon from 'material-ui-icons/Person'

import ConfirmationField from './ConfirmationField' 

const ConfirmationGuestContactFields = ({ form_data, errorColor }) => {
  const { lead } = form_data
  const hasErrors = lead.syncErrors && lead.syncErrors.phone
   
  return (
    <div className="card-group">
      <div className="card-header-container">
        <PersonIcon color={hasErrors ? errorColor : "#7a7a7a"}/>
        <h3 className={`card-header ${hasErrors ? 'error' : ''}`}>Guest Contact Information</h3>
      </div>

      <div className="confirmation-fields">
        <ConfirmationField
          label="Phone"
          value={lead.values.phone && lead.values.phone.phone}
          error={lead.syncErrors && lead.syncErrors.phone && lead.syncErrors.phone.phone}
        /> 
        <ConfirmationField
          label="Alt. Phone"
          value={lead.values.phone && lead.values.phone.alt_phone}
        /> 
        <ConfirmationField
          label="Email"
          value={lead.values.email && lead.values.email.email}
          error={lead.syncErrors && lead.syncErrors.email && lead.syncErrors.email.email}
        /> 
      </div>
    </div>
  )
}

ConfirmationGuestContactFields.propTypes = {
  form_data: PropTypes.object.isRequired,
  errorColor: PropTypes.string.isRequired,
}

export default ConfirmationGuestContactFields