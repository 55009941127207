import React from 'react'
import PropTypes from 'prop-types'
import Button from 'views/ui/Button'
import EditLocation from 'material-ui-icons/EditLocation'

const ToggleBillingButton = ({ onClick }) => (
  <Button
    className="clear"
    label="Enter Address Manually"
    onClick={onClick}
    icon={<EditLocation color="rgb(180, 180, 180)" />}
  />
)

ToggleBillingButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default ToggleBillingButton