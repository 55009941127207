class DialogModel {
  schema

  constructor() {
    this.schema = {
      open:   false,
      title:  '',
      body:   '',
      errors: [],
      stackTrace: [],
      validationErrors: [],
    }
  }
}

export default DialogModel
