import React from 'react'
// import PropTypes from 'prop-types'
import NameField from 'views/vsf/views/Lead/NameField'

const SpouseNameContainer = () => {
  return (
    <div className="group toppad">
      <NameField
        required={true}
        slug="name.spouse_first_name"
        hintText="First Name"
        label="Spouse's First Name"
      />
      <NameField
        required={true}
        slug="name.spouse_last_name"
        hintText="Last Name"
        label="Spouse's Last Name"
      />
    </div>
  )
} 

SpouseNameContainer.propTypes = {}

export default SpouseNameContainer
