import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
// Material
import { Tabs, Tab } from 'material-ui/Tabs'
import CardSale from 'views/vsf/CardSale'
import CardQualityAssurance from 'views/vsf/CardQualityAssurance'
import CardAccommodation from 'views/vsf/CardAccommodation'
import CardQualification from 'views/vsf/CardQualification'
import CardLead from 'views/vsf/CardLead'
import CardBilling from 'views/vsf/CardBilling'
import CardNote from 'views/vsf/CardNote'
import CardConfirmation from 'views/vsf/CardConfirmation'
import Dialog from '../containers/Dialog'
// Icons
import DollarSignIcon from 'material-ui/svg-icons/editor/attach-money'
import LocationIcon from 'material-ui/svg-icons/communication/location-on'
import BookmarkIcon from 'material-ui/svg-icons/action/bookmark'
import FaceIcon from 'material-ui/svg-icons/action/face'
import CardIcon from 'material-ui/svg-icons/action/credit-card'
import NoteIcon from 'material-ui/svg-icons/editor/mode-edit'
import CheckIcon from 'material-ui/svg-icons/action/check-circle'
// Form names
import { FORM_NAME as LEAD_FORM_NAME } from '../vsf/CardLead'
import { FORM_NAME as ACCOMMODATION_FORM_NAME } from '../vsf/CardAccommodation'
import { FORM_NAME as BILLING_FORM_NAME } from '../vsf/CardBilling'
import { FORM_NAME as NOTE_FORM_NAME } from '../vsf/CardNote'
import { FORM_NAME as QUALIFICATION_FORM_NAME } from '../vsf/CardQualification'
import { FORM_NAME as QA_FORM_NAME } from '../vsf/CardQualityAssurance'
import { FORM_NAME as SALE_FORM_NAME } from '../vsf/CardSale'
// Bound Action Creators
import {
  setStep,
  reset,
  toggleNotesView,
  createLead,
  getUserData
} from '../../store/vsf/actions'
import { updateDialog, resetDialog } from '../../store/dialog/actions'
import { selectLeadFormData } from '../../store/vsf/selectors'
import { selectIncludedGifts } from '../../store/vsf/gifts/selectors'
import { resetGifts } from '../../store/vsf/gifts/actions'
// UI
import Button from 'views/ui/Button'

const styles = {
  errorsContainer: {
    padding: '2rem'
  },
  errorsListItem: {
    marginBottom: '1.25rem'
  },
  tabs: {
    wrapper: {
      display: "inline-block",
      position: "relative"
    },
    containerStyle: {
      backgroundColor: "transparent",
      borderBottom: "1px solid #E8EAED",
      borderRadius: 0,
    },
    inkBarStyle: {
      height: "4px", //4px
      color: "#D14A47", //#D14A47rgb(54, 116, 102)", //#367466", //color: "#BB6672",
      marginTop: "-4px", //-4px
      backgroundColor: "#D14A47"
    },
    tab: {
      main: {
        backgroundColor: "rgba(255,255,255,0.7)",
        paddingRight: "0",
        borderRadius: 0,
        height: "100%",
        fontFamily: "Roboto"
      },
      buttonStyle:  {
        backgroundColor: "transparent",
        color: "rgba(0,0,0,0.2)",
        fontWeight: 400,
        flexDirection: "row",
        height: 47,
        borderRadius: 0,
        fontFamily: "Roboto"
      },
      contentStyle: {
        padding: "0 0 2rem", //2rem 1rem",
      },
      active: {
        buttonStyle: {
          color: "red"
        }
      },
      icon: {
        marginRight: 8
      },
    }
  },
  notes: {
    wrapper: {
      display: "inline-block",
      verticalAlign: "top",
      height: "100%",
    },
    tabItemContainerStyle: {
      backgroundColor: "transparent",
      minWidth: "168px",
      borderBottom: "1px solid rgb(63, 176, 63)",
      color: "rgb(63, 176, 63)",
      borderLeft: "1px solid rgb(232, 234, 237)"
    },
    buttonStyle:  {
      backgroundColor: "transparent",
      color: "rgb(63, 176, 63)",
      fontWeight: 400,
      flexDirection: "row",
      height: 48,
    },
    inkBarStyle: {
      backgroundColor: "#209E20",
      height: "0",
      marginTop: "0"
    },
    contentStyle: {
      padding: "0",
      borderLeft: "1px solid rgb(232, 234, 237)",
      borderBottom: "1px solid rgb(232, 234, 237)",
      height: "100%"
    },
  },
}

const tabIconColor = "rgba(0,0,0,0.4)"

class Wizard extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    route: PropTypes.object,
    step: PropTypes.string,
    uid: PropTypes.string.isRequired,
    notesExpanded: PropTypes.bool.isRequired,
    skipCreditCard: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setStep: PropTypes.func.isRequired,
    resetGifts: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    toggleNotesView: PropTypes.func.isRequired,
    updateDialog: PropTypes.func.isRequired,
    getUserData: PropTypes.func.isRequired,
    resetDialog: PropTypes.func.isRequired,
    createLead: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired
  }

  static formNames = new Array(
    LEAD_FORM_NAME,
    ACCOMMODATION_FORM_NAME,
    BILLING_FORM_NAME,
    NOTE_FORM_NAME,
    QUALIFICATION_FORM_NAME,
    QA_FORM_NAME,
    SALE_FORM_NAME,
  )

  componentDidMount () {
    const { uid, getUserData, route } = this.props
    // Anything DOM dependant like localStorage goes in componentDidMount
    window.document.title = route.title

    getUserData(uid)
  }

  handleSubmit = () => {
    const { createLead, state } = this.props
    const form_data = {
      lead: {
        ...selectLeadFormData(state),
        agent: state.auth.id,
        gifts: selectIncludedGifts(state)
      }
    }
    createLead(form_data)
  }

  resetForms = () => {
    const { reset, resetGifts } = this.props

    reset(Wizard.formNames)
    resetGifts()
    this.closeDialog()
  }

  closeDialog = () => {
    const { resetDialog } = this.props

    resetDialog()
  }

  handleNext = () => {
    const { step, skipCreditCard } = this.props
    const tab = parseInt(step, 10)

    if (skipCreditCard && (tab === 4)) {
      this.handleStep(tab + 2)
    } else {
      this.handleStep(tab + 1)
    }
  }

  handlePrev = () => {
    const { step, skipCreditCard } = this.props
    const tab = parseInt(step)

    if (tab > 1) {
      if (skipCreditCard && (tab === 6)) {
        this.handleStep(tab - 2)
      } else {
        this.handleStep(tab - 1)
      }
    }
  }

  handleExpandNotes = () => {
    const { toggleNotesView } = this.props

    toggleNotesView()
  }

  handleStep = (step) => {
    let tab = step
    if (typeof step !== 'number') tab = parseInt(step, 10)

    if (tab < 7) {
      const { setStep } = this.props
      const active = document.getElementsByClassName('vsf tab active')[0]
      const inactive = document.getElementsByClassName('vsf tab ' + tab)[0]

      if (active) active.classList.remove('active')
      if (inactive) inactive.classList.add('active')

      setStep(tab)
    }
  }

  renderStepActions() {
    const { step, isLoading } = this.props
    const tab = parseInt(step)

    return (
      <div className="bottom navigation">
        <div style={{ margin: '0 auto', maxWidth: 200 }}>
          <Button
            className="basic white"
            label="Back"
            onClick={this.handlePrev}
            disabled={tab === 0 || isLoading}
          />
          <Button
            id="next_button"
            className={tab >= 6 ? 'green' : 'basic white red-text'}
            label={tab >= 6 ? 'Finish' : 'Next'}
            onClick={tab >= 6 ? this.handleSubmit : this.handleNext}
            disabled={isLoading}
          />
        </div>
      </div>
    )
  }

  render() {
    const { step, notesExpanded, skipCreditCard } = this.props

    return (
      <div className="wizard">
        <Dialog
          actions={[
            <Button
              key="close"
              label="close"
              className="basic"
              onClick={this.closeDialog}
            />,
            <Button
              key="Create New Lead"
              label="Create New Lead"
              className="red"
              onClick={() => this.resetForms(true)}
            />
          ]}
        />

        <form onSubmit={this.handleSubmit} autoComplete="off" className="wizard form">
          <Tabs
            className="vsf tabs"
            style={{
              ...styles.tabs.wrapper,
              width: notesExpanded ? '70%' : '100%'
            }}
            tabItemContainerStyle={styles.tabs.containerStyle}
            inkBarStyle={styles.tabs.inkBarStyle}
            contentContainerClassName="content-container"
            value={step}
            onChange={this.handleStep}>
            <Tab
              className={`vsf tab 1 ${step === '1' ? "active" : ''}`}
              label="Sales" value="1"
              icon={<DollarSignIcon color={tabIconColor} />}
              buttonStyle={styles.tabs.tab.buttonStyle}
              style={styles.tabs.tab.main}>
              <CardSale />
              <CardQualityAssurance />
            </Tab>
            <Tab
              className={`vsf tab 2 ${step === '2' ? "active" : ''}`}
              label="Travel/Gifts" value="2"
              icon={<LocationIcon color={tabIconColor} />}
              buttonStyle={styles.tabs.tab.buttonStyle}
              style={styles.tabs.tab.main}>
              <CardAccommodation />
            </Tab>
            <Tab
              className={`vsf tab 3 ${step === '3' ? "active" : ''}`}
              label="Qualifications" value="3"
              icon={<BookmarkIcon color={tabIconColor} />}
              buttonStyle={styles.tabs.tab.buttonStyle}
              style={styles.tabs.tab.main}>
              <CardQualification />
            </Tab>
            <Tab
              className={`vsf tab 4 ${step === '4' ? "active" : ''}`}
              label="Guest" value="4"
              icon={<FaceIcon color={tabIconColor} />}
              buttonStyle={styles.tabs.tab.buttonStyle}
              style={styles.tabs.tab.main}>
              <CardLead />
            </Tab>
            {!skipCreditCard && <Tab
              className={`vsf tab 5 ${step === '5' ? "active" : ''}`}
              label="Billing" value="5"
              icon={<CardIcon color={tabIconColor} />}
              buttonStyle={styles.tabs.tab.buttonStyle}
              style={styles.tabs.tab.main}>
              <CardBilling />
            </Tab>}
            <Tab
              className={`vsf tab 6 ${step === '6' ? "active" : ''}`}
              label="Confirm" value="6"
              icon={<CheckIcon color={tabIconColor} />}
              buttonStyle={styles.tabs.tab.buttonStyle}
              style={styles.tabs.tab.main}>
              <CardConfirmation />
            </Tab>
            {!notesExpanded && <Tab
              label="Notes" value="7"
              className="vsf tab 7"
              icon={<NoteIcon color={"rgb(63, 176, 63)"} />}
              buttonStyle={styles.notes.buttonStyle}
              onActive={this.handleExpandNotes}
              style={styles.tabs.tab.main} />}
          </Tabs>
          <Tabs
            className="vsf tabs notes"
            style={{
              ...styles.notes.wrapper,
              width: notesExpanded ? '30%' : '0%',
              visibility: notesExpanded ? 'visible' : 'hidden'
            }}
            tabItemContainerStyle={styles.notes.tabItemContainerStyle}
            inkBarStyle={styles.notes.inkBarStyle}
            contentContainerStyle={styles.notes.contentStyle} >
            <Tab
              label="Notes" value="7"
              className="vsf tab 7"
              icon={<NoteIcon color={"rgb(63, 176, 63)"} />}
              onActive={this.handleExpandNotes}
              buttonStyle={styles.notes.buttonStyle}
              style={styles.tabs.tab.main}>
              <CardNote />
            </Tab>
          </Tabs>
        </form>

        {this.renderStepActions(step)}
      </div>
    )
  }
}

const enhance = compose(
  connect((state) => {
    return {
      step: state.vsf.root.step,
      notesExpanded: state.vsf.root.notesExpanded,
      isLoading: state.vsf.root.loading,
      skipCreditCard: state.vsf.travel.skip_credit_card,
      state,
      uid: state.auth.id
    }
  }, {
    setStep,
    reset,
    toggleNotesView,
    updateDialog,
    resetDialog,
    createLead,
    resetGifts,
    getUserData
  }),
)

export default enhance(Wizard)

