import { connect } from 'react-redux'
import {  formValueSelector } from 'redux-form'

import { selectFocused } from '../../../store/vsf/billing/selectors'

import CardForm from '../components/CardForm/index'

export default connect((state, { form }) => {
  return {
    focused: selectFocused(form)(state),
    ...formValueSelector(form)(
      state,
      'credit_card_name',
      'credit_card_number',
      'expiration_date',
      'cvv',
    ),
  }
})(CardForm)