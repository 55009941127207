/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import Checkbox from 'material-ui/Checkbox'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'

import { getFormattedLabel } from '../../../../store/vsf/gifts/utils'

import styles from 'views/ui/RadioButtonStyles'

const GiftsView = ({ locationId, gifts, toggleGift, selectGift }) => {
  return gifts.map((gift) => {
    const isSelectField = gift.input === 'select'
    if (isSelectField) {
      let itemType = 'menu',
        radioClass = ''

      if (gift.values.length < 6) {
        itemType = 'radio'
        radioClass = 'radioField'
      }

      return (
        <div
          className={`input gift ${isSelectField ? 'select' : ''}`}
          key={gift.id}
        >
          <div className="input checkbox">
            <Checkbox
              id="gift"
              label={gift.label}
              checked={gift.included}
              onCheck={() => toggleGift(locationId, gift.id)}
            />
          </div>

          <div
            className={`input ${radioClass}`}
            style={{ display: gift.included ? 'block' : 'none' }}
          >
            {itemType === 'menu' && (
              <SelectField
                name={`${gift.id}_select`}
                value={gift.included}
                floatingLabelText={gift.label}
                onChange={(e, key, payload) => {
                  selectGift(locationId, gift.id, payload)
                }}
              >
                {gift.values.map((value) => {
                  const label = getFormattedLabel(gift, value)

                  return (
                    <MenuItem value={value} primaryText={label} key={label} />
                  )
                })}
              </SelectField>
            )}

            {itemType === 'radio' && (
              <RadioButtonGroup
                name={`${gift.id}_radio`}
                onChange={(e, value) => selectGift(locationId, gift.id, value)}
              >
                {gift.values.map((value) => {
                  const label = getFormattedLabel(gift, value)

                  return (
                    <RadioButton
                      className="radioButton"
                      key={label}
                      value={value}
                      label={label}
                      iconStyle={styles.hidden}
                      labelStyle={styles.radioLabel}
                      style={styles.radio}
                    />
                  )
                })}
              </RadioButtonGroup>
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div className="input gift" key={gift.id}>
          <div className="input checkbox">
            <Checkbox
              label={gift.label}
              checked={gift.included}
              onCheck={() => toggleGift(locationId, gift.id)}
            />
          </div>
        </div>
      )
    }
  })
}

GiftsView.propTypes = {
  locationId: PropTypes.number.isRequired,
  toggleGift: PropTypes.func.isRequired,
  selectGift: PropTypes.func.isRequired,
}

export default GiftsView
