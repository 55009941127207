import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import dateformat from 'dateformat'
import uuid from 'uuid/v4'
import Dialog from 'material-ui/Dialog'
import AdminController from 'controllers/AdminController'
import AdminModel from 'models/AdminModel'
import { Table } from 'reactable'
import { api } from 'controllers/Rest'
import Tabable from 'views/ui/Tabable'
import { createAlert } from 'controllers/AlertController'
import Button from 'views/ui/Button'
import DialogModel from '../../models/DialogModel'
import CreateUser from '../admin/containers/CreateUsers'
import EditUser from '../admin/containers/EditUser'

import ChangePasswordModal from '../admin/components/UserCrud/ChangePasswordModal'
import DeleteUserModal from '../admin/components/UserCrud/DeleteUserModal'


import { getUserData, editUser } from '../../store/admin/user/actions'

const styles = {
  errorsContainer: {
    padding: '2rem'
  },
  errorsListItem: {
    marginBottom: '1.25rem'
  },
}

class Admin extends React.Component {

  static propTypes = {
    children: PropTypes.element,
    admin: PropTypes.object,
    dispatch: PropTypes.func,
    loading: PropTypes.func,
    editUser: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    tab: PropTypes.number,
    renderTabs: PropTypes.func,
    getUserData: PropTypes.func.isRequired,
    route: PropTypes.object,
  }

  state = {
    ...new AdminModel().schema,
    resetPassword: {
      open: false,
      password: '',
      errorText: null
    },
    deleteUser: {
      open: false
    },
    admin: {}
  }

  activeId   = null
  activeUser = null

  componentDidMount() {
    const { getUserData, route } = this.props

    window.document.title = route.title
    this.setState({
      admin: this.props.admin
    })

    this.reload()
    getUserData()
  }

  componentWillReceiveProps (nextProps) {
    const { admin } = this.props
    const { admin: nextAdmin } = nextProps

    if (admin.data !== nextAdmin.data) {
      this.setState({
        admin: nextAdmin
      })
    }
  }

  handleUpdatePassword = (e) => {
    if (e.target.value.length < 4) {
      this.setState({
        ...this.state,
        resetPassword: {
          ...this.state.resetPassword,
          password: e.target.value,
          errorText: 'Password must be at least 4 characters long.'
        }
      })
    } else {
      this.setState({
        ...this.state,
        resetPassword: {
          ...this.state.resetPassword,
          password: e.target.value,
          errorText: null
        }
      })
    }
  }

  reload = () => {
    const { loading, dispatch } = this.props

    loading(done => dispatch(api.admin.force())
      .then(() => done())
      .catch(e => dispatch(createAlert({
        dismissable: true,
        type: 'error',
        message: `Error loading History: ${e.message}`,
      })))
    )
  }

  resetTokens = (id) => {
    let admin = new AdminController(this)
    admin.resetTokens(id)
  }

  openDeleteUserDialog(id, name) {
    this.activeId = id
    this.activeUser = name

    this.setState({
      ...this.state,
      deleteUser: {
        ...this.state.deleteUser,
        open: true,
      }
    })
  }

  openUpdatePasswordDialog(id, name) {
    this.activeId = id
    this.activeUser = name

    this.setState({
      resetPassword: {
        ...this.state.resetPassword,
        open: true,
      }
    })
  }

  updatePassword = async () => {
    let admin = new AdminController(this)
    await admin.updatePassword({
      id: this.activeId,
      password: this.state.resetPassword.password,
      user: this.activeUser
    })
    this.resetDialog()
  }

  deleteUser = async () => {
    let admin = new AdminController(this)
    admin.deleteUser(this.activeUser)
    this.resetDialog()
  }

  resetDialog = () => {
    this.setState({
      ...this.state,
      dialog: {
        ...new DialogModel()
      },
      resetPassword: {
        open: false,
        password: '',
        errorText: null
      },
      deleteUser: {
        open: false
      }
    })
  }

  renderSessions = () => {
    const { data } = this.state.admin
    const { loading } = this.props.admin

    if (loading)
      return <div className="big text">Loading... please wait</div>

    if (data && data.length < 1)
      return <div className="big text">No login sessions found.</div>

    let items = data && data.map(s => {
      return {
        'Name': s.name,
        'Sign In Count': s.sign_in_count,
        'Last Sign In At': s.last_sign_in_at && dateformat(s.last_sign_in_at, "m/d/yy @ h:MM:ss TT"),
        'Current Sign In IP': s.current_sign_in_ip,
        'Password Updated At': s.password_updated_at && dateformat(s.password_updated_at, "m/d/yy @ h:MM:ss TT"),
        'Token Reset At': s.tokens_reset_at && dateformat(s.tokens_reset_at, "m/d/yy @ h:MM:ss TT"),
        'Actions': (
          <div className="nowrap admin-actions center">
            <Button className="small yellow" onClick={() => this.resetTokens(s.id)}>Reset Tokens</Button>
            <br />
            <Button className="small red" onClick={() => this.openUpdatePasswordDialog(s.id, s.name)}>Reset Password</Button>
            <br />
            <Button className="small green" onClick={() => this.editUser(s.name)}>Edit User</Button>
            <br />
            <Button className="small red" onClick={() => this.openDeleteUserDialog(s.id, s.name)}>Delete User</Button>
          </div>
        )
      }
    })

    return (
      <div>
        <Table
          className="table fixed"
          data={items}
          sortable={true}
          filterable={['ID', 'Name', 'Invoice']}
          itemsPerPage={20}
          pageButtonLimit={10}
          width="100%" />
      </div>
    )
  }

  renderHistory = () => {
    const { data } = this.state.admin
    let items = data && data.map(s => {
      if (s.recent_user_logins.length < 1)
        return null

      return (
        <div key={uuid()}>
          <h3>{s.name}</h3>
          <table className="striped hoverable" width="100%">
            <thead>
              <tr>
                <th>Date</th>
                <th>IP</th>
              </tr>
            </thead>
            <tbody>
              {s.recent_user_logins.map(u => (
                <tr key={uuid()}>
                  <td>{dateformat(u.created_at, "m/d/yy @ h:MM:ss TT")}</td>
                  <td>{u.ip_address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    })

    return (
      <div>
        {items}
      </div>
    )
  }

  renderErrors(errors = []) {
    if (errors && errors.length > 0) {
      let err = errors.map(e => <li key={uuid()} style={{padding: '5px 0'}}>{e.toString()}</li>)
      return (
        <div className="errors">
          <h3>Error Details:</h3>
          <ul>
            {err}
          </ul>
        </div>
      )
    }
    return null
  }

  renderValidationErrors(errors = []) {
    if (errors && errors.length > 0) {
      return (
        <div className="errors">
          <h3>We have encountered some Validation Errors. <br />Please correct these in the VSF Form and resubmit:</h3>
          <ul style={styles.errorsContainer}>
            {errors.map((e) => (
              <li style={styles.errorsListItem} key={uuid()}>{e}</li>
            ))}
          </ul>
        </div>
      )
    }

    return null
  }

  editUser(uid) {
    const { editUser, setTab } = this.props

    editUser(uid)
    setTab(3)
  }

  render() {
    const { tab, renderTabs, children } = this.props
    const { dialog } = this.state

    return (
      <div className="sessions">
        <DeleteUserModal
          resetDialog={this.resetDialog}
          deleteUser={this.deleteUser}
          open={this.state.deleteUser.open}
          name={this.activeUser}
        />
        <ChangePasswordModal
          resetDialog={this.resetDialog}
          updatePassword={this.updatePassword}
          open={this.state.resetPassword.open}
          password={this.state.resetPassword.password}
          errorText={this.state.resetPassword.errorText}
          handleUpdatePassword={this.handleUpdatePassword}
        />

        <Dialog
            title={dialog.schema.title}
            actions={[
              <Button key={'Cancel'} label="Cancel" className="basic" onClick={this.resetDialog} />,
            ]}
            modal={false}
            open={dialog.schema.open}
            autoScrollBodyContent={true}
          >
            <p style={{marginTop: '10px'}}>{dialog.schema.body}</p>
            {dialog.schema.errors.length > 0 ? this.renderErrors(dialog.schema.errors) : null}
            {dialog.schema.validationErrors && dialog.schema.validationErrors.length > 0 ? this.renderValidationErrors(dialog.schema.validationErrors) : null}
        </Dialog>
        {children}
        {renderTabs()}

        {tab === 0 && this.renderSessions() }
        {tab === 1 && this.renderHistory()}
        {tab === 2 && <CreateUser />}
        {tab === 3 && <EditUser />}
      </div>
    )
  }
}

const TabableAdmin = Tabable(Admin, [
  'Current Sessions',
  'Login History',
  'Create Users',
  'Edit User'
])

const enhance = compose(
  connect((state) => {
    return {
      admin: state.admin,
    }
  }, { getUserData, editUser })
)


export default enhance(TabableAdmin)
