import React from "react"
import PropTypes from "prop-types"

// Half Page Wrapper, with optional right
const HalfPage = ({ left, right, className, ...rest }) => {
  return (
    <div className={`half-page-wrapper ${className || ""}`} {...rest}>
      <div className="half-page clearfix">{left}</div>
      {right ? <div className="half-page last clearfix">{right}</div> : null}
    </div>
  )
}

HalfPage.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
  className: PropTypes.string
}

export default HalfPage
