import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Card, CardHeader } from 'material-ui/Card'
import Auth from 'models/Auth'
import { api } from 'controllers/Rest'
import { getLeads } from 'controllers/LeadController'
import Moment from 'moment'
import uuid from 'uuid/v4'
import { createAlert } from 'controllers/AlertController'

const styles = {
  card: {
    margin: '10px',
    width: '700px'
  },
  td: {
    color: '#aaa',
    fontWeight: '200',
    paddingRight: '2.5rem'
  },
  th: {
    background: 'transparent',
    width: '250px'
  },
  price: {
    color: '#9b9',
    fontWeight: '500',
    paddingRight: '2.5rem'
  },
  payments: {
    padding: '0 0 2rem 0',
  },
  h3: {
    marginTop: 0,
    paddingTop: 0
  },
}

class Leads extends React.Component {

  static propTypes = {
    children: PropTypes.element,
    dispatch: PropTypes.func,
    leads: PropTypes.object,
    loading: PropTypes.func,
  }

  state = {
    expandedRows : [],
  }

  constructor(props) {
    super(props)

    window.document.title = "Leads"
  }

  componentWillMount = () => this.reload()

  reload = () => {
    const { loading, dispatch } = this.props

    loading(done => dispatch(api.leads.force())
      .then(() => done())
      .catch(e => dispatch(createAlert({
        dismissable: true,
        type: 'error',
        message: `Error loading Leads: ${e.message}`,
      })))
    )
  }

  renderLead = (s) => {
    return (
      <div>
        <h3 style={styles.h3}>Lead Information</h3>
        <table className="striped hoverable" width="100%">
          <tbody>
            {
              Object.keys(s).map((k, i) => {
                if (typeof s[k] === 'string')
                  return (<tr key={i}><th>{k}</th><td>{s[k]}</td></tr>)
                else
                  return null
              })
            }
          </tbody>
        </table>
      </div>
    )
  }

  renderLeads(lead) {
    const clickCallback = () => this.handleRowClick(lead.id)
    const itemRows = [
      <tr style={{cursor: 'pointer'}} onClick={clickCallback} key={"row-data-" + lead.id}>
        <td style={styles.td}>{lead.first_name + ' ' + lead.last_name}</td>
        {Auth.isAdmin() ? <td style={styles.td}>{lead.package_code}</td> : null}
        <td style={styles.td}>{lead.agent + ': ' + lead.sales_agent}</td>
        <td style={styles.price}>{'$' + lead.package_price}</td>
        <td style={styles.td}>{lead.authorization_code}</td>
      </tr>
    ]

    if (this.state.expandedRows.includes(lead.id)) {
        itemRows.push(
          <tr key={lead.id}>
            <Card key={lead.id} style={styles.card}>
              <CardHeader
                style={styles.header}
                title={
                  <table className="striped hoverable" key={uuid()}>
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Verification Code</th>
                        <th>Verification Agent</th>
                        <th>Auth Code</th>
                        <th>Transaction ID</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                      <tbody>
                        {lead.payments.map((p, i) => (
                          <tr key={i} className={p.success ? 'green' : 'red'}>
                            <td>{p.amount}</td>
                            <td>{p.verification_code}</td>
                            <td>{p.verification_agent}</td>
                            <td>{p.authorization_code}</td>
                            <td>{p.transaction_id}</td>
                            <td>{Moment(p.created_at).format("M/D/YY hh:mm:ss A")}</td>
                          </tr>
                        ))}
                      </tbody>
                  </table>
                }
                actAsExpander={false}
                showExpandableButton={false}
              />
          </Card>
        </tr>
          )
        }
  return itemRows
}

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId)

    const newExpandedRows = isRowCurrentlyExpanded ?
      currentExpandedRows.filter(id => id !== rowId) :
      currentExpandedRows.concat(rowId)

    this.setState({expandedRows : newExpandedRows})
}

  renderTable = () => {
    const { leads } = this.props

    if (leads.loading) {
      return (<div className="big text">Loading... please wait</div>)
    }

    if (leads.data.length === 0) {
      return (<div className="big text">No sales found.</div>)
    }

    let allItemRows = []

    this.props.leads.data.forEach(item => {
        const perItemRows = this.renderLeads(item)
        allItemRows = allItemRows.concat(perItemRows)
    })

    return (
      <table className="striped" width="100%">
        <thead>
          <tr>
            <th style={styles.th}>Name</th>
            {Auth.isAdmin() ? <th style={styles.th}>Package Code</th> : null}
            <th style={styles.th}>Agent</th>
            <th style={styles.th}>Price</th>
            <th style={styles.th}>Authorization</th>
          </tr>
        </thead>
        <tbody>{allItemRows}</tbody>
       </table>
    )
  }

  render = ({ children } = this.props)  => (
    <div className="sales">
      {children}
      {this.renderTable()}
    </div>
  )
}

export default connect(getLeads)(Leads)
