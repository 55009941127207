import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { resetDialog } from '../../store/dialog/actions'

import Dialog from '../ui/Dialog'

class DialogContainer extends Component {
  static propTypes = {
    resetDialog: PropTypes.func.isRequired,
  }

  componentWillUnmount () {
    const { resetDialog } = this.props
    resetDialog()
  }
  
  render() {
    return <Dialog {...this.props} />
  }
}

export default connect((state) => {
  return {
    ...state.dialog
  }
}, { resetDialog })(DialogContainer)