import React from 'react'
import PropTypes from 'prop-types'
// Icons
import DollarSignIcon from 'material-ui/svg-icons/editor/attach-money'

import ConfirmationField from './ConfirmationField'

const ConfirmationSaleFields = ({ form_data, errorColor }) => {
  const { sale, quality_assurance } = form_data
  const hasErrors = sale.syncErrors || quality_assurance.syncErrors

  return (
    <div className="card-group">
      <div className="card-header-container">
        <DollarSignIcon color={hasErrors ? errorColor : "#7a7a7a" }/>
        <h3 className={`card-header ${hasErrors ? 'error' : ''}`}>Sales</h3>
      </div>

      <div className="confirmation-fields">
        <ConfirmationField
          label="Date Sold"
          value={new Date(sale.values.date_sold).toLocaleDateString('en-US')}
          error={sale.syncErrors && sale.syncErrors.date_sold}
        />
        <ConfirmationField
          label="Package Price"
          value={`$${sale.values.package_price}`}
          error={sale.syncErrors && sale.syncErrors.package_price}
        /> 
        <ConfirmationField
          label="Sales Agent"
          value={sale.values.sales_agent}
          error={sale.syncErrors && sale.syncErrors.sales_agent}
        /> 
        <ConfirmationField
          label="Verification Agent"
          value={quality_assurance.values.verification_agent}
          error={quality_assurance.syncErrors && quality_assurance.syncErrors.verification_agent}
        /> 
        <ConfirmationField
          label="Verification Code"
          value={quality_assurance.values.verification_code}
          error={quality_assurance.syncErrors && quality_assurance.syncErrors.verification_code}
        /> 
      </div>
    </div>
  )
}

ConfirmationSaleFields.propTypes = {
  form_data: PropTypes.object.isRequired,
  errorColor: PropTypes.string.isRequired,
}

export default ConfirmationSaleFields