import React from 'react'
import PropTypes from 'prop-types'
import GoogleAddressField from 'views/vsf/views/Lead/GoogleAddressField'
import ToggleMe from 'views/ui/ToggleMe'
import ToggleBillingButton from 'views/vsf/views/Lead/ToggleBillingButton'
import BillingFieldsContainer from 'views/vsf/views/BillingFieldsContainer'

class GoogleAddressFieldContainer extends React.Component {
  state = {
    billingFieldsHidden: true
  }

  handleToggleBillingAddress = (e) => {
    e.preventDefault()

    this.setState((prevState) => {
      return {
        ...prevState,
        billingFieldsHidden: !prevState.billingFieldsHidden
      }
    })
  }

  render() {
    const { updateBillingData } = this.props
    const { billingFieldsHidden } = this.state

    return (
      <div>
        <GoogleAddressField updateBillingData={updateBillingData} />

        <ToggleMe
          button={<ToggleBillingButton onClick={this.handleToggleBillingAddress} />}
          toggleBool={billingFieldsHidden}
          content={<BillingFieldsContainer /> }
        />
      </div>
    )
  }
}

GoogleAddressFieldContainer.propTypes = {
  updateBillingData: PropTypes.func.isRequired
}

export default GoogleAddressFieldContainer
