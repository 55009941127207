import { LOGOUT, SET_AUTH_VALUE, REMOVE_AUTH_VALUE, SET_ERROR } from "./actions"

export const INITIAL_STATE = {
  "access-token": "",
  "token-type": "",
  client: "",
  expiry: "",
  uid: "",
  id: "",
  pin: "",
  error: "",
  acl: {
    login: "*",
    support: [],
    sales: [],
    mail: [],
    print: [],
    admin: [],
    billing: [],
    "capture-&-void": [],
    refund: [],
    stats: [],
    scrubber: [],
    leads: [],
    stamps: [],
    kitchen: [],
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGOUT:
      return INITIAL_STATE

    case SET_AUTH_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }

    case REMOVE_AUTH_VALUE:
      return {
        ...state,
        [action.payload.key]: INITIAL_STATE[action.payload.key],
      }

    case SET_ERROR:
      return {
        ...state,
        error: action.payload.message,
      }

    default:
      return state
  }
}
