import C from '../../config/environment'
import { selectHeaderData } from '../auth/selectors'
import { parseJSON } from '../../controllers/concerns/railsRest'
import { change, reset } from 'redux-form'

import { normalizeCardExpiration, normalizeCardNumber, normalizeLetter } from '../../helpers/normalizers'
import { updateDialogAction, resetDialogAction } from '../dialog/actions' // eslint-disable-line 

// ACTIONS
export const SET_LOADING = 'billing/SET_LOADING'
export const SET_ERRORS = 'billing/SET_ERRORS'
export const SET_PACKAGE_DETAILS = 'billing/SET_PACKAGE_DETAILS'
export const RESET_PACKAGE_DETAILS = 'billing/RESET_PACKAGE_DETAILS'

// ACTION CREATORS
export const setLoadingAction = (loading) => ({
  type: SET_LOADING,
  payload: {
    loading
  }
})

export const setErrorsAction = (errors) => ({
  type: SET_ERRORS,
  payload: {
    errors
  }
})

export const setPackageDetailsAction = (packageDetails) => ({
  type: SET_PACKAGE_DETAILS,
  payload: {
    packageDetails
  }
})

export const resetPackageDetailsAction = () => ({
  type: RESET_PACKAGE_DETAILS,
  payload: null
})

// BOUND ACTION CREATORS
export const getLeadBillingData = (form, leadId) => async (dispatch, getState) => {
  try {
    const headers = selectHeaderData({
      'Content-Type': 'application/json'
    })(getState())

    dispatch(setLoadingAction(true))

    let data = await fetch(C.API.BASE + `/billing/${leadId}`, {
      method: 'GET',
      headers,
      mode: 'cors',
    })
    data = await parseJSON(data)

    if (data.lead) {
      dispatch(setPackageDetailsAction(data.lead))
      resetLeadForms(form, data.lead)(dispatch, getState)
    }

    if (data.errors) {
      dispatch(updateDialogAction({
        open: true,
        title: data.errors,
      }))
    }


    dispatch(setLoadingAction(false))
  } catch (error) {
    dispatch(setErrorsAction(true))
    dispatch(setLoadingAction(false))
    console.error(error) // eslint-disable-line 
  }
}

export const resetLeadForms = (form, packageDetails) => (dispatch) => {
  const expDate =  packageDetails.expiration_date &&
    (packageDetails.expiration_date.length === 3
    ? `0${packageDetails.expiration_date}`
    : packageDetails.expiration_date)

  dispatch(resetDialogAction())
  dispatch(setErrorsAction(false))
  dispatch(change(form, 'credit_card_name', normalizeLetter(packageDetails.credit_card_name)))
  dispatch(change(form, 'credit_card_number', normalizeCardNumber(packageDetails.credit_card_number)))
  dispatch(change(form, 'expiration_date', normalizeCardExpiration(expDate)))
  dispatch(change(form, 'billing_street', packageDetails.billing_street))
  dispatch(change(form, 'billing_city', packageDetails.billing_city))
  dispatch(change(form, 'billing_zip_code', packageDetails.billing_zip_code))
  dispatch(change(form, 'billing_state', packageDetails.billing_state))
  dispatch(change(form, 'cvv', ''))
  dispatch(change(form, 'upgrade_type', null))
  dispatch(change(form, 'fee', ''))
  dispatch(change(form, 'upgrade_specifics', ''))
}

export const updateAndChargeLead = (form, lead_id, values) => async (dispatch, getState) => {
  try {
    dispatch(setLoadingAction(true))

    const headers = selectHeaderData({
      'Content-Type': 'application/json'
    })(getState())
    const {
      billing_city,
      billing_state,
      billing_street,
      billing_zip_code,
      credit_card_name,
      credit_card_number,
      cvv,
      expiration_date,
      fee,
      upgrade_specifics,
      upgrade_type,
    } = values
    const body = JSON.stringify({
      lead: {
        lead_id,
        billing_city,
        billing_state,
        billing_street,
        billing_zip_code,
        credit_card_name,
        credit_card_number,
        cvv,
        expiration_date,
        upgrade: {
          fee,
          upgrade_specifics,
          upgrade_type,
        }
      }
    })

    let data = await fetch(C.API.BASE + '/billing/', {
      method: 'POST',
      headers,
      mode: 'cors',
      body
    })
    data = await parseJSON(data)

    dispatch(setLoadingAction(false))

    if (!data.errors) {
      dispatch(setErrorsAction(false))
      dispatch(updateDialogAction({
        open: true,
        title: data.message,
        body: `transaction id: ${data.transaction_id}`
      }))
      dispatch(reset(form))
      dispatch(resetPackageDetailsAction())
    } else {
      const errors = data.errors && (data.errors.payment_errors || data.errors.validation_errors).join('\n')
      
      dispatch(setErrorsAction(true))
      dispatch(updateDialogAction({
        open: true,
        title:  (data.errors && data.errors.merchant_error) || 'Something went wrong',
        body: errors || "Plesae contact an administrator for assistance."
      }))
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    dispatch(updateDialogAction({
      open: true,
      title: 'Something went wrong',
      body: "Plesae contact an administrator for assistance."
    }))
    dispatch(setErrorsAction(true))
    dispatch(setLoadingAction(false))
  }
}
