import React from 'react'
import PropTypes from 'prop-types'
import RadioButton from 'material-ui/RadioButton'
import { RadioButtonGroup } from 'redux-form-material-ui'
import { Field } from 'redux-form'

import styles from 'views/ui/RadioButtonStyles'

import OptionLabel from '../../containers/OptionLabel'

const RadioGroup = ({ options, name, label, icon, formType }) => {
  return (
    <div className="option-group radio-group">
      <OptionLabel label={label} icon={icon} name={name} formType={formType}/>
      <Field
        className="option-field-container"
        name={name}
        component={RadioButtonGroup}>
        {options.map(({ label, value}) =>
          <RadioButton
            key={value}
            value={value}
            label={label}
            className="radioButton radio-button"
            iconStyle={styles.hidden}
            labelStyle={styles.radioLabel}
          />
        )}
      </Field>
    </div>
  )
}

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any),
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
}

export default RadioGroup