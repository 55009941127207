import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AutoComplete from 'material-ui/AutoComplete'
import { api } from 'controllers/Rest'
import uuid from 'uuid/v4'

class CleanNumbers extends React.Component {

  static propTypes = {
    cleanNumbers: PropTypes.object,
    children: PropTypes.element,
    dispatch: PropTypes.func,
    loading: PropTypes.func,
  }

  componentWillMount = () => {
    this.reload()
  }

  reload = () => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(api.cleanNumbers.reset()),
      dispatch(api.cleanNumbers.sync())
    ])
      .then(() => {
        done()
      })
      .catch((failure) => {
        done()
        alert('Failure in loading Clean Numbers List', failure)
      })
    )
  }

  searchDatabase = (searchText: string) => {
    const { dispatch } = this.props
    dispatch(api.cleanNumbers.get({ search: searchText }))
  }

  searchBans = () => {
    const dataSourceConfig = {
      text: 'phone',
      value: 'phone',
    }

    return(
      <div>
        <AutoComplete
          hintText="Type a number to see if it exists in the Good Call List"
          dataSource={this.props.cleanNumbers.data}
          dataSourceConfig={dataSourceConfig}
          onUpdateInput={this.searchDatabase}
          fullWidth={true}
          style={{ width:'50%' }}
        />
      </div>
    )
  }

  phoneNumberFormattingAreaCode = (phoneNumber) => {
    const areaCode = phoneNumber.substring(0,3),
      firstThreeDigits = phoneNumber.substring(3,6),
      lastFourDigits = phoneNumber.substring(6,10)

    return `(${areaCode}) ${firstThreeDigits} - ${lastFourDigits}`
  }

  _openInNewWindow = (e, link) => {
    e.preventDefault()
    let userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf(' electron/') > -1) {
      window.require('electron').shell.openExternal(link)
    } else {
      let width = window.innerWidth / 2
      let height = window.innerHeight
      window.open(link, 'Google', `left=${width},width=${width},height=${height}`)
    }
  }

  _downloadFileLink = (name, id) => {
    return <a onClick={(e) => this._openInNewWindow(e, `https://drive.google.com/uc?id=${id}&export=download`)}>{name}</a>
  }


  renderNumbers = () => {
    return(
      <div>
        {this.searchBans()}
        <table className="striped" width="50%" style={{ textAlign:'center', paddingLeft:'20px' }}>
          <thead>
            <tr>
              <th>Clean Numbers</th>
              <th>View File</th>
            </tr>
          </thead>
          <tbody>
            {this.props.cleanNumbers.data.map(cleanNumber => {
              return (
                <tr key={uuid()}>
                  <td>{this.phoneNumberFormattingAreaCode(cleanNumber.phone)}</td>
                  <td>{cleanNumber && cleanNumber.scrub_receipt && this._downloadFileLink('View', cleanNumber.scrub_receipt.good_google_file_id)}</td>
                </tr>
              )})
            }
          </tbody>
        </table>
      </div>
    )
  }

  render = () => (
    <div>
      {!this.props.cleanNumbers.isLoading && this.renderNumbers()}
    </div>
  )
}

const getCleanNumbers = (state: GetCleanNumbers) => {
  return {
    cleanNumbers: state.cleanNumbers
  }
}

export default connect(getCleanNumbers)(CleanNumbers)
