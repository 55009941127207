import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createAlert, clearAlerts } from 'controllers/AlertController'
import Button from 'views/ui/Button'

class Kitchen extends React.Component {

  static propTypes = {
    dispatch: PropTypes.func,
  }

  alertSuccess = () => {
    this.props.dispatch(createAlert({
      dismissable: true,
      type: 'success',
      message: 'this is a success alert!',
    }))
  }

  alertWarning = () => {
    this.props.dispatch(createAlert({
      dismissable: true,
      type: 'warning',
      message: 'this is a warning alert!',
    }))
  }

  alertError = () => {
    this.props.dispatch(createAlert({
      dismissable: true,
      type: 'error',
      message: 'this is an error alert!',
    }))
  }

  alertGeneric = () => {
    this.props.dispatch(createAlert({
      dismissAfter: 3000,
      type: 'generic',
      message: 'this is an alert!',
    }))
  }

  clearAlerts = () => {
    this.props.dispatch(clearAlerts())
  }

  render = () => (
    <div className="content">
      <Button label="Generic Alert w/ Timer" onClick={this.alertGeneric} />
      <Button label="Success Alert" className="green" onClick={this.alertSuccess} />
      <Button label="Warning Alert" className="yellow" onClick={this.alertWarning} />
      <Button label="Error Alert" className="red" onClick={this.alertError} />
      <Button label="Clear Alerts" className="teal" onClick={this.clearAlerts} />
    </div>
  )
}

export default connect()(Kitchen)
