import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'

// Icons
import PersonIcon from 'material-ui-icons/Person'

//Containers and Fields
import FormSectionHeader from 'views/ui/FormSectionHeader'
import HalfPage from 'views/ui/HalfPage'
import GuestNameContainer from 'views/vsf/views/Lead/GuestNameContainer'
import SpouseNameContainer from 'views/vsf/views/Lead/SpouseNameContainer'
import PhoneFieldsContainer from 'views/vsf/views/Lead/PhoneFieldsContainer'
import EmailField from 'views/vsf/views/Lead/EmailField'
import GoogleAddressFieldContainer from 'views/vsf/views/Lead/GoogleAddressFieldContainer'
import { FORM_NAME as QUALIFICATION_FORM_NAME } from './CardQualification'

// Selectors
import { selectLeadInitValues } from '../../store/vsf/lead/selectors'

export const FORM_NAME = "wizard.lead"

const CardLead = ({ hideSpouse, change }) => {
  return (
    <div className="content-padding guest-wrapper">

      <FormSectionHeader className="margin-zero top">
        <PersonIcon className="required-input-icon input-icon svg-icon" />
        Guest Personal Information
      </FormSectionHeader>

      <HalfPage
        left={<GuestNameContainer /> }
        right={hideSpouse ? null : <SpouseNameContainer />}
      />

      <FormSectionHeader className="margin-zero middle">
        <PersonIcon className="required-input-icon input-icon svg-icon" />
        Guest Contact Information
      </FormSectionHeader>

      <PhoneFieldsContainer />
      <EmailField />
      <hr />

      <FormSectionHeader className="margin-zero middle topmargin">
        <PersonIcon className="required-input-icon input-icon svg-icon" />
        Guest Billing Address
      </FormSectionHeader>

      <GoogleAddressFieldContainer updateBillingData={change} />
      <hr />
    </div>
  )
}

CardLead.propTypes = {
  hideSpouse: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired
}

const enhance = compose(
  connect((state) => {
    return {
      hideSpouse: formValueSelector(QUALIFICATION_FORM_NAME)(state, 'classification') === 'Single',
      initialValues: selectLeadInitValues(state),
    }
  }),
  reduxForm({ form: FORM_NAME }),
)

export default enhance(CardLead)
