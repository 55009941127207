import React from 'react'
import PropTypes from 'prop-types'
// Icons
import CreditCardIcon from 'material-ui-icons/CreditCard'

import ConfirmationField from './ConfirmationField'

const ConfirmationCreditCardFields = ({ form_data, errorColor }) => {
  const { billing } = form_data
  const hasErrors = billing.syncErrors
  
  return (
    <div className="card-group">
      <div className="card-header-container">
        <CreditCardIcon color={hasErrors ? errorColor : "#7a7a7a"}/>
        <h3 className={`card-header ${hasErrors ? 'error' : ''}`}>Credit Card Information</h3>
      </div>

      <div className="confirmation-fields">
        <ConfirmationField
          label="Name"
          value={billing.values && billing.values.credit_card_name}
          error={billing.syncErrors && billing.syncErrors.credit_card_name}
        /> 
        <ConfirmationField
          label="Number"
          value={billing.values && billing.values.credit_card_number}
          error={billing.syncErrors && billing.syncErrors.credit_card_number}
        /> 
        <ConfirmationField
          label="Expiration Date"
          value={billing.values && billing.values.expiration_date}
          error={billing.syncErrors && billing.syncErrors.expiration_date}
        /> 
        <ConfirmationField
          label="CVC"
          value={billing.values && billing.values.cvv}
          error={billing.syncErrors && billing.syncErrors.cvv}
        /> 
      </div>
    </div>
  )
}

ConfirmationCreditCardFields.propTypes = {
  form_data: PropTypes.object.isRequired,
  errorColor: PropTypes.string.isRequired,
}

export default ConfirmationCreditCardFields