import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PersonIcon from 'material-ui-icons/Person'
import { TextField } from 'redux-form-material-ui'
import FieldLabel from 'views/ui/FieldLabel'
import { validateRequired} from 'helpers/validators'
import { normalizeLetter } from 'helpers/normalizers'
import { textErrorStyle } from 'views/ui/styles/styles'

const VerficationAgentField = () => (
  <div className="input radioField input-style">

    <FieldLabel
      icon={<PersonIcon className="required-input-icon input-icon svg-icon" />}
      label="Verification Agent"
      required={true}
    />

    <Field
      id="verification_agent"
      name="verification_agent"
      component={TextField}
      errorStyle={textErrorStyle}
      hintText="Verification Agent"
      normalize={normalizeLetter}
      validate={[validateRequired]}
    />
  </div>
)

VerficationAgentField.propTypes = {}

export default VerficationAgentField