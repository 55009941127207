import { createSelector } from 'reselect'

import { selectId } from '../../auth/selectors'

export const selectSalesInitValues = createSelector(
  [selectId],
  (id) => {
    const idDev = id === 'DEV'

    return {
      date_sold: new Date(),
      package_price: idDev ? 1.00 : '',
      sales_agent: idDev ? 'Clay' : '',
    }
  }
)

export const selectQAInitValues = createSelector(
  [selectId],
  (id) => {
    const idDev = id === 'DEV'

    return {
      verification_agent: idDev ? 'Terry' : '',
      verification_code: idDev ? '12345678' : '',
    }
  }
)
