/* eslint-disable react/prop-types */

import React from 'react'
import PropTypes from 'prop-types'
import TextField from 'material-ui/TextField'
import { Field } from 'redux-form'

import OptionLabel from '../../containers/OptionLabel'

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    underlineStyle={{
      width: '90%',
    }}
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
)

const TextInput = ({
  name,
  hintText,
  label,
  icon,
  type,
  formType,
}) => {
  return (
    <div className="option-group">
      <OptionLabel label={label} icon={icon} name={name} formType={formType}/>
      <Field
        className="option-field-container"
        type={type}
        name={name}
        component={renderTextField}
        hintText={hintText}
      />
    </div>
  )
}

TextInput.defaultProps = {
  hintText: "",
  type: "text",
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  type: PropTypes.string,
  formType: PropTypes.string.isRequired,
}

export default TextInput