import React from 'react'
import PropTypes from 'prop-types'
// Icons
import FaceIcon from 'material-ui/svg-icons/action/face'

import ConfirmationField from './ConfirmationField'

const ConfirmationGuestPersonalFields = ({ form_data, errorColor }) => {
  const { lead } = form_data
  const hasErrors = lead.syncErrors && lead.syncErrors.name
  
  return (
    <div className="card-group">
      <div className="card-header-container">
        <FaceIcon color={hasErrors ? errorColor : "#7a7a7a"}/>
        <h3 className={`card-header ${hasErrors ? 'error' : ''}`}>Guest Personal Information</h3>
      </div>

      <div className="confirmation-fields">
        <ConfirmationField
          label="Guest First Name"
          value={lead.values.name && lead.values.name.first_name}
          error={lead.syncErrors && lead.syncErrors.name && lead.syncErrors.name.first_name}
        /> 
        <ConfirmationField
          label="Guest Last Name"
          value={lead.values.name && lead.values.name.last_name}
          error={lead.syncErrors && lead.syncErrors.name && lead.syncErrors.name.last_name}
        /> 
        <ConfirmationField
          label="Spouse's First Name"
          value={lead.values.name && lead.values.name.spouse_first_name}
          error={lead.syncErrors && lead.syncErrors.name && lead.syncErrors.name.spouse_first_name}
        /> 
        <ConfirmationField
          label="Spouse's Last Name"
          value={lead.values.name && lead.values.name.spouse_last_name}
          error={lead.syncErrors && lead.syncErrors.name && lead.syncErrors.name.spouse_last_name}
        /> 
      </div>
    </div>
  )
}

ConfirmationGuestPersonalFields.propTypes = {
  form_data: PropTypes.object.isRequired,
  errorColor: PropTypes.string.isRequired,
}

export default ConfirmationGuestPersonalFields