import C from 'config/environment'
import { parseJSON } from './concerns/railsRest'
import Auth from 'models/Auth'
import { createAlert } from 'controllers/AlertController'

class RefundsController {
  data = {}
  app

  constructor(app) {
    this.app = app

    this.data = { users: [] }
  }

  _toggleSpinner(message) {
    this.app.setState({
      loading: true,
      loadingMessage: message
    })
  }

  _disableSpinner() {
    this.app.setState({
      loading: false,
      loadingMessage: ""
    })
  }

  massVoidRefundPayments() {
    this._toggleSpinner("Mass Refunding/Voiding Payments")
    this._massPaymentActionCall('/refunds/mass_refund_void', 'refund')
  }

  refund(id) {
    let data = { id: id }
    this._toggleSpinner("Refunding Payment")

    return fetch(C.API.BASE + `/refunds/${id}/refund`, {
      method: 'PATCH',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(parseJSON)
    .then((refund) => {
      const { dispatch } = this.app.props

      if (!refund.errors) {
        this.app.setState((prevState) => {
          return {
            ...prevState,
            refunds: {
              ...prevState.refunds,
              data: prevState.refunds.data.map((_refund) => {
                if (_refund.id === refund.refund.id) {
                  return refund.refund
                } else {
                 return _refund
                }}
               )
            }
          }
        })

        this._disableSpinner()

        dispatch(createAlert({
          dismissable: true,
          type: 'success',
          message: refund.message,
          dismissAfter: 3000,
        }))
      } else {
        this._disableSpinner()

        dispatch(createAlert({
          dismissable: true,
          type: 'error',
          message: `Error Refunding Payment: ${refund.errors.join(', ')}`,
        }))
      }
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to refund the payment'
      let errors = [error]
      let stackTrace = [error.stack]
      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      this._disableSpinner()
      return error
    })
  }

  voidRefund(id) {
    let data = { id: id }
    this._toggleSpinner("Voiding Payment for Refund")

    return fetch(C.API.BASE + `/refunds/${id}/void`, {
      method: 'PATCH',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(parseJSON)
    .then((refund) => {
      const { dispatch } = this.app.props

      if (!refund.errors) {
        this.app.setState((prevState) => {
          return {
            ...prevState,
            refunds: {
              ...prevState.refunds,
              data: prevState.refunds.data.map((_refund) => {
                if (_refund.id === refund.refund.id) {
                  return refund.refund
                } else {
                 return _refund
                }}
               )
            }
          }
        })

        this._disableSpinner()

        dispatch(createAlert({
          dismissable: true,
          type: 'success',
          message: refund.message,
          dismissAfter: 3000,
        }))
      } else {
        this._disableSpinner()

        dispatch(createAlert({
          dismissable: true,
          type: 'error',
          message: `Error Voiding Payment: ${refund.errors.join(', ')}`,
        }))
      }
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to void the payment'
      let errors = [error]
      let stackTrace = [error.stack]
      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      this._disableSpinner()

      return error
    })
  }

  _massPaymentActionCall(endPoint, action) {
    return fetch(C.API.BASE + endPoint, {
      method: 'PATCH',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        refund_ids: this.app.state.selectedRefunds
      })
    })
    .then(parseJSON)
    .then((response) => {
      // Clone existing State, modify and set new state for mass action results
      const massActionResults = { ...this.app.state.massActionResults }
      massActionResults['action'] = action
      massActionResults['success'] = response.success
      massActionResults['errors'] = response.errors
      this.app.setState({
        massActionResults: massActionResults,
        showMassActionResults: true,
        selectedRefunds: [],
      })

      // Modify payments to reflect new changes
      if (response.success.length > 0) {
        this.app.setState((prevState) => {
          // Clone existing payments and then make modifications
          const refunds = [...prevState.refunds.data]

          response.success.forEach((refund) => {
            const index = refunds.findIndex((_refund) => { return _refund.id === refund.id })
            if (index >= 0) {
              refunds[index] = refund
            }
          })

          return {
            ...prevState,
            refunds: {
              ...prevState.refunds,
              data: refunds
            }
          }
        })
      }

      this._disableSpinner()
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = `We were unable to ${action} the payment`
      let errors = [error]
      let stackTrace = [error.stack]
      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      this._disableSpinner()
      return error
    })
  }
}

export default RefundsController
