import React from 'react'
import PropTypes from 'prop-types'
import DateIcon from 'material-ui-icons/DateRange'
import { Field } from 'redux-form'
import { TextField } from 'redux-form-material-ui'
import FieldLabel from 'views/ui/FieldLabel'

const TextDateField = ({ input, meta: { touched, error }, ...custom }) => {
  return (
    <TextField
      disabled={true}
      errorText={touched && error}
      {...input}
      {...custom}
    />
  )
}

TextDateField.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
}

const DateSoldField = () => {
  return (
    <div className="input radioField input-style">
    
      <FieldLabel
        icon={<DateIcon className="required-input-icon input-icon svg-icon" />}
        label="Date Sold"
      /> 

      <Field
        name="date_sold"
        component={TextDateField}
        format={(value) => new Date(value).toLocaleDateString('en-US')}
      />
        
    </div>
  )
}

DateSoldField.propTypes = {}

export default DateSoldField