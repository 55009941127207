import csv from 'fast-csv'

const csvDirPath = (folderName) => {
  const desktopPath = window.require('electron').remote.app.getPath('desktop')
  return `${desktopPath}/${folderName}`
}

const csvPath = (folderName, fileName) => {
  return `${csvDirPath(folderName)}/${fileName}.csv`
}

const createCSVFile = (folderName, callback) => {
    if (window.process) {
      const fs = window.require('electron').remote.require('fs')

      fs.mkdir(csvDirPath(folderName), () => {
        fs.truncate(csvPath(), 0, () => {
          callback()
        })
      })
    }
  }

export default function writeToCSV(folderName, fileName, headers, data) {
  if (window.process) {
    createCSVFile(folderName, () => {
      const fs             = window.require('electron').remote.require('fs')
      const writableStream = fs.createWriteStream(csvPath(folderName, fileName))

      csv.write(
        data, {
          headers: headers,
          renameHeaders: true,
          discardUnmappedColumns: true
        }
      ).pipe(writableStream)
    })
  }
}
