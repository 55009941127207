import React from 'react'
// import PropTypes from 'prop-types'
import NameField from 'views/vsf/views/Lead/NameField'

const GuestNameContainer = () => {
  return (
    <div className="group toppad">
      <NameField 
        required={ true }
        slug="name.first_name"
        hintText="First Name"
        label="Guest First Name"
      />
      <NameField
        required={true}
        slug="name.last_name"
        hintText="Last Name"
        label="Guest Last Name"
      />
    </div>
  )
}

GuestNameContainer.propTypes = {}

export default GuestNameContainer
