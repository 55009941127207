import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Dialog from 'material-ui/Dialog'
import AutoComplete from 'material-ui/AutoComplete'
import { api } from 'controllers/Rest'
import { getBans, addSingleBan } from 'controllers/BansController'
import { BanType } from 'models/BanModel'
import uuid from 'uuid/v4'
import Button from 'views/ui/Button'

class Bans extends React.Component {

  static propTypes = {
    bans: PropTypes.object,
    children: PropTypes.element,
    dispatch: PropTypes.func,
    loading: PropTypes.func,
  }

  state = {
    saveSingleBanFlag: false,
    numberToAdd: "",
    numbersArray: []
  }

  handleSaveSingleBanDialogOpen = () => {
    this.setState({saveSingleBanFlag: true})
  }

  handleSaveSingleBanDialogClose = () => {
    this.setState({saveSingleBanFlag: false})
  }

  componentWillMount = () => {
    this.reload()
  }

  reload = () => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(api.bans.reset()),
      dispatch(api.bans.sync())
    ])
      .then(() => {
        done()
      })
      .catch((failure) => {
        done()
        alert('Failure in loading Do Not Call List', failure)
      })
    )
  }

  handleAddition = (value, index) => {
    if (index === -1 && value.length === 10 && /^[0-9]{1,10}$/.test(+value)){
      this.handleSaveSingleBanDialogOpen()
      this.setState({numberToAdd: value})
    }
  };

  saveBanDialog = () => {
    const savingBan = {banNumber: this.state.numberToAdd}
    this.saveBan(savingBan)

    this.handleSaveSingleBanDialogClose()
    this.setState({numberToAdd: ""})
  }

  saveBan = (ban: BanType) => {
    const { loading, dispatch } = this.props

    loading((done) => {
      Promise.all([
        addSingleBan(ban, dispatch)
      ])
      .then(() => {
        this.reload()
        done()
      })
      .catch((failure) => {
        done()
        alert('Failure in saving: ', failure)
        alert(failure.message)
      })
    })
  }

  searchDatabase = (searchText: string) => {
    const { dispatch } = this.props

    dispatch(api.bans.get({ search: searchText }))
  }

  searchBans = () => {
    const dataSourceConfig = {
      text: 'phone',
      value: 'phone',
    }

    return(
      <div>
        <AutoComplete
          hintText="Type a number to see if it exists in the Do Not Call List"
          dataSource={this.props.bans.data}
          dataSourceConfig={dataSourceConfig}
          onNewRequest={this.handleAddition}
          onUpdateInput={this.searchDatabase}
          fullWidth={true}
          style={{ width:'50%' }}
        />
      </div>
    )
  }

  phoneNumberFormattingAreaCode = (phoneNumber) => {
    const areaCode = phoneNumber.substring(0,3),
      firstThreeDigits = phoneNumber.substring(3,6),
      lastFourDigits = phoneNumber.substring(6,10)

    return `(${areaCode}) ${firstThreeDigits} - ${lastFourDigits}`
  }

  renderBans = () => {
    const actions = [
      <Button
        key={uuid()}
        label="Cancel"
        className="basic"
        onClick={this.handleSaveSingleBanDialogClose}
      />,
      <Button
        key={uuid()}
        label="Submit"
        className="green"
        onClick={this.saveBanDialog}
      />,
    ]

    return(
      <div>
        {this.searchBans()}
        <Dialog
            title="Would you like to save this number to the database?"
            modal={false}
            actions={actions}
            open={this.state.saveSingleBanFlag}
            onRequestClose={this.handleSaveSingleBanDialogClose}
          >
          {this.state.numberToAdd}
        </Dialog>
        <table className="striped" width="50%" style={{ textAlign:'center', paddingLeft:'20px' }}>
          <thead>
            <tr>
              <th>Do Not Call Numbers</th>
            </tr>
          </thead>
          <tbody>
            {this.props.bans.data.map(ban => {
              return (
                <tr key={uuid()}>
                  <td>{this.phoneNumberFormattingAreaCode(ban.phone)}</td>
                </tr>
              )})
            }
          </tbody>
        </table>
      </div>
    )
  }

  render = () => (
    <div>
      {!this.props.bans.isLoading && this.renderBans()}
    </div>
  )
}

export default connect(getBans)(Bans)
