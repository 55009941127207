import React from 'react'
import { reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import DestinationFieldContainer from 'views/vsf/views/DestinationFieldContainer'
import TravelTimeContainer from 'views/vsf/views/TravelTimeContainer'
import LengthOfStayContainer from 'views/vsf/views/LengthOfStayContainer'
import GiftsContainer from 'views/vsf/views/GiftsContainer'
import PlaceIcon from 'material-ui-icons/Place'
import StyleIcon from 'material-ui-icons/Style'
import FormSectionHeader from 'views/ui/FormSectionHeader'

// Selectors
import {
  selectTravelInitValues,
  selectDestinationOptions,
  selectTravelTimeOptions,
  selectLengthOfStayOptions,
} from '../../store/vsf/travel/selectors'
import { resetGifts } from '../../store/vsf/gifts/actions'

export const FORM_NAME = "wizard.accommodation"

const CardAccommodation = ({
  destination_options,
  travel_time_options,
  length_of_stay_options,
  resetGifts,
}) => {
  return (
    <div>
      <FormSectionHeader className="top">
        <PlaceIcon className="required-input-icon input-icon svg-icon" />
        Travel
      </FormSectionHeader>

      <DestinationFieldContainer destination_options={destination_options} resetGifts={resetGifts} />
      <TravelTimeContainer travel_time_months_options={travel_time_options} />
      <LengthOfStayContainer length_of_stay_options={length_of_stay_options} />

      <FormSectionHeader className="middle topmargin">
        <StyleIcon className="required-input-icon input-icon svg-icon" />
        Gifts
      </FormSectionHeader> 

      <GiftsContainer containingForm={FORM_NAME} />
    </div>
  )
}

CardAccommodation.propTypes = {
  destination: PropTypes.string,
  destination_options: PropTypes.arrayOf(
    PropTypes.string.isRequired
  ).isRequired,
  travel_time_options: PropTypes.arrayOf(
    PropTypes.number.isRequired
  ).isRequired,
  length_of_stay_options: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  ).isRequired,
  resetGifts: PropTypes.func.isRequired,
}

const enhance = compose(
  connect((state) => {
    return {
      destination_options: selectDestinationOptions(state),
      travel_time_options: selectTravelTimeOptions(state),
      length_of_stay_options: selectLengthOfStayOptions(state),
      initialValues: {
        travel: selectTravelInitValues(state)
      },
    }
  }, { resetGifts }),
  reduxForm({ form: FORM_NAME }),
)

export default enhance(CardAccommodation)
