import React from 'react'
// import PropTypes from 'prop-types'
import BillingStreetField from 'views/vsf/views/BillingAddress/BillingStreetField'
import BillingCityField from 'views/vsf/views/BillingAddress/BillingCityField'
import BillingStateField from 'views/vsf/views/BillingAddress/BillingStateField'
import BillingZipField from 'views/vsf/views/BillingAddress/BillingZipField'

const BillingFieldsContainer = () => {
  return (
    <div className="billing-fields-wrapper"> 
      <BillingStreetField  />
      <BillingCityField  />
      <BillingStateField />
      <BillingZipField />
    </div>
  )
}

BillingFieldsContainer.propTypes = {}

export default BillingFieldsContainer
