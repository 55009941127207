import { SET_QUALIFICATION_OPTIONS } from './actions'

export const INITIAL_STATE = {
  options: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_QUALIFICATION_OPTIONS:
      return {
        ...state,
        options: action.payload.options
      }

    default:
      return state
  }
}
