import React from 'react'
import ReactDOM from 'react-dom'
import ActionCableProvider from 'react-actioncable-provider'
import { Provider } from 'react-redux'
// array.flatten polyfill
import "core-js/fn/array/flatten"

// ROUTER
import { HashRouter } from 'react-router-dom'
import { renderRoutes } from 'react-router-config'
import routes from 'config/routes'

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import { red300 } from 'material-ui/styles/colors'

import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/lib/integration/react'
import store from './store/index'
import generateAuthActionsWithStore from './store/auth/redux-token-auth'
import AuthProvider from './views/containers/AuthProvider'

import 'assets/styles/app.scss'
import 'assets/styles/fonts.scss'

import C from 'config/environment'

const { verifyCredentials, signInUser, signOutUser } = generateAuthActionsWithStore(store)

verifyCredentials(store)


const muiTheme = getMuiTheme({
  fontFamily: "Freight Sans Pro; sans-serif",
  palette: {
    primary1Color: '#C23233',
    accent1Color: red300,
    error1Color: "rgb(244, 67, 54)",
    alternateTextColor: '#ccc'
  },
  button: {
    height: 36,
    minWidth: 120,
    fontSize: '16px',
  },
  raisedButton: {
    primaryTextColor: '#ccc'
  }
})

ReactDOM.render(
  <ActionCableProvider url={C.API.CABLE}>
    <Provider store={store}>
      <PersistGate persistor={persistStore(store)}>
        <MuiThemeProvider muiTheme={muiTheme}>
          <HashRouter>
            <AuthProvider signInUser={signInUser} signOutUser={signOutUser}>
              {renderRoutes(routes)}
            </AuthProvider>
          </HashRouter>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  </ActionCableProvider>,
  document.getElementById('root')
)
