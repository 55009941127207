import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'
import { reduxForm } from 'redux-form'
import { TextField, SelectField } from 'redux-form-material-ui'
import { Field } from 'redux-form'
import { validateRequired, validateEmail } from 'helpers/validators'
import Dialog from 'material-ui/Dialog'
import MenuItem from 'material-ui/MenuItem'
import MailController from 'controllers/MailController'
import DialogModel from 'models/DialogModel'
import Button from 'views/ui/Button'

class Mail extends React.Component {

  static propTypes = {
    valid: PropTypes.bool,
    route: PropTypes.object,
  }

  state = {
    to: '',
    subject: '',
    template: '',
    dialog: new DialogModel()
  }

  constructor(props) {
    super(props)

    window.document.title = props.route.title
  }

  handleField = (k: string, v: string) => {
    let newState = this.state
    newState[k] = v
    this.setState(newState)
  }

  template_options = () => {
    return ['generic'].map(d => {
      return (
        <MenuItem
          key={uuid()}
          value={d}
          primaryText={d}
        />
      )
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let mail = new MailController(this)
    mail.create()
  }

  renderErrors = (errors = []) => {
    if (errors && errors.length > 0) {
      let err = errors.map(e => <li key={uuid()} style={{padding: '5px 0'}}>{e}</li>)
      return (
        <div className="errors">
          <h3>Error Details:</h3>
          <ul>
            {err}
          </ul>
        </div>
      )
    }

    return null
  }

  closeDialog = () => {
    let newState = this.state
    newState.dialog.schema.open = false
    this.setState(newState)
  }

  render = ({ dialog } = this.state) => (
    <div className="mail">
      <form onSubmit={this.handleSubmit}>
        <Dialog
          title={dialog.schema.title}
          actions={[
            <Button key={uuid()} label="reset form" className="basic" onClick={() => window.location.reload()} />,
            <Button key={uuid()} label="close" className="red" onClick={this.closeDialog} />
          ]}
          modal={false}
          open={dialog.schema.open}
        >
          <p>{dialog.schema.body}</p>
          {dialog.schema.errors.length > 0 ? this.renderErrors(dialog.schema.errors) : null}
        </Dialog>

        <div className="input">
          <Field
            id="to"
            name="to"
            component={TextField}
            floatingLabelText="Send To:"
            onChange={(e, v) => this.handleField('to', v)}
            validate={[validateRequired, validateEmail]}
          />
        </div>
        <div className="input">
          <Field
            id="subject"
            name="subject"
            component={TextField}
            floatingLabelText="Subject:"
            onChange={(e, v) => this.handleField('subject', v)}
            validate={validateRequired}
          />
        </div>
        <div className="input">
          <Field
            id="template"
            name="template"
            component={SelectField}
            floatingLabelText="Mail Template"
            onChange={(e, v) => this.handleField('template', v)}
            validate={validateRequired}
          >
            {this.template_options()}
          </Field>
        </div>

        <div className="full input fluid">
          <Field
            id="body"
            name="body"
            component={TextField}
            floatingLabelText="Body:"
            onChange={(e, v) => this.handleField('body', v)}
            validate={validateRequired}
            fullWidth={true}
            multiLine={true}
            rows={3}
          />
        </div>

        <br/>
        <br/>

        <div className="full input">
          <button className="green button" disabled={!this.props.valid} onClick={this.handleSubmit}>Submit</button>
        </div>
      </form>
    </div>
  )
}

export default reduxForm({ form: 'mail' })(Mail)
