import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon'

const SalesIcon = ({ color, viewBox, ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <path fill={color} d="M48.3,89.2V76.8c-7.2-0.7-13.6-3.6-19.3-8.6l5.7-6.8c4.4,3.8,8.9,6.1,13.6,6.8V53     c-5.9-1.4-10.3-3.3-13-5.6c-2.7-2.3-4.1-5.7-4.1-10.3c0-4.5,1.6-8.2,4.8-11.1c3.2-2.9,7.3-4.5,12.4-4.7V10.8h5v10.7     c5.8,0.4,11.2,2.4,16.3,5.9l-5.1,7.2c-3.5-2.5-7.2-4-11.2-4.6v14.8h0.2c6,1.4,10.4,3.3,13.3,5.7c2.8,2.4,4.2,5.9,4.2,10.5     c0,4.6-1.6,8.3-4.9,11.2c-3.2,2.9-7.5,4.5-12.9,4.7v12.3H48.3z M42.7,31.9c-1.3,1.2-2,2.6-2,4.4c0,1.8,0.5,3.1,1.6,4.2     c1,1,3,2,6,2.9V29.8C45.9,30,44.1,30.7,42.7,31.9z M59.3,66.3c1.5-1.2,2.2-2.7,2.2-4.6c0-1.8-0.6-3.3-1.7-4.4     c-1.2-1.1-3.3-2.1-6.4-3v14.1C55.8,68.2,57.8,67.5,59.3,66.3z"></path>
  </SvgIcon>
)

SalesIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

SalesIcon.defaultProps = {
  color: "rgba(0,0,0,0.7)",
  viewBox: "0 0 100 100",
}

export default SalesIcon
