const CREATE_ALERT = 'CREATE_ALERT'
const DELETE_ALERT = 'DELETE_ALERT'
const CLEAR_ALERTS = 'CLEAR_ALERTS'

/**
 * Publish an alert
 * - if `dismissAfter` was set, the alert will be auto dismissed after the given period.
 * - if id wasn't specified, a time based id will be generated.
 */
export const createAlert = (data) => {
  const payload = Object.assign({}, data)

  if (!payload.id)
    payload.id = (new Date().getTime()).toString()

  return dispatch => {
    dispatch({ type: CREATE_ALERT, payload })

    if (payload.dismissAfter)
      setTimeout(() => { dispatch({ type: DELETE_ALERT, payload: payload.id }) }, payload.dismissAfter)
  }
}

/**
 * Dismiss an alert by the given id.
 */
export const deleteAlert = (id) => {
  return { type: DELETE_ALERT, payload: id }
}

/**
 * Clear all notifications
 */
export const clearAlerts = () => {
  return { type: CLEAR_ALERTS }
}

export const alertReducer = (domain = [], action) => {
  if (!action || !action.type)
    return domain

  switch (action.type) {
    case CREATE_ALERT:
      return [action.payload, ...domain.filter(({ id }) => id !== action.payload.id)]

    case DELETE_ALERT:
      return domain.filter(notif => notif.id !== action.payload)

    case CLEAR_ALERTS:
      return []

    default:
      return domain
  }
}
