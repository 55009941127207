import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon'

const MailIcon = ({ color, viewBox, ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <g fill={color}>
      <path d="M3.02439024,0.379298966 C1.36197857,0.379298966 0,1.74584034 0,3.41378172 L0,18.5862145 C0,20.2541559 1.36197857,21.6206972 3.02439024,21.6206972 L27.9756098,21.6206972 C29.6380226,21.6206972 31,20.2541559 31,18.5862145 L31,3.41378172 C31,1.74584034 29.6380226,0.379298966 27.9756098,0.379298966 L3.02439024,0.379298966 Z M3.02439024,1.89654034 L27.9756098,1.89654034 C28.1940728,1.89654034 28.3926316,1.94744379 28.578125,2.02694724 L16.5396341,12.8491455 C15.8764062,13.4447766 15.0988925,13.4447766 14.4367378,12.8491455 L2.421875,2.02694724 C2.60736841,1.94744379 2.80592909,1.89654034 3.02439024,1.89654034 Z M29.4759909,3.25970586 C29.481,3.31121621 29.4878049,3.36067828 29.4878049,3.41378172 L29.4878049,18.5862145 C29.4878049,19.4398524 28.8264237,20.1034559 27.9756098,20.1034559 L3.02439024,20.1034559 C2.17357785,20.1034559 1.51219512,19.4398524 1.51219512,18.5862145 L1.51219512,3.41378172 C1.51219512,3.36469897 1.51972963,3.31925759 1.52400915,3.27154034 L13.4325457,13.9752421 C14.6089504,15.03348 16.3671286,15.0320386 17.5438262,13.9752421 L29.4759909,3.25970586 Z"></path>
    </g>
  </SvgIcon>
)

MailIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

MailIcon.defaultProps = {
  color: "rgba(0,0,0,0.7)",
  viewBox: "0 0 31 22",
}

export default MailIcon
