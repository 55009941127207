import { checkStatus, parseJSON } from './concerns/railsRest'
import C from '../config/environment'
import Auth from '../models/Auth'

class SupportController {

  data = {}
  app

  constructor(app) {
    this.app = app
    this.data = {
      body: this.app.state.body,
    }
  }

  create() {
    return fetch(C.API.BASE + '/support', {
      method: 'POST',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.data)
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      let title = data.success ? 'Successfully sent your email' : 'There were some problems'
      let body = data.message
      let errors = data.errors || []

      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
          }
        }
      })
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'You may have forgotten to fill out a required field or entered an invalid value. You can safely check over the form and resubmit again (no charge or record has been saved). If the error persists, please contact AMG for support.'
      let errors = [error.stack]

      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
          }
        }
      })

      return error
    })
  }
}

export default SupportController
