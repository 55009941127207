export const CSV_FIELD_MAPPINGS = {
  subject: "SUBJECT",
  order_id: "ORDERID",
  phone: "PHONE",
  full_name: "FULL NAME",
  country: "COUNTRY",
  address_one: "ADDRESS 1",
  city: "CITY",
  state: "STATE",
  zip: "ZIP",
  order_date: "ORDER DATE",
  status: "STATUS",
  print_message: "PRINT MESSAGE",
  mail_piece: "MAIL PIECE",
  mail_class: "MAIL CLASS",
  tracking_service: "TRACKING SERVICE",
  weight: "Weight",
  from_phone: "FROM PHONE",
  content_type: "Content Type",
  content_description: "Content Description",
  internal_transaction_number: "Internal Transaction Number",
  name: "Name:",
  quantity: "Quantity:",
  unit_price: "Unit Price:"
}
