import React from 'react'
import PropTypes from 'prop-types'
import RadioButton from 'material-ui/RadioButton'
import { RadioButtonGroup } from 'redux-form-material-ui'
import { Field } from 'redux-form'
import LengthIcon from 'material-ui-icons/Schedule'
import styles from 'views/ui/RadioButtonStyles'
import FieldLabel from 'views/ui/FieldLabel'

const renderRadioButtons = (length_of_stay_options) => {
  return length_of_stay_options
    .map(lengthOfStay => {
      return (
        <RadioButton
          key={lengthOfStay}
          label={`${lengthOfStay[0]} day & ${lengthOfStay[1]} night stay`}
          value={`${lengthOfStay[0]} day & ${lengthOfStay[1]} night stay`}
          className="radioButton radio-button"
          iconStyle={styles.hidden}
          labelStyle={styles.radioLabel}
        />
      )
    }
  )
}

const LengthOfStayRadioButtons = ({ length_of_stay_options, required }) => {
  return (
    <div className="input radioField input-style">
      <FieldLabel
        icon={<LengthIcon className="required-input-icon input-icon svg-icon" />}
        label="Length Of Stay"
        required={true}
      />

      <Field
        id="length_of_stay"
        name="travel.length_of_stay"
        component={RadioButtonGroup}
        validate={required}
      >
        {renderRadioButtons(length_of_stay_options)}
      </Field>
    </div>
  )
}


LengthOfStayRadioButtons.propTypes = {
  length_of_stay_options: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.number.isRequired)
  ).isRequired,
  required: PropTypes.func,
}

export default LengthOfStayRadioButtons
