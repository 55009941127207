export const getAccommodationData = (accData) => {
  let data = {}
  
  Object.keys(accData).forEach((acc) => {
    switch (acc) {
      case 'gifts':
        data.gifts = []
        Object.keys(accData[acc]).forEach((gift) => {
          if (typeof accData[acc][gift] === 'boolean') return data.gifts.push(gift)
          else if (typeof accData[acc][gift] === 'object') {
            Object.keys(accData[acc][gift]).forEach((nestedGifts) =>
              Object.values(accData[acc][gift][nestedGifts]).forEach((nestedGift) =>
                data.gifts.push(nestedGift)
              )
            )
          }
        })
        break
      
        case 'travel':
          data = {
            ...data,
            ...accData[acc]
          }
        break
      default:
        break
    }
  })
  
  return data
}

export const getLeadData = (leadData) => {
  return Object.keys(leadData).reduce((aggr, curr) => {
    return {
      ...aggr,
      ...leadData[curr]
    }
  }, {})
}