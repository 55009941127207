import { createSelector } from 'reselect'

export const selectId = (state) => state.auth.id
const selectNavRules = (state) => state.auth.acl
const selectAccessToken = (state) => state.auth['access-token']
const selectExpiry = (state) => state.auth.expiry
const selectUid = (state) => state.auth.uid
const selectClient = (state) => state.auth.client
const selectTokenType = (state) => state.auth['token-type']

export const selectAuthorizedRoutes = createSelector(
  [selectId, selectNavRules],
  (id, navRules) => {
    let authorizedRoutes = []

    Object.entries(navRules).forEach(([ key, value ]) => {
      if (value === '*' || Array.isArray(value) && value.includes(id)) {
        authorizedRoutes.push(key)
      }
    })

    return authorizedRoutes
  }
)

export const selectHeaderData = (overrides) => createSelector(
  [selectAccessToken, selectExpiry, selectUid, selectClient, selectTokenType],
  (accessToken, expiry, uid, client, tokenType) => ({
    'access-token': accessToken,
    expiry,
    uid,
    client,
    'token-type': tokenType,
    ...overrides
  })
)