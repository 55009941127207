import AdminIcon from './AdminIcon'
import BillingIcon from './BillingIcon'
import LeadsIcon from './LeadsIcon'
import KitchenIcon from './KitchenIcon'
import MailIcon from './MailIcon'
// import MailInverseIcon from './MailInverseIcon'
import PrintIcon from './PrintIcon'
import SalesIcon from './SalesIcon'
import ScrubberIcon from './ScrubberIcon'
import StatsIcon from './StatsIcon'
import StampsIcon from './StampsIcon'
import ReloadIcon from './ReloadIcon'
import SupportIcon from './SupportIcon'

export default {
  admin: AdminIcon,
  billing: BillingIcon,
  leads: LeadsIcon,
  kitchen: KitchenIcon,
  mail: MailIcon,
  print: PrintIcon,
  sales: SalesIcon,
  scrubber: ScrubberIcon,
  stats: StatsIcon,
  stamps: StampsIcon,
  reload: ReloadIcon,
  support: SupportIcon
}
