import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
// import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'

import VerficationAgentField from 'views/vsf/views/Sales/VerficationAgentField'
import VerficationCodeField from 'views/vsf/views/Sales/VerficationCodeField'
// Selectors
import { selectQAInitValues } from '../../store/vsf/sales/selectors'

export const FORM_NAME = "wizard.quality_assurance"

const CardQualityAssurance = () => {
  return (
    <div>

      <VerficationAgentField />
      <VerficationCodeField />

      <hr />
    </div>
  )
}

CardQualityAssurance.propTypes = {}

const enhance = compose(
  connect(state => {
    return {
      initialValues: selectQAInitValues(state)
    }
  }),
  reduxForm({ form: FORM_NAME }),
)

export default enhance(CardQualityAssurance)
