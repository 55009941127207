import type { GetBans, BanType } from 'models/BanModel'
import { api } from 'controllers/Rest'
import type { Dispatch } from 'redux'

export const getBans = (state: GetBans) => {
  return {
    bans: state.bans
  }
}

export const addSingleBan = (values: BanType, dispatch: Dispatch<*>) => {
  const payload = {
    body: JSON.stringify({
      ban: {
        phone: values.banNumber
      }
    })
  }

  //TODO: pass to view error messaging
  return dispatch(api.bans.post({}, payload))
}
