import React from 'react'
import PropTypes from 'prop-types'
import PlaceIcon from 'material-ui-icons/Place'
import FieldLabel from 'views/ui/FieldLabel'
import PlaceSearch from 'views/ui/PlaceSearch'

const GoogleAddressField = ({ updateBillingData }) => {
  return (
    <div className="input radioField input-style full address">

      <FieldLabel
        icon={<PlaceIcon className="required-input-icon input-icon svg-icon" />}
        label="Google Address Search"
        required={true}
      />

      <PlaceSearch
        types={['geocode']}
        componentRestrictions={{country: 'us'}}
        updateBillingData={updateBillingData}
      />
    </div>
  )
}

GoogleAddressField.propTypes = {
  updateBillingData: PropTypes.func.isRequired
}

export default GoogleAddressField