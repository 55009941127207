import React from 'react'
import PropTypes from 'prop-types'
import { renderRoutes } from 'react-router-config'
import Sidebar from 'views/ui/Sidebar'
import Alerts from 'views/ui/Alerts'

const Dashboard = ({ route, ...props }) => {
  return (
    <main className="application">
      <section className="content">
        {renderRoutes(route.routes)}
      </section>
      <Alerts />
      <Sidebar {...props} />
    </main>
  )
}

Dashboard.propTypes = {
  route: PropTypes.object,
  location: PropTypes.object,
}

export default Dashboard
