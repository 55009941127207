import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader, CardText } from 'material-ui/Card'
import Dialog from 'material-ui/Dialog'

const styles = {
  errorsContainer: {
    padding: '2rem'
  },
  errorsListItem: {
    marginBottom: '1.25rem'
  },
}

const renderValidationErrors = (errors = []) => {
  if (errors && errors.length > 0) {
    return (
      <div className="errors">
        <h3>We have encountered some Validation Errors. <br />Please correct these in the VSF Form and resubmit:</h3>
        <ul style={styles.errorsContainer}>
          {errors.map((e) => (
            <li style={styles.errorsListItem} key={e}>{e}</li>
          ))}
        </ul>
      </div>
    )
  }
}

const renderErrors = (errors = []) => {
  if (errors && errors.length > 0) {
    let err = errors.map(e => {
      const errMessage = e.toString()
      return (
        <li key={errMessage} style={{padding: '5px 0'}} dangerouslySetInnerHTML={{__html: errMessage}} />
      )
    })
    return (
      <div className="errors">
        <h3>Error Details:</h3>
        <ul>
          {err}
        </ul>
      </div>
    )
  }
  return null
}

const renderStackTrace = (errors = []) => {
  if (errors && errors.length > 0) {
    let err = errors.map(e => <li key={e} style={{padding: '5px 0'}}>{e}</li>)
    return (
      <Card style={{marginTop: '10px'}}>
        <CardHeader
          title="Stack Trace Details"
          subtitle=""
          actAsExpander={true}
          showExpandableButton={true}
        />
        <CardText expandable={true}>
          {err}
        </CardText>
      </Card>
    )
  }

  return null
}

const DialogContainer = ({
  title,
  body,
  actions,
  open,
  errors,
  validationErrors,
  stackTrace,
}) => {
  return (
    <Dialog
      title={title}
      actions={actions}
      modal={false}
      open={open}
      autoScrollBodyContent={true}>
      <p style={{marginTop: '10px'}} dangerouslySetInnerHTML={{__html: body}} />
      {errors.length > 0 ? renderErrors(errors) : null}
      {validationErrors.length > 0 ? renderValidationErrors(validationErrors) : null}
      {stackTrace.length > 0 ? renderStackTrace(stackTrace) : null}
    </Dialog>
  )
}

DialogContainer.defaultProps = {
  open: false,
  errors: [],
  validationErrors: [],
  stackTrace: [],
}

DialogContainer.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.element.isRequired),
  open: PropTypes.bool,
  errors: PropTypes.array,
  validationErrors: PropTypes.array,
  stackTrace: PropTypes.array,
}

export default DialogContainer
