/* eslint-disable */
import React from 'react'
import { Table } from 'reactable'
import PropTypes from 'prop-types'
import RaisedButton from 'material-ui/RaisedButton'

const renderActions = (task_id, markAsCompleted) => (
  <div className="actions">
    <RaisedButton
      label="Close"
      primary
      onClick={() => {markAsCompleted(task_id)}}
    />
  </div>
)

const StampsList = ({ tasks, markAsCompleted }) => {
  let items = tasks.map(task => {
    return {
      'Order ID':      task.order_id,
      'Full Name':     task.full_name,
      'Subject':       task.subject,
      'Print Message': task.print_message,
      'Actions':       renderActions(task.task_id, markAsCompleted)
    }
  })

  return (
    <Table
      className="table"
      data={items}
      sortable={true}
      filterable={['Order ID', 'Full Name', 'Subject', 'Print Message']}
      width="100%"
    />
  )
}

StampsList.defaultProps = {
  tasks: []
}

StampsList.propTypes = {
  tasks: PropTypes.array
}

export default StampsList
