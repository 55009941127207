import { combineReducers } from 'redux'
import userReducer from './user/reducer'

export const INITIAL_STATE = {}

const rootReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default combineReducers({
  root: rootReducer,
  user: userReducer,
})