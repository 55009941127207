class Auth {

  static current() {
    if (typeof window !== "undefined") {
      return {
        id: localStorage.getItem('id'),
        uid: localStorage.getItem('uid'),
        pin: localStorage.getItem('pin'),
        token: localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        expiry: localStorage.getItem('expiry')
      }
    } else {
      // if you returned undefined you'd have to find all invocations of current
      // to check for undefined
      return {}
    }

  }

  static isAdmin = () => {
    return Auth.current().id === 'VSF$ADMIN'
  }

  static isAllowed = (resource) => {
    let id = Auth.current().id

    if (!id || !resource || typeof id !== 'string' || typeof resource !== 'string')
      return false

    let acl = {
      login: '*',
      sales: '*',
      support: '*',
      mail: ['VSF$ADMIN', 'DEV'],
      print: ['VSF$ADMIN', 'DEV'],
      admin: ['VSF$ADMIN', 'DEV'],
      billing: ['VSF$ADMIN', 'DEV'],
      stats: ['VSF$ADMIN', 'DEV'],
      scrubber: ['VSF$ADMIN', 'DEV'],
      leads: ['VSF$ADMIN', 'DEV'],
      stamps: ['VSF$ADMIN', 'DEV'],
      kitchen: ['DEV'],
    }

    if (!acl[resource]) {
      console.error(`Resource ${resource} not found`) // eslint-disable-line
      console.error("Did you add it to the ACL list in Auth.js or mistype the resource name") // eslint-disable-line
    }

    return acl[resource] === '*' || acl[resource].includes(id)
  }
}

export default Auth
