import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import CardIcon from 'material-ui/svg-icons/action/credit-card'
import GearIcon from 'material-ui/svg-icons/action/settings'
import LabelIcon from 'material-ui/svg-icons/action/label'
import AlarmIcon from 'material-ui-icons/Alarm'
import LengthIcon from 'material-ui-icons/Schedule'
import LocationIcon from 'material-ui/svg-icons/communication/location-on'
import { Field } from 'redux-form'
import { Checkbox } from 'redux-form-material-ui'


import RadioGroup from './RadioGroup'
import TextInput from './TextInput'
import ChipInput from './ChipInput'
import UserMenu from './UserMenu'
import Gifts from './Gifts'
import StyleIcon from 'material-ui-icons/Style'

import './index.scss'

import Button from 'views/ui/Button'
import Dialog from '../../../containers/Dialog'

const Options = ({
  activeLocation,
  canAccessSales,
  options,
  onLocationChipClick,
  onAddChip,
  onSubmit,
  onDeleteChip,
  los,
  errors,
  ttim,
  locations,
  form,
  loading,
  userPresets,
  onReset,
  onEditUserChange,
  onDialogClose,
  editing,
}) => {
  const formType = form.split('.')[1]

  return (
    <div className="Options-container">
      <Dialog
        actions={[
          <Button
            key="close"
            label="close"
            className="basic"
            onClick={onDialogClose}
          />,
          formType !== 'create' && <Button
            key="reset"
            label="Reset Form"
            className="red"
            onClick={(e) => onReset(e, true)}
          />
        ].filter(Boolean)}
      />
      <div className="Options-presets">
        {formType === 'create' && <ChipInput
          loading={loading.preset}
          allOptions={options.presets}
          options={userPresets}
          onAddChip={onAddChip}
          onDeleteChip={onDeleteChip}
          name="presets"
          label='User Presets'
          formType={formType}
          icon={<GearIcon />}
        />}
        {formType === 'edit' && <UserMenu
          loading={loading.preset}
          allOptions={options.presets}
          name="presets"
          label='Edit User'
          formType={formType}
          icon={<GearIcon />}
          onChange={onEditUserChange}
        />}
      </div>
      {(formType !== 'edit' || editing) && <Fragment>
        <div className="Options-inputs">
          <TextInput
            hintText="Name"
            name="name"
            label="Name"
            formType={formType}
            icon={<LabelIcon />}
          />

          <RadioGroup
            options={options.card}
            name="card"
            label="Card Type"
            formType={formType}
            icon={<CardIcon />}
          />

          <TextInput
            hintText="Password"
            name="password"
            label="Password"
            icon={<LabelIcon />}
            formType={formType}
            type="password"
          />

          <TextInput
            hintText="Confirm Password"
            name="confirmPassword"
            label="Confirm Password"
            icon={<LabelIcon />}
            formType={formType}
            type="password"
          />

          <ChipInput
            allOptions={options.ttim}
            options={ttim}
            onAddChip={onAddChip}
            onDeleteChip={onDeleteChip}
            name="ttim"
            label='Travel Time'
            formType={formType}
            icon={<AlarmIcon />}
          />

          <ChipInput
            allOptions={options.los}
            options={los}
            onAddChip={onAddChip}
            onDeleteChip={onDeleteChip}
            name="los"
            label='Length Of Stay'
            formType={formType}
            icon={<LengthIcon />}
          />

          <ChipInput
            activeLocation={activeLocation}
            allOptions={options.locations}
            options={locations}
            onLocationChipClick={onLocationChipClick}
            onAddChip={onAddChip}
            onDeleteChip={onDeleteChip}
            name="locations"
            label='Locations'
            icon={<LocationIcon />}
            formType={formType}
            fullWidth
          />

          <Gifts
            options={options.gifts}
            name="gifts"
            label={activeLocation.label ? `Gifts for ${activeLocation.label}` : 'Gifts (select a location)'}
            activeLocation={activeLocation}
            form={form}
            formType={formType}
            icon={<StyleIcon />}
          />
        </div>

        <div className="Options-inputs">
          <div className="OptionLabel-container"><h3>Tabs user can access:</h3></div>

          <div className="option-group gifts full-width">
            <div className="gifts-container">
              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Sales"
                  name="can_access_sales"
                  component={Checkbox}>
                </Field>
              </div>

             <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Hide Credit Card on Sales"
                  name="skip_credit_card"
                  component={Checkbox}
                  disabled={!canAccessSales}>
                </Field>
              </div>


              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Billing"
                  name="can_access_billing"
                  component={Checkbox}>
                </Field>
              </div>

              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Mail"
                  name="can_access_mail"
                  component={Checkbox}>
                </Field>
              </div>

              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Leads"
                  name="can_access_leads"
                  component={Checkbox}>
                </Field>
              </div>

              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Admin"
                  name="can_access_admin"
                  component={Checkbox}>
                </Field>
              </div>

              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Print"
                  name="can_access_print"
                  component={Checkbox}>
                </Field>
              </div>

              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Stamps"
                  name="can_access_stamps"
                  component={Checkbox}>
                </Field>
              </div>

              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Capture & Void"
                  name="can_access_capture_and_void"
                  component={Checkbox}>
                </Field>
              </div>

              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Refund"
                  name="can_access_refunds"
                  component={Checkbox}>
                </Field>
              </div>

              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Scrubber"
                  name="can_access_scrubber"
                  component={Checkbox}>
                </Field>
              </div>
              <div className="option-field-container checkbox">
                <Field
                  labelStyle={{
                    fontWeight: 'bold'
                  }}
                  label="Support"
                  name="can_access_support"
                  component={Checkbox}>
                </Field>
              </div>
            </div>
          </div>
        </div>


        <div className="Options-submit-container">
          <Button
            label="Reset"
            className="red"
            onClick={onReset}
          />
          <Button
            label={loading[formType] ? `${formType}...` : `${formType} User`}
            className="green"
            onClick={onSubmit}
            disabled={!!errors || loading[formType]}
          />
        </div>
      </Fragment>}
    </div>
  )
}

Options.defaultProps = {
  loading: false,
  editing: false
}

Options.propTypes = {
  activeLocation: PropTypes.object,
  canAccessSales: PropTypes.bool,
  options: PropTypes.object.isRequired,
  onLocationChipClick: PropTypes.func.isRequired,
  onAddChip: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onDeleteChip: PropTypes.func.isRequired,
  onEditUserChange: PropTypes.func.isRequired,
  los: PropTypes.array,
  ttim: PropTypes.array,
  locations: PropTypes.array,
  userPresets: PropTypes.array,
  form: PropTypes.string.isRequired,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  errors: PropTypes.object,
  loading: PropTypes.object,
  editing: PropTypes.string,
}

export default Options
