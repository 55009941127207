import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PhoneIcon from 'material-ui-icons/Phone'
import { TextField } from 'redux-form-material-ui'
import { textErrorStyle } from 'views/ui/styles/styles'
import FieldLabel from 'views/ui/FieldLabel'

const PhoneField = ({ label, slug, hintText, normalize, validate, required }) => {
  return (
    <div className="input radioField input-style width-third">
      <FieldLabel
        icon={<PhoneIcon className="required-input-icon input-icon svg-icon" />}
        label={label || "Phone"}
        required={required}
      />

      <Field
        id={slug}
        name={slug}
        component={TextField}
        hintText={hintText || label}
        errorStyle={textErrorStyle}
        fullWidth={true}
        normalize={normalize}
        validate={validate}
      />
    </div>
  )
}

PhoneField.propTypes = {
  label:       PropTypes.string,
  slug:        PropTypes.string.isRequired,
  hintText:    PropTypes.string,
  required:    PropTypes.bool,
  normalize:   PropTypes.any,
  validate:    PropTypes.any
}

export default PhoneField