var currentEnvURL, cableURL

if ((window.process && process.env.NODE_ENV === 'production') || process.env.NODE_ENV === 'test') {
  currentEnvURL = 'https://sales.allenmg.com'
  cableURL = 'wss://sales.allenmg.com/cable'
} else if (window.process && process.env.NODE_ENV === 'production') {
  currentEnvURL = 'https://amg-staging.herokuapp.com'
  cableURL = 'wss://amg-staging.herokuapp.com/cable'
} else {
  let loc = window.location
  cableURL = `${(loc.protocol === "https:" ? "wss" : "ws")}://${loc.host.replace(/:3000/, ':5000')}/cable`
  currentEnvURL = loc.origin.replace('3000', '5000')
}

export default {
  API: {
    BASE: currentEnvURL,
    CABLE: cableURL
  },
  AUTH: {
    BASE: `${currentEnvURL}/auth`,
  },
  PRINT: {
    BASE: `${currentEnvURL}/tasks/mailer/`,
    PC: `${currentEnvURL}/email/lead/purchase_confirmation?part=text/html&mode=desktop`
  }
}
