// @flow
import type { Dispatch } from 'redux'
import type { GetTasks, TaskType } from 'models/TaskModel'
import { api } from 'controllers/Rest'

export const getTasks = (state: GetTasks) => {
  return {
    tasks: state.tasks
  }
}

export const updateTask = (values: TaskType, dispatch: Dispatch<*>) => {
  return dispatch(api.task.put({ id: values.task_id }, { body: JSON.stringify(values) }))
}
