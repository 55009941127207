import C from 'config/environment'
import { parseJSON } from './concerns/railsRest'
import Auth from 'models/Auth'
import { createAlert } from 'controllers/AlertController'

class PaymentsController {
  data = {}
  app

  constructor(app) {
    this.app = app

    this.data = { users: [] }
  }

  _toggleSpinner(message) {
    this.app.setState({
      loading: true,
      loadingMessage: message
    })
  }

  _disableSpinner() {
    this.app.setState({
      loading: false,
      loadingMessage: ""
    })
  }

  massApprovePayments() {
    this._toggleSpinner("Mass Approving Payments")
    this._massPaymentActionCall('/payments/mass_approve', 'approve')
  }

  massVoidPayments() {
    this._toggleSpinner("Mass Voiding Payments")
    this._massPaymentActionCall('/payments/mass_void', 'void')
  }

  approvePayment(id) {
    let data = { id: id }
    this._toggleSpinner("Approving Payment")

    return fetch(C.API.BASE + `/payments/${id}/approve`, {
      method: 'PATCH',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(parseJSON)
    .then((payment) => {
      const { dispatch } = this.app.props

      if (!payment.errors) {
        this.app.setState((prevState) => {
          return {
            ...prevState,
            payments: {
              ...prevState.payments,
              data: prevState.payments.data.map((_payment) => {
                if (_payment.id === payment.payment.id) {
                  return payment.payment
                } else {
                 return _payment
                }}
               )
            }
          }
        })

        this._disableSpinner()

        dispatch(createAlert({
          dismissable: true,
          type: 'success',
          message: payment.message,
          dismissAfter: 3000,
        }))
      } else {
        this._disableSpinner()

        dispatch(createAlert({
          dismissable: true,
          type: 'error',
          message: `Error Capturing Payment: ${payment.errors.join(', ')}`,
        }))
      }
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to capture the payment'
      let errors = [error]
      let stackTrace = [error.stack]
      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      this._disableSpinner()
      return error
    })
  }

  voidPayment(id) {
    let data = { id: id }
    this._toggleSpinner("Voiding Payment")

    return fetch(C.API.BASE + `/payments/${id}/void`, {
      method: 'PATCH',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(parseJSON)
    .then((payment) => {
      const { dispatch } = this.app.props

      if (!payment.errors) {
        this.app.setState((prevState) => {
          return {
            ...prevState,
            payments: {
              ...prevState.payments,
              data: prevState.payments.data.map((_payment) => {
                if (_payment.id === payment.payment.id) {
                  return payment.payment
                } else {
                 return _payment
                }}
               )
            }
          }
        })

        this._disableSpinner()

        dispatch(createAlert({
          dismissable: true,
          type: 'success',
          message: payment.message,
          dismissAfter: 3000,
        }))
      } else {
        this._disableSpinner()

        dispatch(createAlert({
          dismissable: true,
          type: 'error',
          message: `Error Voiding Payment: ${payment.errors.join(', ')}`,
        }))
      }
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'We were unable to void the payment'
      let errors = [error]
      let stackTrace = [error.stack]
      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      this._disableSpinner()

      return error
    })
  }

  _massPaymentActionCall(endPoint, action) {
    return fetch(C.API.BASE + endPoint, {
      method: 'PATCH',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        payment_ids: this.app.state.selectedPayments
      })
    })
    .then(parseJSON)
    .then((response) => {
      // Clone existing State, modify and set new state for mass action results
      const massActionResults = { ...this.app.state.massActionResults }
      massActionResults['action'] = action
      massActionResults['success'] = response.success
      massActionResults['errors'] = response.errors
      this.app.setState({
        massActionResults: massActionResults,
        showMassActionResults: true,
        selectedPayments: [],
      })

      // Modify payments to reflect new changes
      if (response.success.length > 0) {
        this.app.setState((prevState) => {
          // Clone existing payments and then make modifications
          const payments = [...prevState.payments.data]

          response.success.forEach((payment) => {
            const index = payments.findIndex((_payment) => { return _payment.id === payment.id })
            if (index >= 0) {
              payments[index] = payment
            }
          })

          return {
            ...prevState,
            payments: {
              ...prevState.payments,
              data: payments
            }
          }
        })
      }

      this._disableSpinner()
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = `We were unable to ${action} the payment`
      let errors = [error]
      let stackTrace = [error.stack]
      this.app.setState({
        dialog: {
          schema: {
            open: true,
            title: title,
            body: body,
            errors: errors,
            stackTrace
          }
        },
        admin: {
          schema: {
            showSpinner: false
          }
        }
      })

      this._disableSpinner()
      return error
    })
  }
}

export default PaymentsController
