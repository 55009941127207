import React from 'react'
import PropTypes from 'prop-types'
import RadioButton from 'material-ui/RadioButton'
import { RadioButtonGroup } from 'redux-form-material-ui'
import { Field } from 'redux-form'
import AlarmIcon from 'material-ui-icons/Alarm'
import styles from 'views/ui/RadioButtonStyles'
import FieldLabel from 'views/ui/FieldLabel'

const renderRadioButtons = (travel_time_months_options) => {
  return travel_time_months_options
    .map(travelTime => {
      return (
        <RadioButton
          key={travelTime}
          value={`${travelTime} Months`}
          label={`${travelTime} Months`}
          className="radioButton radio-button"
          iconStyle={styles.hidden}
          labelStyle={styles.radioLabel}
        />
      )
    }
  )
}

const TravelTimeRadioButtons = ({ travel_time_months_options, required }) => {
  return (
    <div className="input radioField input-style">
      <FieldLabel
        icon={<AlarmIcon className="required-input-icon input-icon svg-icon" />}
        label="Travel Time"
        required={true}
      />

      <Field
        id="travel_time_months"
        name="travel.travel_time_months"
        component={RadioButtonGroup}
        validate={required}
      >
        {renderRadioButtons(travel_time_months_options)}
      </Field>
    </div>
  )
}

TravelTimeRadioButtons.propTypes = {
  travel_time_months_options: PropTypes.arrayOf(
    PropTypes.number.isRequired
  ).isRequired,
  required: PropTypes.func.isRequired,
}

export default TravelTimeRadioButtons
