import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon'

const ScrubberIcon = ({ color, viewBox, ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <g fill={color}>
      <path d="M0.338289474,19.492 C0.338289474,19.492 4.11118421,23.4672 10.0827632,22.3036 C10.0827632,22.3036 9.14881579,24.9112 6.95605263,25.2164 C6.95605263,25.2164 11.6305263,29.3644 15.8253947,26.3448 C15.8253947,26.3448 16.3661842,29.2164 14.5196053,29.9924 C14.5196053,29.9924 21.4961842,33.6508 22.8955263,30.0568 C24.2944737,26.4632 24.2865789,17.7216 24.2865789,17.7216 L15.9122368,12.5716 C15.9122368,12.5716 9.45947368,18.1032 0.338289474,19.492 Z" id="Shape"></path>
      <path d="M23.1943421,12.522 L29.6617105,1.7244 L27.4468421,0.362 L20.9790789,11.1596 C18.9998684,10.3456 17.0767105,10.5224 16.3677632,11.7064 L24.8435526,16.92 C25.5528947,15.736 24.8202632,13.9256 23.1943421,12.522 Z" id="Shape"></path>
    </g>
  </SvgIcon>
)

ScrubberIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

ScrubberIcon.defaultProps = {
  color: "rgba(0,0,0,0.7)",
  viewBox: "0 0 30 32",
}

export default ScrubberIcon
