import React from 'react'
// import PropTypes from 'prop-types'
import PhoneField from 'views/vsf/views/Lead/PhoneField'
import { validateRequired, validatePhone } from 'helpers/validators'
import { normalizePhone } from 'helpers/normalizers'

const PhoneFieldsContainer = () => {
  return (
    <div className="group toppad left phones">
      <PhoneField
        normalize={normalizePhone}
        validate={[validateRequired, validatePhone]}
        slug="phone.phone"
        label="Phone"
        required={true}
      />

      <PhoneField
        normalize={normalizePhone}
        validate={validatePhone}
        slug="phone.alt_phone"
        label="Alt. Phone"
      />
    </div>
  )
}

PhoneFieldsContainer.propTypes = {}

export default PhoneFieldsContainer
