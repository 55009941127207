import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Field } from 'redux-form'
import { Checkbox, RadioButtonGroup } from 'redux-form-material-ui'
import RadioButton from 'material-ui/RadioButton'
// Icons
import ClassIcon from 'material-ui-icons/Group'
import BookmarkIcon from 'material-ui/svg-icons/action/bookmark'
import FormSectionHeader from 'views/ui/FormSectionHeader'

// Selectors
import {
  selectQualificationInitValues,
  selectQualificationOptions,
  selectQualificationsFormData,
} from '../../store/vsf/qualification/selectors'

const reqColor = 'rgb(209, 75, 71)'

export const FORM_NAME = 'wizard.qualification'

const styles = {
  radio: {
    display: 'inline-block',
    width: 'auto',
    marginRight: '1rem',
    float: 'none',
  },
  radioLabel: {
    borderRadius: '5px',
    borderColor: '#ddd',
    borderWidth: 1,
    borderStyle: 'solid',
    fontSize: 14,
    float: 'none',
    width: '100%',
    padding: '0.25rem 0.75rem',
    marginBottom: '0.5rem',
  },
  radioLabelwIcon: {
    position: 'relative',
    display: 'block',
    marginBottom: '1.25rem',
    lineHeight: '24px',
    textIndent: 30,
    color: 'rgba(0,0,0,0.5)',
    fontSize: '15px',
    marginTop: '0.25rem',
  },
  colorIcon: {
    fill: reqColor,
  },
}
styles.standAloneLabel = Object.assign({}, styles.radioLabelwIcon, {
  margin: '2rem 0 0 1.5rem',
})
styles.standAloneLabelReq = Object.assign({}, styles.radioLabelwIcon, {
  margin: '2rem 0 0 1.5rem',
  color: reqColor,
})
styles.input = Object.assign({}, { bottom: 0, left: 2 }, styles.colorIcon)
styles.reqLabel = Object.assign({}, styles.radioLabelwIcon, { color: reqColor })

const required = (value) => (value == null ? 'Required' : undefined)

const CardQualification = ({
  qualificationOptions,
  getQualificationsFormData,
}) => {
  const { age, presentation } = qualificationOptions

  const renderPresentationCheck = () => {
    let labelText = `Guest agrees to attend ${presentation} minute presentation`

    return (
      <div className="input checkbox qualification">
        <Field
          id="presentation"
          name="qualifications.presentation"
          component={Checkbox}
          label={labelText}
          validate={required}
        />
      </div>
    )
  }

  const renderClassificationValues = () => {
    const classificationValues = [
      { value: 'Married', text: 'Married' },
      { value: 'CoHabitating', text: 'Co-habitating w/ matching ID' },
      { value: 'Single', text: 'Single Female' },
    ]
    return classificationValues.map((v) => {
      return (
        <RadioButton
          key={v}
          value={v.value}
          label={v.text}
          className="radioButton"
          iconStyle={{ display: 'none' }}
          labelStyle={styles.radioLabel}
          style={styles.radio}
        />
      )
    })
  }

  let incomeValue = null
  let classificationName =
    getQualificationsFormData &&
    getQualificationsFormData.values &&
    getQualificationsFormData.values.classification
  if (classificationName === 'CoHabitating') {
    incomeValue = '40k+'
  } else if (classificationName === 'Married') {
    incomeValue = '40k+'
  } else {
    incomeValue = '50k+'
  }

  return (
    qualificationOptions && (
      <div>
        <FormSectionHeader
          className="middle topmargin bottommargin"
          required={true}
        >
          <ClassIcon className="required-input-icon input-icon svg-icon" />
          Classification
        </FormSectionHeader>

        <div className="input radioField" style={{ float: 'none', marginBottom: '25px' }}>
          <Field
            id="classification"
            name="classification"
            component={RadioButtonGroup}
            floatingLabelText="Classification"
            validate={required}
          >
            {renderClassificationValues()}
          </Field>
        </div>

        <hr />
        <FormSectionHeader className="top margin-zero" required={true}>
          <BookmarkIcon className="required-input-icon input-icon svg-icon" />
          Qualifications
        </FormSectionHeader>

        <div className="input checkbox qualification">
          <Field
            id="age"
            name="qualifications.age"
            component={Checkbox}
            label={`Age: ${age} (check to confirm)`}
            validate={required}
          />
        </div>

        <div className="input checkbox qualification">
          <Field
            id="income"
            name="qualifications.income"
            component={Checkbox}
            label={`Income: ${incomeValue} (check to confirm)`}
            validate={required}
          />
        </div>

        {renderPresentationCheck()}
      </div>
    )
  )
}

CardQualification.defaultProps = {
  qualificationOptions: {},
}

CardQualification.propTypes = {
  qualificationOptions: PropTypes.object,
}

const enhance = compose(
  connect((state) => ({
    initialValues: selectQualificationInitValues(state),
    qualificationOptions: selectQualificationOptions(state),
    getQualificationsFormData: selectQualificationsFormData(state),
  })),
  reduxForm({ form: FORM_NAME })
)

export default enhance(CardQualification)
