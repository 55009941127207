import { SET_LOADING, SET_ERRORS, SET_PACKAGE_DETAILS, RESET_PACKAGE_DETAILS } from './actions'

export const INITIAL_STATE = {
  loading: false,
  errors: false,
  packageDetails: {}
}

export default (state = INITIAL_STATE, action) => {
  const { payload } = action

  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: payload.loading
      }

    case SET_ERRORS:
      return {
        ...state,
        errors: payload.errors
      }

    case SET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: {
          ...state.packageDetails,
          ...payload.packageDetails
        }
      }

    case RESET_PACKAGE_DETAILS:
      return {
        ...state,
        packageDetails: INITIAL_STATE.packageDetails
      }
  
    default:
      return state
  }
}