import C from 'config/environment'
import { checkStatus, parseJSON } from './concerns/railsRest'
import Auth from 'models/Auth'

export class LeadController {

  data = {}
  app
  state

  constructor(app) {
    this.app   = app
    this.state = app.state
    this.form_data = app.props.form_data

    // let selectedModels = {
    //   agent: app.state.agent,
    //   accommodation: app.state.accommodation.schema,
    //   billing: app.state.billing.schema,
    //   lead: app.state.lead.schema,
    //   sale: app.state.sale.schema,
    // }

    // let formatted = flattenJSON(selectedModels)
    // formatted.gifts = app.state.gift.parameterize()
    // formatted.note = app.state.note.schema
    // delete formatted.showSpinner

    this.data = {
      lead: {
        ...this.form_data
      }
    }
  }

  create() {
    return fetch(C.API.BASE + '/leads', {
      method: 'POST',
      headers: {
        'access-token': Auth.current().token,
        'expiry': Auth.current().expiry,
        'uid': Auth.current().uid,
        'client': Auth.current().client,
        'token-type': 'Bearer',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(this.data)
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(data => {
      let body             = data.message
      let errors           = data.errors || []
      let validationErrors = data.validationErrors || []
      let title            = data.charge && data.charge.success ?
        'Your lead saved and charged successfully' : 'There were some problems'

      this.state.showSpinner = false
      this.state.dialog = {
        schema: {
          open: true,
          title,
          body,
          errors,
          validationErrors,
          stackTrace: []
        }
      }

      this.app.setState(this.state)
    }).catch(error => {
      let title = 'Sorry to tell you this, but there may be a problem'
      let body = 'You may have forgotten to fill out a required field or entered an invalid value. You can safely check over the form and resubmit again (no charge or record has been saved). If the error persists, please contact AMG for support.'

      let errors = [error]
      let stackTrace = [error.stack]
      let validationErrors = error.validationErrors || []

      this.state.dialog = {
        schema: {
          open: true,
          title,
          body,
          errors,
          validationErrors,
          stackTrace
        }
      }

      this.state.showSpinner = false
      this.app.setState(this.state)

      return error
    })
  }
}

export const getLeads = (state) => {
  return {
    leads: state.leads
  }
}
