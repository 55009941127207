import { persistCombineReducers } from 'redux-persist'
import localForage from 'localforage'

import { alertReducer } from '../controllers/AlertController'
import { reducer as reduxFormReducer } from 'redux-form'
import vsf from './vsf/reducer'
import auth from './auth/reducer'
import _admin from './admin/reducer'
import dialog from './dialog/reducer'
import billing from './billing/reducer'
import { reduxTokenAuthReducer } from 'redux-token-auth'
import { rest } from 'controllers/Rest'

const persistConfig = {
  key: 'amg',
  storage: localForage,
  blacklist: [
    'form',
    'reduxTokenAuth',
    'vsf',
    'billing',
    'dialog',
    '_admin',
    'auth'
  ],
}

export default persistCombineReducers(persistConfig, {
  ...rest.reducers,
  form: reduxFormReducer,
  alerts: alertReducer,
  reduxTokenAuth: reduxTokenAuthReducer,
  vsf,
  auth,
  dialog,
  billing,
  _admin
})
