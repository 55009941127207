import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { compose } from "redux"
import { reduxForm } from "redux-form"

import {
  getLeadBillingData,
  resetLeadForms,
  updateAndChargeLead,
} from "../../../store/billing/actions"
import { selectPackageDataIsLoaded } from "../../../store/billing/selectors"
import { resetDialog } from "../../../store/dialog/actions"

import Spinner from "../../ui/Spinner"
import Upgrade from "../components/Upgrade/index"

const FORM_NAME = "billing.upgrade"

const INITIAL_STATE = {
  leadId: {
    error: "",
    value: "",
  },
  searchingForAddress: false,
  leadDataIsLoading: false,
}

class UpgradeContainer extends Component {
  static propTypes = {
    getLeadBillingData: PropTypes.func.isRequired,
    updateAndChargeLead: PropTypes.func.isRequired,
    resetLeadForms: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resetDialog: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    packageDetails: PropTypes.object,
  }

  state = INITIAL_STATE

  activeLeadId = null

  handleInvoiceChange = e => {
    const { value } = e.target
    let error = value ? "" : "Required"

    if (value) {
      if (!/^\d+$/.test(value)) {
        error = "Expected numbers only"
      } else if (value.length !== 19) {
        error = "Expected 19 digits"
      }
    }

    this.setState({
      leadId: {
        error,
        value,
      },
    })
  }

  handleInvoiceSubmit = async () => {
    const { getLeadBillingData } = this.props
    const { value } = this.state.leadId

    if (value) {
      this.activeLeadId = value
      this.setState({
        leadDataIsLoading: true
      })

      await getLeadBillingData(FORM_NAME, value)

      this.setState({
        ...this.state,
        leadId: INITIAL_STATE.leadId,
        leadDataIsLoading: false,
      })

    } else {
      this.setState({
        leadId: {
          ...this.state.leadId,
          error: "Required",
        },
      })
    }
  }

  handleToggleSearchingForAddress = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        searchingForAddress: !prevState.searchingForAddress,
      }
    })
  }

  handleReset = () => {
    const { resetLeadForms, packageDetails } = this.props

    resetLeadForms(FORM_NAME, packageDetails)
  }

  handleSubmit = values => {
    const { updateAndChargeLead } = this.props

    updateAndChargeLead(FORM_NAME, this.activeLeadId, values)
  }

  handleDialogClose = () => {
    const { resetDialog } = this.props

    resetDialog()
  }

  render() {
    const { leadId, searchingForAddress, leadDataIsLoading } = this.state
    const { loading } = this.props

    return loading ? (
      <Spinner text={leadDataIsLoading ? "Loading, please wait" : "Charging, please wait" } />
    ) : (
      <Upgrade
        {...this.props}
        onInvoiceSubmit={this.handleInvoiceSubmit}
        onInvoiceChange={this.handleInvoiceChange}
        leadId={leadId}
        onToggleSearch={this.handleToggleSearchingForAddress}
        searchingForAddress={searchingForAddress}
        form={FORM_NAME}
        loading={loading}
        onReset={this.handleReset}
        onSubmit={this.handleSubmit}
        onDialogClose={this.handleDialogClose}
      />
    )
  }
}

const enhance = compose(
  connect(
    state => {
      return {
        packageDetails: state.billing.packageDetails,
        loading: state.billing.loading,
        hasPackageData: selectPackageDataIsLoaded(state),
        hasErrors: state.billing.errors,
      }
    },
    {
      getLeadBillingData,
      resetLeadForms,
      updateAndChargeLead,
      resetDialog,
    },
  ),
  reduxForm({ form: FORM_NAME }),
)

export default enhance(UpgradeContainer)
