import React from 'react'
import PropTypes from 'prop-types'
import ReactCreditCards from 'react-credit-cards'
import { Field } from 'redux-form'
import { TextField } from 'redux-form-material-ui'

import { normalizeCardExpiration, normalizeCardNumber, normalizeLetter, normalizeCardCode } from '../../../../helpers/normalizers'
import { validateRequired, validateCardNumber } from '../../../../helpers/validators'

import './index.scss'

const Card = ({
  styles,
  credit_card_number,
  credit_card_name,
  expiration_date,
  cvv,
  focused,
}) => {
  return (
    <div className="BillingCard">
      <div className="BillingCard-fields">
        <Field
          className="BillingCard-field"
          name="credit_card_name"
          component={TextField}
          floatingLabelText="name"
          hintText="John Doe"
          floatingLabelFixed
          floatingLabelStyle={styles.floatingLabelStyle}
          normalize={normalizeLetter}
          validate={[validateRequired]}
        />
        <Field
          className="BillingCard-field"
          name="credit_card_number"
          component={TextField}
          floatingLabelText="number"
          hintText="4242424242424242"
          floatingLabelFixed
          floatingLabelStyle={styles.floatingLabelStyle}
          normalize={normalizeCardNumber}
          validate={[validateRequired, validateCardNumber]}
        />
        <Field
          className="BillingCard-field"
          name="expiration_date"
          component={TextField}
          floatingLabelText="expiry"
          hintText="12/22"
          floatingLabelFixed
          floatingLabelStyle={styles.floatingLabelStyle}
          normalize={normalizeCardExpiration}
          validate={[validateRequired]}
        />
        <Field
          className="BillingCard-field"
          name="cvv"
          component={TextField}
          floatingLabelText="cvc"
          hintText="123"
          floatingLabelFixed
          floatingLabelStyle={styles.floatingLabelStyle}
          normalize={normalizeCardCode}
          validate={[validateRequired]}
        />
      </div>

      <div className="BillingCard-card">
        <ReactCreditCards
          number={credit_card_number || ''}
          name={credit_card_name || ''}
          expiry={expiration_date || ''}
          cvc={cvv || ''}
          focused={focused}
        />
      </div>
   
    </div>
  )
}

Card.defaultProps = {
  credit_card_number: '',
  credit_card_name: '',
  expiration_date: '',
  cvv: '',
}

Card.propTypes = {
  styles: PropTypes.object.isRequired,
  credit_card_number: PropTypes.string,
  credit_card_name: PropTypes.string,
  expiration_date: PropTypes.string,
  cvv: PropTypes.string,
  focused: PropTypes.string
}

export default Card
