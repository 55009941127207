/* eslint-disable  */
import React from 'react'
import PropTypes from 'prop-types'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { compose } from 'redux'

import InfoIcon from 'material-ui/svg-icons/action/info'

import styles from 'views/ui/RadioButtonStyles'
import GiftsView from 'views/vsf/views/Gifts/GiftsView'

import ModalGiftInfo from '../ModalGiftInfo'
import { selectGifts } from '../../../store/vsf/gifts/selectors'
import { selectActiveTravelDestinationId } from '../../../store/vsf/travel/selectors'
import {
  toggleDialog,
  toggleGift,
  selectGift,
} from '../../../store/vsf/gifts/actions'
import { selectId } from '../../../store/auth/selectors'

class GiftsContainer extends React.Component {
  renderDefaultGifts(gifts, locationId) {
    const { toggleGift, selectGift } = this.props

    return (
      <div>
        <h3 className="header additional">Default Gifts</h3>

        <GiftsView
          gifts={gifts}
          locationId={locationId}
          toggleGift={toggleGift}
          selectGift={selectGift}
        />
      </div>
    )
  }

  renderAdditionalGifts(gifts, locationId) {
    const { toggleGift, selectGift } = this.props

    return (
      <div>
        <h3 className="header additional">Additional Gifts</h3>

        <GiftsView
          gifts={gifts}
          locationId={locationId}
          toggleGift={toggleGift}
          selectGift={selectGift}
        />
      </div>
    )
  }

  renderCloserSpecials(gifts, locationId) {
    const { toggleGift, selectGift, toggleDialog } = this.props

    return (
      <div>
        <h3 className="header closer">
          Closer Specials
          <InfoIcon
            className="icon"
            style={{
              fill: 'rgba(0,0,0,0.4)',
              width: 20,
              height: 20,
              cursor: 'pointer',
            }}
            fill="rgba(0,0,0,0.4)"
            hoverColor="#444"
            onClick={toggleDialog}
          />
        </h3>

        <GiftsView
          gifts={gifts}
          locationId={locationId}
          toggleGift={toggleGift}
          selectGift={selectGift}
        />
      </div>
    )
  }

  renderPremiumGifts(gifts, locationId) {
    const { toggleGift, selectGift } = this.props

    return (
      <div>
        <h3 className="header premium">Premium Gifts</h3>

        <GiftsView
          gifts={gifts}
          locationId={locationId}
          toggleGift={toggleGift}
          selectGift={selectGift}
        />
      </div>
    )
  }

  render() {
    const {
      gifts,
      selectGift,
      toggleGift,
      toggleDialog,
      dialogOpen,
      id,
      locationId,
    } = this.props
    if (locationId && gifts) {
      const { default: defaultGifts, additional, closer, premium } = gifts
      return (
        <div className="gift-items" style={styles.paddingLeft2}>
          <ModalGiftInfo hideDialog={toggleDialog} open={dialogOpen} id={id} />

          <div className="column two third">
            {gifts.default && this.renderDefaultGifts(defaultGifts, locationId)}

            {gifts.additional &&
              this.renderAdditionalGifts(additional, locationId)}
          </div>

          <div className="column one third">
            {gifts.closer && this.renderCloserSpecials(closer, locationId)}
            {gifts.premium && this.renderPremiumGifts(premium, locationId)}
          </div>
        </div>
      )
    } else {
      return (
        <h3 className="header additional">
          Select a travel destination to view gifts
        </h3>
      )
    }
  }
}

GiftsContainer.propTypes = {
  sale: PropTypes.object,
  gift: PropTypes.object,
  package_price: PropTypes.number,
  containingForm: PropTypes.string,
  id: PropTypes.string,
  locationId: PropTypes.number,
  gifts: PropTypes.object,
  toggleDialog: PropTypes.func.isRequired,
  toggleGift: PropTypes.func.isRequired,
  selectGift: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
}

const enhance = compose(
  connect(
    (state, { containingForm }) => {
      return {
        dialogOpen: state.vsf.gifts.dialogOpen,
        package_price: parseInt(
          formValueSelector('wizard.sale')(state, 'package_price'),
          10
        ),
        gifts: selectGifts(state),
        id: selectId(state),
        locationId: selectActiveTravelDestinationId(state),
      }
    },
    {
      toggleDialog,
      toggleGift,
      selectGift,
    }
  )
)

export default enhance(GiftsContainer)
