import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon'

const StatsIcon = ({ color, viewBox, ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <g fill={color}>
      <rect x="0.882352941" y="12.0923077" width="3.52941176" height="8.49230769"></rect>
      <rect x="9.17647059" y="9.26153846" width="3.52941176" height="11.5"></rect>
      <rect x="17.2941176" y="4.66153846" width="3.52941176" height="16.1"></rect>
      <rect x="25.5882353" y="0.238461538" width="3.52941176" height="20.5230769"></rect>
    </g>
  </SvgIcon>
)

StatsIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

StatsIcon.defaultProps = {
  color: "rgba(0,0,0,0.7)",
  viewBox: "0 0 30 21",
}

export default StatsIcon
