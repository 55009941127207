import React from 'react'
import PropTypes from 'prop-types'

class FormSectionHeader extends React.Component {

  render = () => {
    const { children, className, required, ...rest } = this.props

    return (
      <label className={`required-radio-group-label radioField standalone ${className || ''}`} {...rest}>
        { children }
        { required ? this.renderRequired(required) : null}
      </label>
    )
  }

  renderRequired = ( required ) => (
    required ? <em className="required required-icon">*</em> : null
  )
}

FormSectionHeader.propTypes = {
  className:  PropTypes.string,
  children:   PropTypes.any,
  required:   PropTypes.bool
}

export default FormSectionHeader