import 'whatwg-fetch'
import Promise from 'promise-polyfill'

if (!window.Promise)
  window.Promise = Promise

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response
  } else if (response.status === 401) {
    let error = new Error("Login Credentials are invalid")
    error.response = response
    throw error
  } else {
    let error = new Error(response.statusText)
    error.response = response
    throw error
  }
}

export const parseJSON = (response) => {
  return response.json()
}

export const flattenJSON = (data)  => {
  var result = {}

  const recurse = (cur, prop) => {
    if (prop === 'date_sold')
      cur = cur.toString()

    if (Object(cur) !== cur) {
      result[prop] = cur
    } else {
      var isEmpty = true

      for (var p in cur) {
        if (Object.prototype.hasOwnProperty.call(cur, p)) {
          isEmpty = false
          recurse(cur[p], p)
        }
      }

      if (isEmpty)
        result[prop] = {}
    }
  }

  recurse(data, "")

  return result
}
