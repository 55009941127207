import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PersonIcon from 'material-ui-icons/Person'
import { TextField } from 'redux-form-material-ui'
import { validateRequired } from 'helpers/validators'
import { normalizeCapital } from 'helpers/normalizers'
import FieldLabel from 'views/ui/FieldLabel'

import { textErrorStyle } from 'views/ui/styles/styles'

const NameField = ({ label, slug, hintText, required }) => {
  return (
    <div className="input radioField input-style">
      <FieldLabel
        icon={<PersonIcon className="required-input-icon input-icon svg-icon" />}
        label={label || 'Name'}
        required={required}
      />

      <Field
        className="full-width"
        id={slug}
        name={slug}
        component={TextField}
        hintText={hintText || label}
        errorStyle={textErrorStyle}
        normalize={normalizeCapital}
        validate={validateRequired}
      />
    </div>
  )
}


NameField.propTypes = {
  label:            PropTypes.string,
  slug:             PropTypes.string.isRequired,
  hintText:         PropTypes.string,
  required:         PropTypes.bool
}

export default NameField
