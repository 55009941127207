import React, { Component } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import PropTypes from "prop-types"
import {
  arrayPush,
  arrayRemove,
  formValueSelector,
  change,
  reset as resetForm,
  reduxForm,
} from "redux-form"

import { selectFilteredUserOptions } from "../../../store/admin/user/selectors"
import { resetDialog } from "../../../store/dialog/actions"
import {
  updateActiveLocation,
  createUser,
  getUserPreset,
  removeUserPreset,
  initializeUserForm,
  editUser,
  updateUser,
  resetPresets,
} from "../../../store/admin/user/actions"

export default ({ form }) => WrappedComponent => {
  class WithUserForm extends Component {
    static propTypes = {
      activeLocation: PropTypes.object,
      canAccessSales: PropTypes.bool,
      updateActiveLocation: PropTypes.func.isRequired,
      arrayRemove: PropTypes.func.isRequired,
      arrayPush: PropTypes.func.isRequired,
      getUserPreset: PropTypes.func.isRequired,
      removeUserPreset: PropTypes.func.isRequired,
      initializeUserForm: PropTypes.func.isRequired,
      change: PropTypes.func.isRequired,
      createUser: PropTypes.func.isRequired,
      editUser: PropTypes.func.isRequired,
      updateUser: PropTypes.func.isRequired,
      resetDialog: PropTypes.func.isRequired,
      resetForm: PropTypes.func.isRequired,
      resetPresets: PropTypes.func.isRequired,
      los: PropTypes.array,
      ttim: PropTypes.array,
      locations: PropTypes.array,
      userPresets: PropTypes.array,
      options: PropTypes.object.isRequired,
      password: PropTypes.string,
      editing: PropTypes.string,
    }

    static defaultProps = {
      editing: false
    }

    componentDidMount () {
      const { editing, getUserPreset } = this.props
      this.handleReset()

      setTimeout(() => {
        if (editing) {
          if (form === 'admin.edit') {
            getUserPreset(form, editing, true)
          } else {
            this.handleReset()
          }
        }
      }, 0)
    }

    componentWillUnmount() {
      const { editing, editUser } = this.props

      this.handleReset()

      if (editing) {
        editUser(null)
      }
    }

    handleDeleteChipInput = (field, index, id, value) => {
      const {
        activeLocation,
        updateActiveLocation,
        arrayRemove,
        change,
        removeUserPreset,
      } = this.props

      arrayRemove(form, field, index)

      if (field === "locations") {
        change(form, `gifts.locationId:${id}`, null)

        if (id === activeLocation.id) {
          updateActiveLocation({
            label: "",
            id: null,
          })
        }
      }

      if (field === "presets") {
        removeUserPreset(form, value)
      }
    }

    handleAddChipInput = (option, field) => {
      const {
        options,
        arrayPush,
        updateActiveLocation,
        getUserPreset,
      } = this.props

      if (options[field].find(({ value }) => value === option.value)) {
        arrayPush(form, field, option)
        if (field === "locations") {
          updateActiveLocation({
            id: option.value,
            label: option.label,
          })
        }
      }

      if (field === "presets") {
        getUserPreset(form, option.value)
      }
    }

    handleLocationChipClick = (e, field, { label, value }) => {
      const { updateActiveLocation } = this.props
      e.stopPropagation()

      updateActiveLocation({
        id: value,
        label,
      })
    }

    handleEditUserChange = (uid) => {
      const { getUserPreset, editUser, resetForm, initializeUserForm } = this.props

      resetForm(form)
      initializeUserForm(form)
      editUser(uid)
      getUserPreset(form, uid, true)
    }

    handleDialogClose = () => {
      const { resetDialog } = this.props

      resetDialog()
    }

    handleSubmit = async () => {
      const { createUser, updateUser, editing } = this.props

      if (editing) {
        updateUser(form, editing)
      } else {
        try {
          await createUser(form)
          this.handleReset()
        } catch (error) {
          // eslint-disable-next-line
          console.error(error)
        }
      }
    }

    handleReset = (_, dialog) => {
      const {
        updateActiveLocation,
        initializeUserForm,
        resetForm,
        editing,
        getUserPreset,
        editUser,
        resetPresets,
      } = this.props

      resetPresets()
      updateActiveLocation({ id: null, value: "" })
      resetForm(form)
      initializeUserForm(form)

      if (editing && !dialog) {
        getUserPreset(form, editing, true)
      }

      if (dialog) {
        editUser(null)

        if (form !== 'admin.create') {
          this.handleDialogClose()
        }
      }
    }

    render() {
      return (
        <WrappedComponent
          form={form}
          onLocationChipClick={this.handleLocationChipClick}
          onAddChip={this.handleAddChipInput}
          onDeleteChip={this.handleDeleteChipInput}
          onSubmit={this.handleSubmit}
          onEditUserChange={this.handleEditUserChange}
          onDialogClose={this.handleDialogClose}
          onReset={this.handleReset}
          {...this.props}
        />
      )
    }
  }

  return compose(
    reduxForm({ form, validate }),
    connect(
      state => {
        const formType = form.split('.')[1]
        return {
          options: selectFilteredUserOptions(state),
          los: formValueSelector(form)(state, "los"),
          ttim: formValueSelector(form)(state, "ttim"),
          locations: formValueSelector(form)(state, "locations"),
          password: formValueSelector(form)(state, "password"),
          userPresets: formValueSelector(form)(state, "presets"),
          activeLocation: state._admin.user.activeLocation,
          canAccessSales: formValueSelector(form)(state, "can_access_sales"),
          errors: state.form.admin[formType].syncErrors,
          loading: state._admin.user.loading,
          editing: state._admin.user.editing
        }
      },
      {
        updateActiveLocation,
        arrayPush,
        arrayRemove,
        createUser,
        change,
        getUserPreset,
        removeUserPreset,
        resetDialog,
        resetForm,
        initializeUserForm,
        editUser,
        updateUser,
        resetPresets,
      },
    ),
  )(WithUserForm)

  function validate(values) {
    let errors = {}

    if (form === 'admin.create' || values.password || values.confirmPassword) {
      if (
        values['password'] &&
        values['password'] &&
        values['password'].length < 4 &&
        values['password'].length > 0
      ) {
        errors['password'] = "Minimum length of 4"
      }

      if (values.password && values.confirmPassword) {
        if (values.password !== values.confirmPassword) {
          errors.confirmPassword = "Passwords do not match"
        }
      }
    }

    if (!values['name']) {
      errors['name'] = "Required"
    }

    if (values.can_access_sales) {
      [
        "card",
        "ttim",
        "los",
        "locations",
      ].forEach(fieldName => {
        if (
          Array.isArray(values[fieldName])
            ? values[fieldName].length < 1
            : !values[fieldName]
        ) {
          errors[fieldName] = "Required"
        }
      })
    }

    if (
      values.locations &&
      values.locations.length > 0 &&
      values.gifts &&
      Object.keys(values.gifts).length > 0 &&
      values.giftOptions &&
      values.giftOptions.values
    ) {
      Object.entries(values.giftOptions.values).forEach(([locationId, gifts]) => {
        Object.entries(gifts).forEach(([giftId, options]) => {
          if (
            values.gifts[locationId] &&
            values.gifts[locationId][giftId] &&
            Object.values(options).every(val => !val)
          ) {
            if (!errors.gifts) {
              errors.gifts = {
                [locationId]: {
                  [giftId]: "At least one selection is required",
                },
              }
            } else if (errors.gifts && !errors.gifts[locationId]) {
              errors = {
                ...errors,
                gifts: {
                  ...errors.gifts,
                  [locationId]: {
                    [giftId]: "At least one selection is required",
                  },
                },
              }
            } else if (
              errors.gifts &&
              errors.gifts[locationId] &&
              !errors.gifts[locationId][giftId]
            ) {
              errors = {
                ...errors,
                gifts: {
                  ...errors.gifts,
                  [locationId]: {
                    ...errors.gifts[locationId],
                    [giftId]: "At least one selection is required",
                  },
                },
              }
            }
          }
        })
      })
    }
    return errors
  }
}


