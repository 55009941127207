import { reset } from 'redux-form'

import { setStepAction, getUserData } from '../vsf/actions'

// ACTIONS
export const LOGOUT = 'auth/LOGOUT'
export const SET_AUTH_VALUE = 'auth/SET_AUTH_VALUE'
export const REMOVE_AUTH_VALUE = 'auth/REMOVE_AUTH_VALUE'
export const SET_ERROR = 'auth/SET_ERROR'

// ACTION CREATORS
const logoutAction = () => ({
  type: LOGOUT,
  payload: null,
})

// key is one of: 'access-token' | 'client' | 'expiry' | 'token-type' | 'uid' | 'id' | 'pin'
export const setAuthValueAction = (key, value) => ({
  type: SET_AUTH_VALUE,
  payload: {
    key,
    value,
  },
})

// key is one of: 'access-token' | 'client' | 'expiry' | 'token-type' | 'uid' | 'id' | 'pin'
export const removeAuthValueAction = (key) => ({
  type: REMOVE_AUTH_VALUE,
  payload: {
    key,
  },
})

export const setErrorAction = (message) => ({
  type: SET_ERROR,
  payload: {
    message
  },
})

// BOUND ACTION CREATORS
export const login = (history, form, { username, password }, signInUser) => async (dispatch, getState) => {
  try {
    const uppUsername = username.toUpperCase()
    localStorage.clear()

    await signInUser({ email: `${username}@amg.com`, password })(dispatch, getState)
    localStorage.setItem('id', uppUsername),
    localStorage.setItem('pin', password),

    dispatch(setAuthValueAction('id', uppUsername))
    dispatch(setAuthValueAction('pin', password))

    dispatch(reset(form))

    dispatch(setErrorAction(''))

    dispatch(getUserData(uppUsername)).then(() => {
      let route = '/dashboard/'
      let permissions = getState().vsf.permissions
      if (permissions.canAccessSales) {
        route += 'sales'
      } else if (permissions.canAccessBilling) {
        route += 'billing'
      } else if (permissions.canAccessLeads) {
        route += 'leads'
      } else if (permissions.canAccessAdmin) {
        route += 'admin'
      } else if (permissions.canAccessMail) {
        route += 'mail'
      } else if (permissions.canAccessPrint) {
        route += 'print'
      } else if (permissions.canAccessStamps) {
        route += 'stamps'
      } else if (permissions.canAccessCaptureAndVoid) {
        route += 'capture-&-void'
      } else if (permissions.canAccessRefunds) {
        route += 'refund'
      } else if (permissions.canAccessScrubber) {
        route += 'scrubber'
      } else if (permissions.canAccessKitchen) {
        route += 'kitchen'
      } else if (permissions.canAccessStats) {
        route += 'stats'
      } else if (permissions.canAccessSupport) {
        route += 'support'
      }
      history.push(route)
    })
    //dispatch(getUserData(uppUsername))
      } catch (error) {
    dispatch(setErrorAction('Location ID or Pin is incorrect.'))
    dispatch(reset(form))
  }
}

export const logout = (history, signOutUser) => async (dispatch, getState) => {
  try {
    await signOutUser()(dispatch, getState)
    dispatch(setStepAction("1"))
    localStorage.clear()

    dispatch(logoutAction())

    history.push('/')
  } catch (error) {
    throw error
  }
}
