import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PinDropIcon from 'material-ui-icons/PinDrop'
import { textErrorStyle } from 'views/ui/styles/styles'
import { validateRequired } from 'helpers/validators'
import { normalizeUpper } from 'helpers/normalizers'
import FieldLabel from 'views/ui/FieldLabel'
import { AutoComplete } from 'redux-form-material-ui'
import { AutoComplete as MUIAutoComplete } from 'material-ui'
import { statesList } from 'constants/states'

const BillingStateField = () => (
  <div className="input radioField input-style">

    <FieldLabel
      icon={<PinDropIcon className="required-input-icon input-icon svg-icon" />}
      label="Billing State"
      required={true}
    />

    <Field
      id="billing_state"
      name="billing.billing_state"
      component={AutoComplete}
      hintText="State"
      errorStyle={textErrorStyle}
      openOnFocus={true}
      dataSource={statesList}
      filter={MUIAutoComplete.fuzzyFilter}
      normalize={normalizeUpper}
      validate={validateRequired}
    />
  </div>
)


BillingStateField.propTypes = {}

export default BillingStateField