import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from 'material-ui/CircularProgress'
import Chip from 'material-ui/Chip'
import ChipInput from 'material-ui-chip-input'

import { red200 } from 'material-ui/styles/colors'

import OptionLabel from '../../containers/OptionLabel'

const ChipInputOptions = ({
  allOptions,
  options,
  onLocationChipClick,
  onDeleteChip,
  onAddChip,
  name,
  label,
  icon,
  fullWidth,
  activeLocation,
  loading,
  formType,
}) => {
  return (
    <div className={`option-group ${fullWidth ? 'full-width' : ''}`}>
      <OptionLabel label={label} icon={icon} name={name} formType={formType}>
        {name === 'presets' && loading
          ? <CircularProgress thickness={2} size={20} style={{ marginLeft: 20 }}/>
          : null
        }
      </OptionLabel>
      <ChipInput
        disabled={loading}
        className="option-field-container"
        fullWidth
        menuStyle={{
          maxHeight: 200
        }}
        openOnFocus
        dataSource={allOptions}
        value={options}
        dataSourceConfig={{ text: 'label', value: 'value' }}
        hintText={label}
        underlineShow={false}
        onRequestDelete={(id, index) => {
          onDeleteChip(name, index, name === 'locations' && id, options[index].value)
        }}
        onRequestAdd={(option) => {
          onAddChip(option, name)
        }}
        chipRenderer={({
          text,
          value,
          isFocused,
          isDisabled,
          handleRequestDelete,
          defaultStyle
        }, key) => {
          return (
            <Chip
              key={key}
              style={{
                ...defaultStyle,
                pointerEvents: isDisabled ? 'none' : undefined,
              }}
              backgroundColor={
                activeLocation && activeLocation.id === value ||
                isFocused ? red200 : 'rgb(224, 224, 224)'
              }
              onClick={(e) => {
                if (onLocationChipClick) onLocationChipClick(e, name, { label: text, value })
              }}
              onRequestDelete={handleRequestDelete}
            >
              {text}
            </Chip>
          )
        }}
      />
    </div>
  )
}

ChipInputOptions.defaultProps = {
  fullWidth: false,
  options: [],
  loading: false,
}

ChipInputOptions.propTypes = {
  activeLocation: PropTypes.object,
  allOptions: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object),
  onLocationChipClick: PropTypes.func,
  onAddChip: PropTypes.func.isRequired,
  onDeleteChip: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formType: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
}

export default ChipInputOptions
