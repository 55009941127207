
/* eslint-disable  */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { change, formValueSelector } from 'redux-form'

import { selectFilteredUserOptions } from '../../../store/admin/user/selectors'

import GiftHelpers from '../components/GiftHelpers/index'

class GiftHelpersContainer extends Component {
  state = {
    selectAll: false,
    selectFrom: ''
  }

  static propTypes = {
    activeLocation: PropTypes.object,
    formValues: PropTypes.object,
    gifts: PropTypes.array,
    form: PropTypes.string.isRequired,
    change: PropTypes.func,
  }

  componentWillReceiveProps(nextProps) {
    const { activeLocation, formValues } = this.props
    const {
      activeLocation: nextActiveLocation,
      formValues: nextFormValues,
      gifts: nextGifts
    } = nextProps

    if (activeLocation !== nextActiveLocation) {
      this.setState({
        ...this.state,
        selectAll: false
      })
    }

    if (formValues !== nextFormValues && nextFormValues) {
      const nextValues = Object.values(nextFormValues)
      const allSelected = nextValues.every((item) => item) &&
        nextGifts.length === nextValues
          .filter(Boolean).length
      const allDeSelected = nextValues.every((item) => !item)

      if (allSelected) {
        this.setState({
          selectAll: true
        })
      } else if (allDeSelected) {
        this.setState({
          selectAll: false
        })
      }
    }
  }
  

  handleSelectAllToggle = () => {
    const { selectAll } = this.state
    const { gifts, form, change, activeLocation } = this.props
    const field = `gifts.locationId:${activeLocation.id}`
    if (selectAll) {
      change(form, field, null)
    } else {
      gifts.forEach(({ id }) => {
        change(form, `${field}.giftId:${id}`, true)
      })
    }

    this.setState((prevState) => {
      return {
        selectAll: !prevState.selectAll
      }
    })
  }

  render () {
    const { selectAll } = this.state
    return (
      <GiftHelpers
        selectAll={selectAll}
        onSelectAllToggle={this.handleSelectAllToggle}
      />
    )
  }
}

export default connect((state, { form }) => {
  const activeLocation = state._admin.user.activeLocation
  return {
    activeLocation,
    gifts: selectFilteredUserOptions(state).gifts,
    formValues: formValueSelector(form)(state, `gifts.locationId:${activeLocation.id}`)
  }
}, { change })(GiftHelpersContainer)