import React from 'react'
import PropTypes from 'prop-types'
// Icons
import BookmarkIcon from 'material-ui/svg-icons/action/bookmark'
import ConfirmationField from './ConfirmationField'

function incomeFieldText(classification) {
  let income = null
  if (classification === 'CoHabitating') {
    income = '40k+'
  } else if (classification === 'Married') {
    income = '40k+'
  } else {
    income = '50k+'
  }
  return `Income: $${income}`
}

const ConfirmationQualificationFields = ({ form_data, errorColor }) => {
  const { qualifications } = form_data.qualification.values
  let hasErrors = !qualifications

  if (!hasErrors) {
    const values = Object.values(qualifications)

    if (values.length !== 3) hasErrors = true
    else hasErrors = !values.every((val) => val)
  }

  return (
    <div className="card-group">
      <div className="card-header-container">
        <BookmarkIcon color={hasErrors ? errorColor : '#7a7a7a'} />
        <h3 className={`card-header ${hasErrors ? 'error' : ''}`}>
          Qualification
        </h3>
      </div>

      <div className="confirmation-fields">
        <ConfirmationField
          label="Age: 25+"
          error={
            !qualifications || (qualifications && !qualifications.age)
              ? 'Required'
              : null
          }
        />
        <ConfirmationField
          label={incomeFieldText(form_data.qualification.values.classification)}
          error={
            !qualifications || (qualifications && !qualifications.income)
              ? 'Required'
              : null
          }
        />
        <ConfirmationField
          label="Guest agrees to attend 60-90 minute presentation"
          error={
            !qualifications || (qualifications && !qualifications.presentation)
              ? 'Required'
              : null
          }
        />
      </div>
    </div>
  )
}

ConfirmationQualificationFields.propTypes = {
  form_data: PropTypes.object.isRequired,
  errorColor: PropTypes.string.isRequired,
}

export default ConfirmationQualificationFields
