import React from 'react'

import Spinner from '../ui/Spinner'

export default (Component) => {
  return class Loadable extends React.Component {

    state = {
      isLoading: false
    }

    setIsLoading(isLoading) {
      this.setState({ isLoading })
    }

    loading(func) {
      this.setIsLoading(true)
      func(this.setIsLoading.bind(this, false))
    }

    render() {
      return (
        <Component
          loading={this.loading.bind(this)}
          {...this.props}
          {...this.state}
        >
          {this.state.isLoading ? <Spinner /> : null}
        </Component>
      )
    }
  }
}
