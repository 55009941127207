/* eslint-disable  */
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
// import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
// Icons
import AttachMoney from 'material-ui-icons/AttachMoney'

import FormSectionHeader from 'views/ui/FormSectionHeader'
import DateSoldField from 'views/vsf/views/Sales/DateSoldField'
import PackagePriceField from 'views/vsf/views/Sales/PackagePriceField'
import SalesAgentField from 'views/vsf/views/Sales/SalesAgentField'

// Selectors
import { selectSalesInitValues } from '../../store/vsf/sales/selectors'

export const FORM_NAME = "wizard.sale"

const CardSale = () => {
  return (
    <div>
      <FormSectionHeader className="margin-zero top">
        <AttachMoney className="required-input-icon input-icon svg-icon" />
        Sale Information
      </FormSectionHeader>

      <div className="sales">
        <DateSoldField />
        <PackagePriceField />
        <SalesAgentField />

        <hr />
      </div>
    </div>
  )
}

CardSale.propTypes = {}

const enhance = compose(
  connect((state) => {
    return {
      initialValues: selectSalesInitValues(state)
    }
  }),
  reduxForm({ form: FORM_NAME }),
)
export default enhance(CardSale)
