import React from 'react'
import PropTypes from 'prop-types'
import RaisedButton from 'material-ui/RaisedButton'
import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'
import { ActionCable } from 'react-actioncable-provider'
import icons from 'views/ui/icons'
import StampsList from "views/dashboard/stamps/StampsList"
import { CSV_FIELD_MAPPINGS } from '../stamps/CsvFieldMappings'
import { CSV_FIELD_MAPPINGS as PRINT_CSV_FIELD_MAPPINGS } from '../print/CsvFieldMappings'
import writeToCSV from 'modules/CsvWriter'

const NOT_STARTED      = 0
const SYNCING          = 1
const FINISHED_SYNCING = 2

class Stamps extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    loading:  PropTypes.func,
    tasks:    PropTypes.array,
    children: PropTypes.element,
    route:    PropTypes.object,
  }

  state = {
    fetch_message: "",
    startPrinting: false,
    syncingState:  NOT_STARTED,
    updateTasks:   true,
    tasks:         [],
    filterCountry: 'All',
    startExportingToCSV: false,
    tasksToPrint: []
  }

  constructor(props) {
    super(props)

    window.document.title = props.route.title
  }

  onConnected() {
    this.fetchTasks()
  }

  onReceived(message) {
    if (this.state.syncingState != FINISHED_SYNCING) {
      if (message.hasOwnProperty('syncing_state')) {
        this.setState({
          syncingState: message.syncing_state
        })
      }
    }

    if (message.hasOwnProperty('tasks') && this.state.updateTasks == true) {
      this.handleReceivingTasks(message)
    }

    if (message.hasOwnProperty('fetch_message')) {
      this.handleReceivingFetchMessage(message)
    }
  }

  handleReceivingFetchMessage = (message) => {
    this.setState({ fetch_message: message.fetch_message })
  }

  fetchForCSVGenerate = () => {
    const { loading } = this.props

    loading((done) => {
      this.setState({startExportingToCSV: true}, () => {
        let tasksToPrint = this.state.tasks.map(function(task) {
          return task['task_id']
        })

        this.setState({ tasksToPrint: tasksToPrint })

        this.refs.stampsChannel.perform('fetch_for_csv_generate', {
          status: 'Not Started',
          sync: true,
          filter_country: this.state.filterCountry
        })
      })
      done()
    })
  }

  handleReceivingTasks = (tasks) => {
    const { loading } = this.props

    loading(done => {
      this.setTasks(tasks)
      this.setState({
        syncingState: FINISHED_SYNCING
      })

      done()
    })
  }

  setTasks = (possibleTasks) => {
    if (this.state.startExportingToCSV && possibleTasks.for_csv) {
      if (this.state.filterCountry === 'Canada') {
        let csvTasks = possibleTasks.tasks.reduce((result, task) => {
          var newTask = {}

          for (var key in task) {
            if (key in PRINT_CSV_FIELD_MAPPINGS) {
              newTask[PRINT_CSV_FIELD_MAPPINGS[key]] = task[key]

              if (task[key] == "NULL" || task[key] == "null") {
                newTask[PRINT_CSV_FIELD_MAPPINGS[key]] = ""
              }
            }
          }

          result.push(newTask)
          return result
        }, [])

        let headers = Object.values(PRINT_CSV_FIELD_MAPPINGS)
        writeToCSV('print', 'print', headers, csvTasks)
      } else {
        let headers = this.headersForCSVButton(possibleTasks.tasks)
        let csvTasks = this.dataForCSV(possibleTasks.tasks)
        writeToCSV('stamps', 'stamps', headers, csvTasks)
      }

      this.refs.stampsChannel.perform('update_tasks_for_print', {
        task_ids: this.state.tasksToPrint
      })


      this.setState({
        syncingState: FINISHED_SYNCING,
        fetch_message: "Wrote to CSV file",
        startExportingToCSV: false,
        tasksToPrint: []
      })
      return
    }


    this.setState({
      startPrinting: false,
      updateTasks: true,
      fetch_message: '',
      tasks: possibleTasks.tasks
    })
  }

  fetchTasksForCSVGenerate = () => {
    const { loading } = this.props

    this.setState({ syncingState: SYNCING, printButtonLabel: '' }, () => {
      loading(done => {
        this.setState({ tasks: [] })
        this.refs.stampsChannel.perform('fetch_for_csv_generate', {
          filter_country: this.state.filterCountry
        })
        done()
      })
    })
  }

  fetchTasks = () => {

    const { loading } = this.props
    this.setState({ syncingState: SYNCING, printButtonLabel: '' }, () => {
      loading(done => {
        console.log(this.state.filterCountry) //eslint-disable-line
        this.refs.stampsChannel.perform('fetch', {
          filter_country: this.state.filterCountry
        })
        done()
      })
    })
  }

  handleFilterAddressOnChange = (event, index, value) => {
    this.setState({ filterCountry: value }, () => {
      this.fetchTasks()
    })
  }


  enableButton = ({ tasks } = this.props) => {
    return this.state.syncingState != FINISHED_SYNCING || (tasks && tasks.length == 0)
  }

  markAsCompleted = (task_id) => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(() => {
        this.refs.stampsChannel.perform('mark_as_completed',{
          task: { task_id: task_id, status: 'Completed' }
        })
        //this.fetchTasksForCSVGenerate()
        this.fetchTasks()
      })
    ]).then(done))
  }

  render = () => {
    return (
      <div>
        <div className="content tasks">
          <ActionCable
            ref='stampsChannel'
            channel={{channel: 'StampsChannel'}}
            onConnected={() => this.onConnected()}
            onReceived={(data) => this.onReceived(data)}
          />

          {this.props.children}
          <StampsList tasks={this.state.tasks} markAsCompleted={this.markAsCompleted} />
        </div>

        {this.renderBottomNav()}
      </div>
    )
  }

  headersForCSVButton = () => {
    return Object.keys(this.state.tasks[0]).reduce((result, key) => {
      if (key in CSV_FIELD_MAPPINGS) {
        result.push(CSV_FIELD_MAPPINGS[key])

        return result
      }

      return result
    }, [])
  }

  dataForCSV = (tasks) => {
    return tasks.reduce((result, task) => {
      var newTask = {}

      for (var key in task) {
        if (key in CSV_FIELD_MAPPINGS) {
          newTask[CSV_FIELD_MAPPINGS[key]] = task[key]

          if (task[key] == "NULL" || task[key] == "null") {
            newTask[CSV_FIELD_MAPPINGS[key]] = ""
          }
        }
      }

      result.push(newTask)
      return result
    }, [])
  }

  showFilter = () => {
    return this.state.syncingState != FINISHED_SYNCING  ||
      this.state.startPrinting
  }

  renderBottomNav = () => {
    return (
      <div className="toolbar">
        <div className="filter">
          <RaisedButton
            label="Reload Stamps Tasks"
            primary={true}
            onClick={this.fetchTasks}
            disabled={this.enableButton()}
            icon={<icons.reload color="white" viewBox="0 0 140 140" />}
          />
        </div>
        <DropDownMenu
          value={this.state.filterCountry}
          onChange={this.handleFilterAddressOnChange}
          disabled={this.showFilter()}
        >
          <MenuItem value="US" primaryText="US Mail" />
          <MenuItem value="Canada" primaryText="Canada Mail" />
          <MenuItem value="All" primaryText="All Mail" />
        </DropDownMenu>


        <div className="count">{this.state.fetch_message}</div>

        {this.renderActions()}
      </div>
    )
  }

  renderActions = () => (
    <div className="actions">
      <RaisedButton
        label="Generate CSV"
        primary={true}
        onClick={this.fetchForCSVGenerate}
        disabled={this.enableButton() || !this.state.tasks || this.state.tasks.length === 0}
        icon={<icons.stamps color="white" viewBox="0 0 140 140" />}
      />
    </div>
  )
}

export default Stamps
