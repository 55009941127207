import React from 'react'
import { Field } from 'redux-form'
import MenuItem from 'material-ui/MenuItem'
import { SelectField } from 'redux-form-material-ui'
import PropTypes from 'prop-types'
import CircularProgress from 'material-ui/CircularProgress'

import OptionLabel from '../../containers/OptionLabel'

const UserMenu = ({
  allOptions,
  name,
  label,
  icon,
  fullWidth,
  loading,
  onChange,
  formType,
}) => {
  return (
    <div className={`option-group ${fullWidth ? 'full-width' : ''}`}>
      <OptionLabel label={label} icon={icon} name={name} formType={formType}>
        {name === 'presets' && loading
          ? <CircularProgress thickness={2} size={20} style={{ marginLeft: 20 }}/>
          : null
        }
      </OptionLabel>
      <Field
        name="uid"
        style={{
          marginLeft: 20
        }}
        component={SelectField}
        hintText="Select a user"
        onChange={(_, uid) => {
          onChange && onChange(uid)
        }}
      >
        {allOptions.map(({ label, value }) => (
          <MenuItem key={value} value={value} primaryText={label} />
        ))}
      </Field>
    </div>
  )
}

UserMenu.defaultProps = {
  fullWidth: false,
  loading: false,
}

UserMenu.propTypes = {
  allOptions: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  formType: PropTypes.string.isRequired,
}

export default UserMenu