import React from 'react'
import PropTypes from 'prop-types'

import Dialog from 'material-ui/Dialog'
import Button from 'views/ui/Button'

class DeleteUserModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    resetDialog: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    name: PropTypes.string,
  }

  render() {
    return (
      <Dialog
        title="Are you sure you want to delete the User? This cannot be undone"
        actions={[
          <Button key={'Cancel'} label="Cancel" className="basic" onClick={this.props.resetDialog} />,
          <Button
            key={"Delete User"}
            label="Delete User"
            className="red"
            onClick={this.props.deleteUser} />
        ]}
        modal={false}
        open={this.props.open}
        autoScrollBodyContent={true}
      >
        <p style={{marginTop: '10px'}}>Are you sure you want to delete {this.props.name}? This action cannot be undone</p>
      </Dialog>
    )
  }
}

export default DeleteUserModal
