import { bindActionCreators } from 'redux'
import {
  formValueSelector,
  change,
  arrayPush,
  arrayRemoveAll,
} from 'redux-form'

import { api } from 'controllers/Rest'
import {
  getActiveMergedPresets,
  applyFormValues,
  getUserDataFromForm,
  initUserForm,
} from './utils'

import C from '../../../config/environment'
import { selectHeaderData } from '../../auth/selectors'
import { checkStatus, parseJSON } from '../../../controllers/concerns/railsRest'
import { updateDialogAction } from '../../dialog/actions'

// ACTIONS
export const SET_USER_OPTIONS = '_admin/SET_USER_OPTIONS'
export const UPDATE_ACTIVE_LOCATION = '_admin/UPDATE_ACTIVE_LOCATION'
export const SET_USER_LOADING = '_admin/SET_USER_LOADING'
export const SET_PRESETS = '_admin/SET_PRESETS'
export const REMOVE_PRESET = '_admin/REMOVE_PRESET'
export const PUSH_ACTIVE_PRESET = '_admin/PUSH_ACTIVE_PRESET'
export const UPDATE_EDITING_PRESETS = '_admin/UPDATE_EDITING_PRESETS'
export const RESET_ACTIVE_PRESETS = '_admin/RESET_ACTIVE_PRESETS'
export const REMOVE_ACTIVE_PRESET = '_admin/REMOVE_ACTIVE_PRESET'
export const SET_EDITING_USER = '_admin/SET_EDITING_USER'

// ACTION CREATORS
export const setUserOptionsAction = (options) => ({
  type: SET_USER_OPTIONS,
  payload: {
    options,
  },
})

export const updateActiveLocationAction = (activeLocation) => ({
  type: UPDATE_ACTIVE_LOCATION,
  payload: {
    activeLocation,
  },
})

export const setPresetsAction = (preset) => ({
  type: SET_PRESETS,
  payload: {
    preset,
  },
})

export const removePresetAction = (id) => ({
  type: REMOVE_PRESET,
  payload: {
    id,
  },
})

export const pushActivePresetAction = (id) => ({
  type: PUSH_ACTIVE_PRESET,
  payload: {
    id,
  },
})

export const resetActivePresetAction = () => ({
  type: RESET_ACTIVE_PRESETS,
  payload: null,
})

export const removeActivePresetAction = (id) => ({
  type: REMOVE_ACTIVE_PRESET,
  payload: {
    id,
  },
})

export const updateEditingPresetsAction = (newId) => ({
  type: UPDATE_EDITING_PRESETS,
  payload: {
    id: newId,
  },
})

export const setLoadingAction = (type, loading) => ({
  type: SET_USER_LOADING,
  payload: {
    type,
    loading,
  },
})

export const setEditingUserAction = (uid) => ({
  type: SET_EDITING_USER,
  payload: {
    uid,
  },
})

// BOUND ACTION CREATORS
export const getUserData = () => async (dispatch, getState) => {
  const headers = selectHeaderData({
    'Content-Type': 'application/json',
  })(getState())

  try {
    let data = await fetch(C.API.BASE + '/users/new', {
      method: 'GET',
      headers,
    })
    data = await checkStatus(data)
    data = await parseJSON(data)

    const formData = {
      card: data.card_options,
      gifts: data.gifts,
      los: data.length_of_stay_options,
      locations: data.locations,
      ttim: data.travel_time_in_months_options,
    }

    dispatch(setUserOptionsAction(formData))
  } catch (error) {
    // eslint-disable-next-line
    console.error(error)
  }
}

export const updateActiveLocation = (activeLocation) => (dispatch) => {
  dispatch(updateActiveLocationAction(activeLocation))
}

export const getUserPreset = (form, preset, isEditing = false) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const headers = selectHeaderData({
    'Content-Type': 'application/json',
  })(state)

  try {
    let { presets } = state._admin.user.options
    let { activePresets } = state._admin.user

    if (!presets.find(({ name }) => name === preset)) {
      dispatch(setLoadingAction('preset', true))

      let data = await fetch(C.API.BASE + `/users/${preset}`, {
        method: 'GET',
        headers,
      })
      data = await checkStatus(data)
      data = await parseJSON(data)

      dispatch(setPresetsAction(data.user))

      presets = [...presets, data.user]
    }

    activePresets = isEditing ? [preset] : [...activePresets, preset]

    dispatch(pushActivePresetAction(preset))

    applyFormValues(
      form,
      getActiveMergedPresets(presets, activePresets),
      bindActionCreators(
        {
          change,
          arrayPush,
          arrayRemoveAll,
        },
        dispatch
      ),
      isEditing
    )

    dispatch(setLoadingAction('preset', false))
  } catch (error) {
    // eslint-disable-next-line
    console.log(error)
    dispatch(setLoadingAction('preset', false))
  }
}

export const removeUserPreset = (form, preset) => (dispatch, getState) => {
  const state = getState()
  const { presets } = state._admin.user.options
  const active = state._admin.user.activePresets.filter((id) => id !== preset)

  dispatch(removeActivePresetAction(preset))
  dispatch(
    updateActiveLocationAction({
      id: null,
      label: '',
    })
  )

  applyFormValues(
    form,
    getActiveMergedPresets(presets, active),
    bindActionCreators(
      {
        change,
        arrayPush,
        arrayRemoveAll,
      },
      dispatch
    )
  )
}

export const createUser = (form) => async (dispatch, getState) => {
  dispatch(setLoadingAction('create', true))
  const state = getState()
  const headers = selectHeaderData({
    'Content-Type': 'application/json',
  })(state)

  try {
    const fieldsMapping = {
      los: 'length_of_stay',
      locations: 'locations',
      ttim: 'travel_time',
      name: 'name',
      card: 'credit_card',
      password: 'password',
      gifts: 'gifts',
      can_access_admin: 'can_access_admin',
      can_access_billing: 'can_access_billing',
      can_access_capture_and_void: 'can_access_capture_and_void',
      can_access_leads: 'can_access_leads',
      can_access_mail: 'can_access_mail',
      can_access_print: 'can_access_print',
      can_access_refunds: 'can_access_refunds',
      can_access_sales: 'can_access_sales',
      can_access_scrubber: 'can_access_scrubber',
      can_access_stamps: 'can_access_stamps',
      can_access_support: 'can_access_support',
      skip_credit_card: 'skip_credit_card'
    }
    const formData = formValueSelector(form)(
      state,
      'los',
      'locations',
      'ttim',
      'name',
      'card',
      'password',
      'gifts',
      'giftOptions',
      'can_access_admin',
      'can_access_billing',
      'can_access_capture_and_void',
      'can_access_leads',
      'can_access_mail',
      'can_access_print',
      'can_access_refunds',
      'can_access_sales',
      'can_access_scrubber',
      'can_access_stamps',
      'can_access_support',
      'skip_credit_card'
    )
    const user = getUserDataFromForm(fieldsMapping, formData)

    let data = await fetch(C.API.BASE + '/users', {
      method: 'POST',
      headers,
      body: JSON.stringify({
        user,
      }),
    })
    data = await parseJSON(data)

    if (data.errors) {
      throw data.errors
    }

    const title = 'User has been successfully created'

    dispatch(setLoadingAction('create', false))

    dispatch(
      updateDialogAction({
        open: true,
        title,
      })
    )

    dispatch(api.admin.force())
  } catch (error) {
    console.error(error) // eslint-disable-line
    const title = 'Something went wrong'
    let errors = []

    dispatch(setLoadingAction('create', false))

    Object.entries(error).forEach(([field, messages]) => {
      messages.forEach((message) => {
        errors.push(`${field} ${message}`)
      })
    })

    dispatch(
      updateDialogAction({
        open: true,
        title,
        errors,
      })
    )
  }
}

export const updateUser = (form, uid) => async (dispatch, getState) => {
  dispatch(setLoadingAction('editing', true))
  const state = getState()
  const headers = selectHeaderData({
    'Content-Type': 'application/json',
  })(state)

  try {
    const fieldsMapping = {
      los: 'length_of_stay',
      locations: 'locations',
      ttim: 'travel_time',
      name: 'name',
      card: 'credit_card',
      password: 'password',
      gifts: 'gifts',
      can_access_admin: 'can_access_admin',
      can_access_billing: 'can_access_billing',
      can_access_capture_and_void: 'can_access_capture_and_void',
      can_access_leads: 'can_access_leads',
      can_access_mail: 'can_access_mail',
      can_access_print: 'can_access_print',
      can_access_refunds: 'can_access_refunds',
      can_access_sales: 'can_access_sales',
      can_access_scrubber: 'can_access_scrubber',
      can_access_stamps: 'can_access_stamps',
      can_access_support: 'can_access_support',
      skip_credit_card: 'skip_credit_card'
    }
    const formData = formValueSelector(form)(
      state,
      'los',
      'locations',
      'ttim',
      'name',
      'card',
      'password',
      'gifts',
      'giftOptions',
      'can_access_admin',
      'can_access_billing',
      'can_access_capture_and_void',
      'can_access_leads',
      'can_access_mail',
      'can_access_print',
      'can_access_refunds',
      'can_access_sales',
      'can_access_scrubber',
      'can_access_stamps',
      'can_access_support',
      'skip_credit_card'
    )
    const user = getUserDataFromForm(fieldsMapping, formData)

    let data = await fetch(C.API.BASE + `/users/${uid}`, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({
        user,
      }),
    })
    data = await parseJSON(data)

    if (data.errors) {
      throw data.errors
    }

    dispatch(removePresetAction(uid))
    const title = 'User has been successfully edited'

    dispatch(setLoadingAction('editing', false))

    dispatch(
      updateDialogAction({
        open: true,
        title,
      })
    )

    dispatch(updateEditingPresetsAction(formData.name))
    dispatch(api.admin.force())
    dispatch(change(form, 'uid', formData.name))
  } catch (error) {
    console.error(error) // eslint-disable-line
    const title = 'Something went wrong'
    let errors = []

    dispatch(setLoadingAction('editing', false))

    Object.entries(error).forEach(([field, messages]) => {
      messages.forEach((message) => {
        errors.push(`${field} ${message}`)
      })
    })

    dispatch(
      updateDialogAction({
        open: true,
        title,
        errors,
      })
    )
  }
}

export const initializeUserForm = (form) => (dispatch, getState) => {
  const { options: formData } = getState()._admin.user
  initUserForm(
    form,
    formData,
    bindActionCreators(
      {
        change,
      },
      dispatch
    )
  )
}

export const editUser = (uid) => (dispatch) => {
  dispatch(setEditingUserAction(uid))
}

export const resetPresets = () => (dispatch) => {
  dispatch(resetActivePresetAction())
}
