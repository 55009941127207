import { formatCardNumber } from 'helpers/credit_card_helper'

export const normalizePhone = (value) => {
  if (!value)
    return value

  const n = value.replace(/[^\d]/g, '')

  if (n.length <= 3)
    return n
  else if (n.length <= 6)
    return `(${n.slice(0, 3)}) ${n.slice(3)}`

  return `(${n.slice(0, 3)}) ${n.slice(3, 6)}-${n.slice(6, 10)}`
}

export const normalizeUpper = value => {
  return value.toUpperCase()
}

export const normalizeCapital = value => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export const normalizeNumber = value => {
  if (value == null)
    return value

  return value.replace(/[^\d]/g, '')
}

export const normalizeLetter = value => {
  if (value == null)
    return value

  return value.replace(/[^a-zA-Z\s]/g, '')
}

export const normalizeCardExpiration = value => {
  if (value == null)
    return value

  let n = value.replace(/[^\d]/g, '')

  if (n.length <= 2)
    return n

  return `${n.slice(0,2)}/${n.slice(2,4)}`
}

export const normalizeCardNumber = value => {
  if (!value)
    return value

  return formatCardNumber(value)
}

export const normalizeCardCode = value => {
  if (value == null)
    return value

  let n = value.replace(/[^\d]/g, '')

  return n.slice(0,4)
}

export const normalizePrice = value => {
  if (value < 0) return Math.abs(value)
  else return value
}
