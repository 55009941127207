import { createSelector } from 'reselect'

import { selectId } from '../../auth/selectors'

export const selectLeadInitValues = createSelector(
  [selectId], 
  (id) => {
    const isDev = id === 'DEV'

    return {
      name: {
        first_name: isDev ? 'Sohaib': '',
        last_name: isDev ? 'Bhatti': '',
        spouse_first_name: isDev ? 'Sohaiba': '',
        spouse_last_name: isDev ? 'Bhatti': '',
      },
      phone: {
        phone: isDev ? '123-456-7890': '',
        alt_phone: isDev ? '(123) 456-7890': '',
      },
      email: {
        email: isDev ? 'sohaibbbhatti@gmail.com': '',
      },
      billing: {
        billing_street: isDev ? '21 china town': '',
        billing_city: isDev ? 'New York': '',
        billing_state: isDev ? 'NY': '',
        billing_zip_code: isDev ? '10004': '',
      }
    }
  }
)
