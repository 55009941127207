import React from "react"
import PropTypes from "prop-types"
import loading from "../../assets/images/loading.gif"

const Spinner = ({ text }) => {
  return (
    <div className="loading">
      <div>
        <img className="spinner" src={loading} alt="loading" />
        <h1>{text}</h1>
      </div>
    </div>
  )
}

Spinner.defaultProps = {
  text: "Loading, please wait",
}

Spinner.propTypes = {
  text: PropTypes.string,
}

export default Spinner
