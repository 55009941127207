import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import TitleIcon from 'material-ui-icons/Title'
import { TextField } from 'redux-form-material-ui'
import FieldLabel from 'views/ui/FieldLabel'
import { validateRequired} from 'helpers/validators'
import { normalizeLetter } from 'helpers/normalizers'

const PackagePriceField = () => (
  <div className="input radioField input-style">

    <FieldLabel
      icon={<TitleIcon className="required-input-icon input-icon svg-icon" />}
      label="Sales Agent"
      required={true}
    />

    <Field
      id="sales_agent"
      name="sales_agent"
      component={TextField}
      hintText="Opener"
      normalize={normalizeLetter}
      validate={validateRequired}
    />
  </div>
)

PackagePriceField.propTypes = {}

export default PackagePriceField