import { SET_GIFT_OPTIONS, TOGGLE_DIALOG, TOGGLE_GIFT, SELECT_GIFT, RESET_GIFTS } from './actions'

export const INITIAL_STATE = {
  dialogOpen: false,
  options: {},
  // used to reset gifts
  initialOptions: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_GIFT_OPTIONS:
      return {
        ...state,
        options: {
          ...action.payload.options
        },
        initialOptions: {
          ...action.payload.options
        }
      }

    case TOGGLE_DIALOG:
      return {
        ...state,
        dialogOpen: !state.dialogOpen
      }
    
    case TOGGLE_GIFT:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.locationId]: {
            ...state.options[action.payload.locationId],
            [action.payload.id]: {
              ...state.options[action.payload.locationId][action.payload.id],
              included: !state.options[action.payload.locationId][action.payload.id].included
            }
          }
        }
      }

    case SELECT_GIFT:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.locationId]: {
            ...state.options[action.payload.locationId],
            [action.payload.id]: {
              ...state.options[action.payload.locationId][action.payload.id],
              value: action.payload.value
            }
          }
        }
      }

     case RESET_GIFTS:
      return {
        ...state,
        options: {
          ...state.initialOptions
        }
      }
      
    default:
      return state
  }
}