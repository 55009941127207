import { validCardNumber } from 'helpers/credit_card_helper'
import { phones, emails } from 'constants/banned'

const allowedEmail = value => {
  return emails.includes(value.toUpperCase()) ? 'This email is banned' : undefined
}

const allowedPhone = value => {
  return phones.includes(value) ? 'This phone number is banned' : undefined
}

export const defaultRequired = (value) => (value ? undefined : 'Required')

export const validateRequired = value => {
  return value == null || value.toString().replace(/\t|\s|\n|\r/g,'').length < 1 ? 'Required' : undefined
}

export const validateEmail = value => {
  if (!value)
    return undefined

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
    return 'Invalid email'

  return allowedEmail(value)
}

// can't create an higher order function to dynamically check a value with redux-form
export const validate255MaxChar = (value) => {
  if (!value)
    return undefined

  if (value.length > 255) {
    return `Maximum of 255 characters`
  }
}

export const validatePassword = (original) => (value) => {
  if (!value) return undefined
  if (original !== value) {
    return 'Passwords do not match'
  }
}

export const validatePhone = value => {
  if (!value)
    return undefined

  let phone = value.match(/\d+/g).join('')

  if (phone.length !== 10)
    return 'Phone number should be 10 digits'

  return allowedPhone(parseInt(phone, 10))
}

export const validateZip = value => {
  if (!value)
    return undefined

  return (value.length < 5 || value.length > 6) ? 'Zip code should be 5-6 digits' : undefined
}

export const validatePrice = value => {
  if (!value)
    return 'Required'

  return parseInt(value, 10) < 1 || parseInt(value, 10) > 2999 ? 'Package price must be between $1-2999' : undefined
}

export const validateVerification = value => {
  return value && value.length !== 8 ? 'Verification code must be 8 digits' : undefined
}

export const validateCardNumber = value => {
  if (!value)
    return undefined

  let n = value.match(/\d+/g).join('')
  return !validCardNumber(n) ? 'Card Number appears to be invalid' : undefined
}
