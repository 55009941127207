import DialogModel from './DialogModel'

class AdminModel {
  schema

  constructor() {
    this.schema = {
      name: '',
      tokens: {},
      updated_at: '',
      sign_in_count: 0,
      current_sign_in_ip: '',
      last_sign_in_ip: '',
      show_spinner: false,
      dialog: new DialogModel(),
    }
  }
}

export default AdminModel
