import React from 'react'
import PropTypes from 'prop-types'
import RadioButton from 'material-ui/RadioButton'
import { RadioButtonGroup } from 'redux-form-material-ui'
import { Field } from 'redux-form'
import PlaceIcon from 'material-ui-icons/Place'
import styles from 'views/ui/RadioButtonStyles'
import FieldLabel from 'views/ui/FieldLabel'

const renderRadioButtons = (destination_options) => {
  return destination_options
    .map(destination => {
      return (
        <RadioButton
          key={destination}
          value={destination}
          label={destination}
          className="radioButton radio-button"
          iconStyle={styles.hidden}
          labelStyle={styles.radioLabel}
        />
      )
    }
  )
}

const DestinationFieldRadioButtons = ({ destination_options, required, resetGifts }) => {
  return (
    <div className="input radioField input-style">
      <FieldLabel
        icon={<PlaceIcon className="required-input-icon input-icon svg-icon" />}
        label="Travel Destination"
        required={true} />

      <Field
        id="destination"
        name="travel.destination"
        component={RadioButtonGroup}
        onChange={resetGifts}
        validate={required}>
        {renderRadioButtons(destination_options)}
      </Field>
    </div>
  )
}



DestinationFieldRadioButtons.propTypes = {
  destination_options: PropTypes.arrayOf(PropTypes.string).isRequired,
  required: PropTypes.func.isRequired,
  resetGifts: PropTypes.func.isRequired,
}

export default DestinationFieldRadioButtons
