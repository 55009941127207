import React from 'react'
// import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import CodeIcon from 'material-ui-icons/Code'
import { textErrorStyle } from 'views/ui/styles/styles'
import { validateRequired } from 'helpers/validators'
import FieldLabel from 'views/ui/FieldLabel'
import { TextField } from 'redux-form-material-ui'
import { validateZip } from 'helpers/validators'

const BillingZipField = () => (
  <div className="input radioField input-style">

    <FieldLabel
      icon={<CodeIcon className="required-input-icon input-icon svg-icon" />}
      label="Billing ZIP Code"
      required={true}
    />

    <Field
      id="billing_zip_code"
      name="billing.billing_zip_code"
      component={TextField}
      hintText="ZIP Code"
      errorStyle={ textErrorStyle }
      validate={[validateRequired, validateZip]}
    />
  </div>
)

BillingZipField.propTypes = {}

export default BillingZipField