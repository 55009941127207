import React from 'react'
import PropTypes from 'prop-types'
// Icons
import LocationIcon from 'material-ui/svg-icons/communication/location-on'

import ConfirmationField from './ConfirmationField'

const ConfirmationTravelFields = ({ form_data, errorColor }) => {
  const { accommodation } = form_data
  const hasErrors = !!accommodation.syncErrors

  return (
    <div className="card-group">
      <div className="card-header-container">
        <LocationIcon color={hasErrors ? errorColor : "#7a7a7a"}/>
        <h3 className={`card-header ${hasErrors ? 'error' : ''}`}>Travel</h3>
      </div>

      <div className="confirmation-fields">
        <ConfirmationField
          label="Destination"
          value={accommodation.values.travel && accommodation.values.travel.destination}
          error={accommodation.syncErrors && accommodation.syncErrors.travel && accommodation.syncErrors.travel.destination}
        /> 
        <ConfirmationField
          label="Travel Time"
          value={accommodation.values.travel && accommodation.values.travel.length_of_stay}
          error={accommodation.syncErrors && accommodation.syncErrors.travel && accommodation.syncErrors.travel.length_of_stay}
        /> 
        <ConfirmationField
          label="Length of Stay"
          value={accommodation.values.travel && accommodation.values.travel.travel_time_months}
          error={accommodation.syncErrors && accommodation.syncErrors.travel && accommodation.syncErrors.travel.travel_time_months}
        /> 
      </div>
    </div>
  )
}

ConfirmationTravelFields.propTypes = {
  form_data: PropTypes.object.isRequired,
  errorColor: PropTypes.string.isRequired,
}

export default ConfirmationTravelFields