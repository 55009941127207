import React from 'react'
import PropTypes from 'prop-types'

class FieldLabel extends React.Component {
  render = () => {
    const { children, className, icon, required, label, ...rest } = this.props
    return (
      <label className={`required-radio-group-label radio-label-with-icon required-icon ${className || ''}`}  {...rest}>
        { icon || '' }
        { label || '' }
        { this.renderRequired( required, label ) }
        { children }
      </label>
    )
  }

  renderRequired = ( required, label ) => (
    required && label ? <em className="required required-icon">*</em> : null
  )
}

FieldLabel.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  icon:       PropTypes.object,
  label:      PropTypes.string,
  required:   PropTypes.bool
}

export default FieldLabel
