import { createSelector } from 'reselect'

import { selectId } from '../../auth/selectors'

export const selectBillingInitValues = createSelector(
  [selectId], 
  (id) => {
    const isDev = id === 'DEV'

    return {
      credit_card_name: isDev ? 'John Doe' : '',
      credit_card_number: isDev ? '4242424242424242' : '',
      expiration_date: isDev ? '12/20' : '',
      cvv: isDev ? '123' : '',
    }
  }
)

export const selectFocused = (formName) => (state) => {
  const fieldMap = {
    credit_card_name: 'name',
    credit_card_number: 'number',
    expiration_date: 'expiry',
    cvv: 'cvc',
  }
  const [root, form] = formName.split('.')

  return root in state.form && form in state.form[root] ? fieldMap[state.form[root][form].active] : ''
}