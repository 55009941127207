// ACTIONS
export const SET_DESTINATION_OPTIONS = 'vsf/travel/SET_DESTINATION_OPTIONS'
export const SET_LOS_OPTIONS = 'vsf/travel/SET_LOS_OPTIONS'
export const SET_TRAVEL_TIME_OPTIONS = 'vsf/travel/SET_TRAVEL_TIME_OPTIONS'
export const SET_SKIP_CREDIT_CARD = 'vsf/travel/SET_SKIP_CREDIT_CARD'

// ACTION CREATORS
export const setDestinationOptionsAction = (options) => ({
  type: SET_DESTINATION_OPTIONS,
  payload: {
    options
  }
})

export const setLosOptionsAction = (options) => ({
  type: SET_LOS_OPTIONS,
  payload: {
    options
  }
})

export const setTravelTimeOptionsAction = (options) => ({
  type: SET_TRAVEL_TIME_OPTIONS,
  payload: {
    options
  }
})

export const setSkipCreditCardAction = (payload) => ({
  type: SET_SKIP_CREDIT_CARD,
  payload: payload
})
