import React from 'react'
import PropTypes from 'prop-types'
import { RadioButtonGroup } from 'redux-form-material-ui'
import RadioButton from 'material-ui/RadioButton'
import { Field } from 'redux-form'
import { Checkbox } from 'redux-form-material-ui'

import styles from 'views/ui/RadioButtonStyles'

import './index.scss'

const GIFT_TYPE_OPTIONS = ['default', 'additional', 'closer', 'premium']

const GiftOptions = ({
  selected,
  valueOptions,
  activeLocationId,
  id,
  label,
  error,
}) => {
  return (
    selected && (
      <div className="GiftOptions-container">
        <Field
          className="GiftOptions-option-field-container"
          name={`giftOptions.type.locationId:${activeLocationId}.giftId:${id}`}
          component={RadioButtonGroup}
        >
          {GIFT_TYPE_OPTIONS.map((option) => (
            <RadioButton
              style={{
                width: 'auto',
                fontSize: 12,
              }}
              key={option}
              value={option}
              label={option}
              className="radioButton radio-button"
              iconStyle={styles.hidden}
              labelStyle={styles.radioLabel}
            />
          ))}
        </Field>
        <div className="error-text">{error && error}</div>
        {valueOptions &&
          valueOptions.map((option) => {
            return (
              <Field
                key={option}
                label={`${option} ${label}`}
                name={`giftOptions.values.locationId:${activeLocationId}.giftId:${id}.option:${option}`}
                component={Checkbox}
              />
            )
          })}
      </div>
    )
  )
}

GiftOptions.propTypes = {
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  activeLocationId: PropTypes.number.isRequired,
  valueOptions: PropTypes.array,
  selected: PropTypes.bool.isRequired,
  error: PropTypes.string,
}

export default GiftOptions
