import React from 'react'
import PropTypes from 'prop-types'
import SvgIcon from 'material-ui/SvgIcon'

const ReloadIcon = ({ color, viewBox, ...props }) => (
  <SvgIcon viewBox={viewBox} {...props}>
    <path fill={color} d="M86.78,19.22,98,8V41H65L76.1,29.9A33,33,0,1,0,82.43,56H97.56A48,48,0,1,1,86.78,19.22Z"></path>
  </SvgIcon>
)

ReloadIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

ReloadIcon.defaultProps = {
  color: "rgba(0,0,0,0.7)",
  viewBox: "0 0 100 100",
}

export default ReloadIcon
