import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Table } from 'reactable'
import Moment from 'moment'
import { api } from 'controllers/Rest'
import { createAlert } from 'controllers/AlertController'
import PaymentsController from 'controllers/PaymentsController'
import Spinner from "../ui/Spinner"
import NumberFormat from 'react-number-format'
import Checkbox from 'material-ui/Checkbox'

import FilterToolBar from '../capture_void/FilterToolBar'
import MassActionToolBar from '../capture_void/MassActionToolBar'
import MassActionResults from '../capture_void/MassActionResults'

export const getPayments = (state) => {
  return {
    payments: state.payments
  }
}

class Billing extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    payments: PropTypes.object,
    loading: PropTypes.func,
    route: PropTypes.object,
  }

  state = {
    filter: {
      state: null,
      gateway: null
    },
    showMassActionResults: false,
    selectedPayments: [],
    massActionResults: {},
    payments: [],
    loading: false,
    loadingMessage: "",
    tableFilterValue: '',
  }

  constructor(props) {
    super(props)

    window.document.title = props.route.title
  }

  componentWillMount() {
    this.setState({
      payments: this.props.payments
    })

    this.reload()
  }

  reload = () => {
    const { loading, dispatch } = this.props

    loading(done => dispatch(api.payments.force())
      .then(() => done())
      .then(() => {
        this.setState({
          payments: this.props.payments
        })
      })
      .catch(e => dispatch(createAlert({
        dismissable: true,
        type: 'error',
        message: `Error loading Payments: ${e.message}`,
      })))
    )
  }

  approvePayment(paymentId) {
    let admin = new PaymentsController(this)
    admin.approvePayment(paymentId)
  }

  voidPayment(paymentId) {
    let admin = new PaymentsController(this)
    admin.voidPayment(paymentId)
  }

  massApprovePayments = () =>  {
    let admin = new PaymentsController(this)
    admin.massApprovePayments()
  }

  massVoidPayments = () =>  {
    let admin = new PaymentsController(this)
    admin.massVoidPayments()
  }

  filterState = (event, index, value) => {
    this._filterProperty('state', value)
  }

  filterGateway = (event, index, value) => {
    this._filterProperty('gateway', value)
  }

  fetchPayments = () => {
    const { loading, dispatch } = this.props
    loading((done) => Promise.all([
      dispatch(
        api.payments(this.state.filter)
      )
    ]).then(() => {
      done()
      this.setState({
        payments: this.props.payments
      })
    }))
  }

  clearFilters = () => {
    this.setState({
      filter: {
        state: null,
        gateway: null
      }
    }, () => {
      this.fetchPayments()
    })
  }

  closeDialog = () => {
    this.setState({
      showMassActionResults: false
    })
  }

  unselectPayments = () => {
    this.setState({
      selectedPayments: []
    })
  }

  _filterProperty = (propertyName, propertyValue) => {
    const { loading, dispatch } = this.props

    loading((done) => Promise.all([
      dispatch(
        api.payments(
          Object.assign(
            {},
            this.state.filter,
            { [propertyName]: propertyValue }
          )
        )
      )
    ]).then(() => {
      done()
      this.setState({
        payments: this.props.payments,
        filter: {
          ...this.state.filter,
          [propertyName]: propertyValue
        }
      })
    }))
  }

  _hasDefaultFilterStates = () => {
    const { state, gateway } = this.state.filter
    return state === null && gateway === null
  }

  _renderState = (payment) => {
    let cssClass = 'badge'
    if (payment.state === 'Void') {
      cssClass += ' red'
    } else if (payment.state === 'Authorized') {
      cssClass += ' blue'
    } else if (payment.state === 'Declined') {
      cssClass += ' orange'
    }

    return <span className={cssClass}>{payment.state}</span>
  }

  selectAllPayments = () => {
    let allPayments = this.state.payments.data
    if (this.state.selectedPayments.length === 0) {
      this.setState({
        selectedPayments: allPayments.map((payment) => payment.id)
      })
    } else {
      this.unselectPayments()
    }
  }

  handleChange = payment => () => {
    const { selectedPayments } = this.state
    if (this.state.selectedPayments.indexOf(payment.id) >= 0) {
      this.setState({
        selectedPayments: selectedPayments.filter((val) => val !== payment.id)
      })
    } else {
      this.setState({
        selectedPayments: [].concat(selectedPayments, payment.id)
      })
    }
  }

  _renderLinkForLead = (payment) => {
    return <a onClick={(e) => this._openLeadInNewWindow(e, payment.lead_link)} href={payment.lead_link}>{payment.lead_name}</a>
  }

  _renderPackageCode = (payment) => {
    let link = `https://crm.zoho.com/crm/org49733957/search?searchword=${payment.package_code}`
    return <a onClick={(e) => this._openLeadInNewWindow(e, link)} href={link}>{payment.package_code}</a>
  }

  _openLeadInNewWindow = (e, link) => {
    e.preventDefault()
    let userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.indexOf(' electron/') > -1) {
      window.require('electron').shell.openExternal(link)
    } else {
      let width = window.innerWidth / 2
      let height = window.innerHeight
      window.open(link, 'Google', `left=${width},width=${width},height=${height}`)
    }
  }

  renderPayments = () => {
    const payments = this.state.payments
    let paged = 0

    if (payments.loading)
      return <div className="big text">Loading... please wait</div>

    if (payments.data.length < 1)
      return <div className="big text">No payments found.</div>

    if (payments.data.length > 20)
      paged = 200

    let items = payments.data.map(payment => {
      let paymentItem = {
        'Select': payment.state !== 'Captured' ? <Checkbox
              checked={this.state.selectedPayments.includes(payment.id)}
              onCheck={this.handleChange(payment)}
            /> : '',
        'Submit Date': Moment(payment.created_at).format("M/D/YY hh:mm:ss A"),
        'Transaction': payment.transaction_id,
        'Invoice': this._renderPackageCode(payment),
        'Name': this._renderLinkForLead(payment),
        'State': this._renderState(payment),
        'Amount': <NumberFormat value={payment.amount/100} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      }

      if (this.shouldRenderActionButtons(payment)) {
        paymentItem['Actions'] = (
          <div className="nowrap">
            <button className="green button" disabled={this.state.loading? 'disabled' : ''} onClick={() => this.approvePayment(payment.id)}>Approve</button>
            <button className="red button" disabled={this.state.loading? 'disabled' : ''} onClick={() => this.voidPayment(payment.id)}>Void</button>
          </div>
        )
      }

      return paymentItem
    })

    let columns = [
      {key: 'Select', label: ''},
      'Submit Date',
      'Transaction',
      'Invoice',
      'Name',
      'State',
      'Amount'
    ]
    if (this.state.selectedPayments.length == 0)
      columns = [...columns, 'Actions']

    return (
      <Table
        className="table"
        columns={columns}
        data={items}
        sortable={[
          'Submit Date',
          'Transaction',
          'Invoice',
          'Name',
          'State',
          'Amount'
        ]}
        filterable={[
          {
            column: 'Name',
            filterFunction: function(contents, filter) {
              let name = contents.props.children || ''
              return (name.toLowerCase().indexOf(filter) > -1)
            }
          },
          {
            column: 'Invoice',
            filterFunction: function(contents, filter) {
              let invoice = contents.props.children || ''
              return (invoice.toLowerCase().indexOf(filter) > -1)
            }
          },
          'Transaction'
        ]}
        onFilter={(val) => this.setState({...this.state, tableFilterValue: val})}
        filterBy={this.state.tableFilterValue}
        itemsPerPage={paged}
        pageButtonLimit={10}
        width="100%"
      />
    )

    table.filterBy(this.state.tableFilterValue)
    return table
  }

  shouldRenderActionButtons = (payment) => {
    return (
      (this.state.selectedPayments.length == 0) &&
        (payment.state === 'Authorized')
    )
  }

  renderSpinner = () => {
    if (this.state.loading) {
      return <Spinner text={this.state.loadingMessage } />
    }
  }

  renderToolBar = () => {
    if (this.state.selectedPayments.length > 0) {
      return (
        <MassActionToolBar
          loading={this.state.loading}
          selectedCount={this.state.selectedPayments.length}
          massApprovePayments={this.massApprovePayments}
          massVoidPayments={this.massVoidPayments}
          unselectPayments={this.unselectPayments}
        />
      )
    } else {
      return (
        <FilterToolBar
          gatewayFilterValue={this.state.filter.gateway}
          filterGatewayOnChange={this.filterGateway}
          stateFilterValue={this.state.filter.state}
          filterStateOnChange={this.filterState}
          paymentCount={this.state.payments.data.length}
          loading={this.state.loading}
          clearFilters={this.clearFilters}
          fetchPayments={this.fetchPayments}
        />
      )
    }
  }

  render = ({ children } = this.props) => {
    return (
      <div className="capture-void">
        {children}
        <MassActionResults
          show={this.state.showMassActionResults}
          closeDialog={this.closeDialog}
          success={this.state.massActionResults.success}
          errors={this.state.massActionResults.errors}
          title={this.state.massActionResults.action}
        />
        {this.renderToolBar()}
        {this.renderSpinner()}
        {this.renderPayments()}
      </div>
    )
  }
}

export default connect(getPayments)(Billing)
